import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import CONSTANTS, { BREADCRUMS } from '../../Constants'
import { BULK_UPDATE } from '../../Constants/ApiDefinations'
import { TIPS_MODULE } from '../../Helpers/ManageTips'
import { combineErrorMessage, getDateInFormat } from '../../Helpers/Util'
import { BreadcrumsModel } from '../../Models'
import mainApiService from '../../Services'
import { useAppDispatch } from '../../Store/Hooks'
import { showAlert } from '../../Store/Slice/alert.slice'
import RoleConfirmation from '../Dialogs/RoleConfirmation'
import { BackButton, Breadcrumbs, MUITipsCard } from '../Shared'
import './BulkUpdate.scss'
import BulkUpdateEditor from './BulkUpdateEditor'
import BulkUpdateInformation from './BulkUpdateInformation'
import JobDrawerEditor from './JobDrawerEditor'

const BulkUpdateComponent = () => {
  const { pageType, id } = useParams()
  const [jobDrawer, setJobDrawer] = useState<boolean>(false)

  const [viewData, setViewData] = useState<any>()

  const [dialogProps, setDialogProps] = useState<any>({ open: false, heading: '' })

  const [jobData, setJobData] = useState({})

  useEffect(() => {
    getDetailsOfJob()
  }, [])

  const dispatch = useAppDispatch()

  const openCloseDrawer = (value: boolean) => {
    setJobDrawer(value)

    if (!value) {
      getDetailsOfJob()
    }
  }

  const handleCloseDialog = () => {
    setDialogProps({ open: false, heading: '' })
  }

  const handleDialog = (
    heading: string,
    description: string,
    successCallBack: any,
    cancelCallBack?: any,
    type = 'warning',
    icon = 'DeleteRoleWarIcon',
    showHtml = false,
  ) => {
    setDialogProps({
      heading: heading,
      description: description,
      color: type,
      Icon: icon,
      open: true,
      iconColor: type,
      showHtmlProps: showHtml,
      showHtml: heading === CONSTANTS.BULK_UPDATE_CONFIRM_DIALOG_SCHEDULE,
      cancelButtonLabel: cancelCallBack ? 'Cancel' : null,
      successButtonLabel: 'Okay',
      handleSuccess: successCallBack,
      handleClose: cancelCallBack,
    })
  }

  const getDetailsOfJob = async () => {
    const reqData = BULK_UPDATE.GET_JOB_DETAILS(id as string)
    const result = await mainApiService(reqData)

    if (result?.error) {
      dispatch(showAlert([true, combineErrorMessage(result), 'error']))
      return
    }
    setJobData(result?.data)
    setViewData([
      {
        label: 'Attributes',
        value: result?.data?.attributes?.map((att: any) => att.label).join(', '),
        isTitle: false,
      },
      { label: 'Job Id', value: result?.data?.jobId, isTitle: false },
      { label: 'Asset Count', value: result?.data?.assetCount, isTitle: false },
      { label: 'User Name', value: result?.data?.userName, isTitle: false },
      {
        label: 'Start Time',
        value: getDateInFormat(result?.data?.scheduleDate ?? '', 'dd/MM/yyyy HH:mm'),
        isTitle: false,
      },
      {
        label: 'End Time',
        value: getDateInFormat(result?.data?.endDate ?? '', 'dd/MM/yyyy HH:mm'),
        isTitle: false,
      },
      { label: 'Success Count', value: result?.data?.successCount, isTitle: false },
      { label: 'Failure Count', value: result?.data?.failureCount, isTitle: false },
    ])
  }

  const navigate = useNavigate()

  return (
    <>
      {jobDrawer && (
        <JobDrawerEditor
          jobInfo={jobData}
          pageType={pageType}
          open={jobDrawer}
          id={id}
          handleClose={openCloseDrawer}
        />
      )}
      <div className={'role__editor p-16'}>
        <div className='p-8'>
          <Breadcrumbs
            breadcrums={
              pageType === CONSTANTS.PAGE_TYPE.VIEW
                ? (BREADCRUMS.VIEW_BULK_UPDATE() as BreadcrumsModel[])
                : (BREADCRUMS.CREATE_UPDATE_BULK_UPDATE() as BreadcrumsModel[])
            }
          />
        </div>
        <div className='p-8'>
          <BackButton
            label={pageType === CONSTANTS.PAGE_TYPE.VIEW ? 'View Job' : 'Bulk Update'}
            className='col73'
            handleOnClick={() => {
              navigate('/bulk-update')
            }}
          />
        </div>
        <div className='mt-1'>
          <div className='role__editor-wrapper'>
            <div className='role__editor-content p-8'>
              {pageType === CONSTANTS.PAGE_TYPE.VIEW ? (
                <BulkUpdateInformation
                  viewData={viewData}
                  view={jobData}
                  id={id as string}
                  pageType={pageType as string}
                  jobData={jobData}
                  refresh={getDetailsOfJob}
                  handleCloseDialog={handleCloseDialog}
                  handleDialog={handleDialog}
                />
              ) : (
                <BulkUpdateEditor
                  jobDetails={jobData}
                  refresh={getDetailsOfJob}
                  openCloseDrawer={openCloseDrawer}
                  handleCloseDialog={handleCloseDialog}
                  handleDialog={handleDialog}
                />
              )}
            </div>
            <div className='p-8 ml-1by2'>
              <div>
                <MUITipsCard
                  tipsArr={CONSTANTS.NO_TIPS}
                  module={TIPS_MODULE.BULK_UPDATE}
                  pageType={pageType}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {dialogProps.open && <RoleConfirmation {...dialogProps} />}
    </>
  )
}

export default BulkUpdateComponent
