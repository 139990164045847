import { Search } from '@mui/icons-material'
import { InputAdornment, TextField } from '@mui/material'

/**
 * A component that provides a searchable input field with an optional placeholder and search icon.
 * @param placeholder - The placeholder text for the search input.
 * @param onChange - A function to handle onChange event of the search input.
 */
export const FilterSearch = ({
  placeholder = 'Search by Title and Id',
  onChange,
  valueProps = false,
  value,
  dynamicWidth = 100,
}: {
  placeholder?: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  valueProps?: boolean
  value?: any
  dynamicWidth?: any
}) => {
  const textFieldProps = valueProps ? { value } : {}
  return (
    <TextField
      autoComplete={'false'}
      data-testid='asset_status_table_search'
      className='c-input search-input'
      sx={{ '--dynamic-width': `${dynamicWidth}%` }}
      id='outlined-search'
      onChange={onChange}
      placeholder={placeholder}
      InputProps={{
        id: 'searchId',
        startAdornment: (
          <InputAdornment position='start'>
            <Search style={{ color: '#B1B4B6' }} />
          </InputAdornment>
        ),
      }}
      type='search'
      {...textFieldProps}
    />
  )
}
