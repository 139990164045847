import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import CONSTANTS, { BREADCRUMS } from '../../../Constants'
import {
  ASSET_TYPE_DEFINITION_CRUD,
  GLOBAL_SEARCH,
  PARTNER_APIS,
} from '../../../Constants/ApiDefinations'
import { TIPS_MODULE } from '../../../Helpers/ManageTips'
import { getPageTitle, getPartnerViewDeatils, removeDuplicates } from '../../../Helpers/Util'
import updateTagMetaData from '../../../Helpers/updateTagMetaData'
import { BreadcrumsModel } from '../../../Models'
import mainApiService from '../../../Services'
import { useAppDispatch } from '../../../Store/Hooks'
import { showAlert } from '../../../Store/Slice/alert.slice'
import { setLoader } from '../../../Store/Slice/loader.slice'
import { getMediaProfileList } from '../../../Store/Slice/videoAssetGroup.slice'
import { BackButton, Breadcrumbs, MUITipsCard } from '../../Shared'
import PartnersForm from './PartnersForm'
import PartnersInformation from './PartnersInformation'

const PartnersEditor: React.FC<{
  id: string | undefined
  partnerId: string | undefined
}> = ({ id, partnerId }: { id: string | undefined; partnerId: string | undefined }) => {
  const { VIEW, EDIT, CREATE } = CONSTANTS.PAGE_TYPE
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [partnerData, setPartnerData] = useState<any>({})
  const [viewData, setViewData] = useState<any>([])
  const [listOptions, setListOptions] = useState({
    masterList: [],
    assetTypeList: [],
    mediaProfilesList: [],
    transcodingProcessTypesList: [],
  })

  useEffect(() => {
    getListOption()
  }, [])

  const getPartnerInformation = async (listOptionsObj: any) => {
    if (!listOptionsObj.masterList?.length) {
      return
    }
    if (id !== CREATE && partnerId) {
      const _id = partnerId
      dispatch(setLoader(true))
      const requestedData: any = PARTNER_APIS.VIEW_PARTNER(_id as any)
      const result = await mainApiService(requestedData)
      dispatch(setLoader(false))
      if (!result?.data?._id?.length) {
        dispatch(showAlert([true, result.message, 'error']))
        navigate('/partners')
      } else {
        const viewRes = result.data
        setPartnerData(viewRes)
        setViewData(getPartnerViewDeatils(viewRes, listOptionsObj))
      }
    }
  }

  useEffect(() => {
    if (id === CREATE) {
      updateTagMetaData({ title: 'Create Partner' })
    } else if (partnerData?.serviceName) {
      updateTagMetaData({ title: `Partner: ${partnerData?.serviceName}` })
    }
    return () => {
      updateTagMetaData({})
    }
  }, [partnerData?.serviceName, id])

  useEffect(() => {
    getPartnerInformation(listOptions)
  }, [id])

  const getListOption = async () => {
    dispatch(setLoader(true))
    const allListResult: any = await Promise.all([
      await mainApiService(ASSET_TYPE_DEFINITION_CRUD.LIST_DATA_SOURCE()),
      await mainApiService(GLOBAL_SEARCH.ASSET_DEFINITION_LIST()),
      await dispatch(getMediaProfileList({}) as any),
    ])
    dispatch(setLoader(false))
    if (allListResult) {
      const masterList = allListResult[0]?.data?.map((ele: any) => {
        const keySplit = ele.key.split('/')
        return {
          ...ele,
          value: keySplit?.length > 1 ? keySplit[1] : keySplit[0],
          masterKey: ele.value,
        }
      })
      const parsedData = allListResult[1]?.data?.map(
        (item: { value: string; assetType: string }) => ({
          ...item,
          value: item.assetType,
          key: item.assetType,
        }),
      )
      const uniqueAssetType: any = removeDuplicates(parsedData, 'assetType')
      const listOptionsObj = {
        masterList: masterList,
        assetTypeList: uniqueAssetType,
        mediaProfilesList: allListResult[2]?.payload?.media,
        transcodingProcessTypesList: allListResult[2]?.payload?.process,
      }
      setListOptions(listOptionsObj)
      if (id) {
        getPartnerInformation(listOptionsObj)
      }
    }
  }

  return (
    <>
      <div className={`role__editor p-16 ${id !== VIEW ? 'pb-120' : ''}`}>
        <div className='p-8'>
          <Breadcrumbs
            breadcrums={
              BREADCRUMS.CREATE_PARTNER(id, getPageTitle(id as string)) as BreadcrumsModel[]
            }
          />
        </div>
        <div>
          <div className='p-8'>
            <BackButton
              label={`${getPageTitle(id as string)} Partner`}
              className='col73'
              handleOnClick={(e: any) => {
                e.preventDefault()
                navigate('/partners')
              }}
            />
          </div>
          <div className='mt-1'>
            <div className='role__editor-wrapper'>
              <div className='role__editor-content p-8'>
                {id === VIEW ? (
                  <div className=''>
                    <PartnersInformation
                      view={partnerData}
                      viewData={viewData}
                      listOptions={listOptions}
                      getPartnerInformation={() => getPartnerInformation(listOptions)}
                    />
                  </div>
                ) : (
                  <>
                    {listOptions?.masterList.length > 0 && (
                      <PartnersForm partnersData={partnerData} id={id} listOptions={listOptions} />
                    )}
                  </>
                )}
              </div>
              <div className=' p-8  ml-1 '>
                <div>
                  <MUITipsCard
                    tipsArr={CONSTANTS.PARTNER_TIPS}
                    module={TIPS_MODULE.MANAGE_PARTNER}
                    pageType={[VIEW, CREATE].includes(id as string) ? id : EDIT}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PartnersEditor
