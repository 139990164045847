import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import { TextField, Tooltip } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import CONSTANTS, { VideoAssetGroupConstants } from '../../../Constants'
import {
  CONTENT_CRUD,
  GLOBAL_SEARCH,
  HOME_PAGE,
  USER_CONFIG,
} from '../../../Constants/ApiDefinations'
import {
  getThumbnailFromImageDetails,
  processRecordsAdvanceSearch,
} from '../../../Helpers/ManageContent'
import { checkAnyMetaWritePermission } from '../../../Helpers/Permission'
import {
  deepClone,
  getDateInFormat,
  getOrderColumn,
  getStatusMessage,
  setRouteToLocalStorage,
} from '../../../Helpers/Util'
import { RoleConfirmationModal, TypeObject } from '../../../Models'
import mainApiService from '../../../Services'
import { useAppDispatch } from '../../../Store/Hooks'
import { showAlert } from '../../../Store/Slice/alert.slice'
import { setLoader } from '../../../Store/Slice/loader.slice'
import AssetCloneVersion from '../../AssetList/AssetCloneVersion'
import AssetImage from '../../AssetList/AssetImage'
import RoleConfirmation from '../../Dialogs/RoleConfirmation'
import HeadersColMenu from '../../HeadersColMenu/HeadersColMenu'
import AssetStateLabel from '../../Labels/AssetStateLabel'
import { MUIButton, MUICheckbox, MUIGrid } from '../../Shared'
import MUICommonTable from '../../Shared/MUI-CommonTable'
import styles from '../GlobalSearch.module.scss'

import React from 'react'
import rearrangeIcon from '../../../Assets/Images/svgs/rearrangeIcon.svg'
import { setSavedFilter } from '../../../Helpers/CommonListingHelper'
import { getLocalStorage, setLocalStorage } from '../../../Helpers/Storage'
import updateTagMetaData from '../../../Helpers/updateTagMetaData'
import ReorderColumnDialog from '../../Dialogs/ReorderColumnDialog'

interface AdvancedSearchListProps {
  attributes: TypeObject[]
  searchPayload: TypeObject
  searchData: Record<string, any>
  submitForm: (data: TypeObject, formData?: any, from?: any) => Promise<void>
  formData?: any
  savedFilters?: any
  mounted?: boolean
}

const initialFilters = {
  search: '',
  state: '',
  sort: '',
  sortType: '',
  filters: [],
  limit: 10,
  pageNumber: 1,
  content: false,
}

const assetProperties = [
  {
    id: 'image',
    fieldName: 'image',
    label: 'Image',
    sort: false,
    filter: false,
    viewInListing: true,
    position: 0,
    class: 'list_image',
    content: false,
  },
  {
    id: 'blitzId',
    fieldName: 'blitzId',
    label: 'Blitz Id',
    sort: true,
    filter: false,
    viewInListing: true,
    position: 1,
    class: 'list_asset_id',
    content: true,
  },
  {
    id: 'avsContentStatus',
    fieldName: 'avsContentStatus',
    label: 'AVS Status',
    sort: false,
    filter: false,
    viewInListing: true,
    position: 2,
  },
  {
    id: 'contentId',
    fieldName: 'contentId',
    label: 'AVS Content Id',
    sort: true,
    filter: false,
    viewInListing: true,
    position: 3,
    class: '',
    content: true,
  },
  {
    id: 'state',
    fieldName: 'state',
    label: 'Asset State',
    sort: true,
    filter: false,
    viewInListing: true,
    position: 6,
    content: true,
  },
  {
    id: 'assetType',
    fieldName: 'assetType',
    label: 'Asset Type',
    sort: true,
    filter: false,
    viewInListing: true,
    position: 7,
    content: true,
  },
  {
    id: 'assetCategory',
    fieldName: 'assetCategory',
    label: 'Asset Category',
    sort: true,
    filter: false,
    viewInListing: true,
    position: 8,
    content: true,
  },
  {
    id: 'contentCategory',
    fieldName: 'contentCategory',
    label: 'Content Category',
    sort: true,
    filter: false,
    viewInListing: true,
    position: 9,
    content: true,
  },
  {
    id: 'createdAt',
    fieldName: 'createdAt',
    label: 'Creation Date',
    sort: true,
    filter: false,
    viewInListing: true,
    position: 16,
    fieldType: 'DATETIME',
    content: true,
  },
  {
    id: 'serviceAlias',
    fieldName: 'serviceAlias',
    label: 'Partner',
    sort: true,
    filter: false,
    viewInListing: true,
    position: 17,
    content: true,
  },
]

const apiCancelTokens: any = []
/**
 * Advanced Search List Component
 * @param attributes - list of all the attributes
 * @param searchPayload - payload to be send in onSubmit method for existing search
 * @param searchData - data to be shown in table for global search
 * @param submitForm - (data) => { void } function to submit the form
 * @returns {React.JSX.Element} React JSX element representing the Advanced Search List Ccomponent.
 * */

const AdvancedSearchList: React.FC<AdvancedSearchListProps> = ({
  attributes,
  searchPayload,
  searchData,
  submitForm,
  formData,
  savedFilters,
  mounted,
}: AdvancedSearchListProps) => {
  const [searchParams] = useSearchParams()
  const [reorderDilaog, setReorderDialog] = useState(false)

  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  // const { blitzId } = useParams()
  const [dialogPropsSaveSearch, setDialogPropsSaveSearch] = React.useState<RoleConfirmationModal>({
    heading: '',
    description: '',
    color: 'primary',
    open: false,
  })
  const [rows, setRows] = useState<any>([])
  const [versionRows, setVersionRows] = useState<null | {
    index: number
    records: Record<string, any>[]
  }>(null)
  const [totalCount, setTotalCount] = useState(0)
  const [headers, setHeaders] = useState<any>([])
  const [selectedFilters, setSelectedFilters] = useState<any>({ ...initialFilters })
  const [headersColListTemp, setHeadersColListTemp] = useState<any>([])
  const [anchorHeadersColEl, setAnchorHeadersColEl] = useState<null | Element>(null)
  const [showVersionIcon, setShowVersionIcon] = useState(false)
  const [selectedVersion, setSelectedVersion] = useState<null | {
    index: number
    record: Record<string, any>
  }>(null)
  const [isAnyMetaPermission, setIsAnyMetaPermission] = useState(true)
  const [savedFilterName, setSavedFilterName] = useState('')
  const [ispined, setIspined] = useState(false)
  const [allowPin, setAllowPin] = useState(false)
  const [pinnedErrorMessage, setpinnedErrorMessage] = useState('')
  const [pinSearchData, setPinSearchData] = useState([])

  const location = useLocation()
  const [dialogProps, setDialogProps] = useState<RoleConfirmationModal>({
    heading: '',
    description: '',
    color: 'primary',
    open: false,
  })

  const onSaveBtnClick = () => {
    getPinnedSearch()
    setDialogPropsSaveSearch({
      heading: CONSTANTS.SAVE_SEARCH_TITLE,
      color: 'primary',
      Icon: 'Pinned',
      open: true,
      iconColor: 'success',
      successButtonLabel: 'Submit',
      cancelButtonLabel: 'Cancel',
    })
  }
  const [exportStatus, setExportStatus] = useState('') // Add state for export status
  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null) // Ref to manage the timeout ID
  const API_REFRESH_INTERVAL = 10000 // 10 seconds
  const getPinnedSearch = async () => {
    const requestedData: any = HOME_PAGE.GET_PINNED_SEARCH_LIST()
    const result: any = await mainApiService(requestedData)
    if (result?.responseCode === 200) {
      if (result?.data) {
        setPinSearchData(result?.data)
        if (result?.data?.length < 6) {
          setAllowPin(true)
        } else {
          setAllowPin(false)
        }
      } else {
        setAllowPin(true)
        setPinSearchData([])
      }
    } else {
      setAllowPin(true)
    }
  }

  const headOrder = getLocalStorage(CONSTANTS.LOCAL_KEYS.LISTING_ORDER)
  const userData = getLocalStorage(CONSTANTS.LOCAL_KEYS.USER_DATA)
  const getHeadersOrder = async () => {
    const requiredApiData = USER_CONFIG.GET(userData.userId)
    dispatch(setLoader(true))
    const result = await mainApiService(requiredApiData)
    if (result?.responseCode === 200) {
      setLocalStorage(CONSTANTS.LOCAL_KEYS.LISTING_ORDER, result.data)
    } else {
      dispatch(showAlert([true, result?.error?.message, 'error']))
    }
    dispatch(setLoader(false))
  }

  const onReorderColumn = async (data: TypeObject[], columnList?: any) => {
    const tempArrangeColumn: any = []
    data.forEach((item) => {
      const columnId = item.id
      tempArrangeColumn.push(columnId)
    })
    const payload = {
      userId: userData?.userId,
      configuration: {
        ['AdvanceSearch']: tempArrangeColumn || [],
      } as Record<string, any>,
    }
    const requiredApiData = USER_CONFIG.UPDATE(payload)
    dispatch(setLoader(true))
    const result = await mainApiService(requiredApiData)
    if (result?.responseCode === 200) {
      const finalHeader = getOrderColumn(columnList || headers, tempArrangeColumn || [])
      setHeaders(finalHeader)
      getHeadersOrder()
      dispatch(showAlert([true, 'Listing view modified successfully', 'success']))
    } else {
      dispatch(showAlert([true, result?.error?.message, 'error']))
    }
    dispatch(setLoader(false))
  }

  // useEffect(() => {
  //   getHeadersOrder()
  // }, [])

  useEffect(() => {
    // Cleanup function to clear the timeout when the component is unmounted
    return () => {
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current!)
      }
    }
  }, [])

  useEffect(() => {
    setSavedFilter('/advanced-search', {
      filterData: deepClone(searchPayload),
      formData: deepClone({ ...formData }),
      headers: deepClone(headers),
    })
  }, [headers])

  useEffect(() => {
    setIsAnyMetaPermission(checkAnyMetaWritePermission())
  }, [])

  useEffect(() => {
    if (attributes.length) {
      getListingHeaders()
    }
  }, [attributes, formData])
  // useEffect(() => {
  //   getListingHeaders()
  // }, [headOrder])
  useEffect(() => {
    if (savedFilters?.filterData && searchParams.get('filter')) {
      const selectedFiltersCopy = {
        search: '',
        state: '',
        sort: savedFilters?.filterData?.sort?.[0]?.sortBy ?? '',
        sortType: savedFilters?.filterData?.sort?.[0]?.sortType ?? 1,
        filters: [],
        limit: savedFilters?.filterData?.limit ?? 10,
        pageNumber: savedFilters?.filterData?.page ?? 1,
        content: savedFilters?.filterData?.sort?.[0]?.sortIn === 'content' ? true : false,
      }
      setSelectedFilters({ ...selectedFiltersCopy })
    }
  }, [savedFilters])

  useEffect(() => {
    getListingData('search', { ...selectedFilters })
  }, [searchData])

  useEffect(() => {
    if (selectedVersion && selectedVersion.index >= 0) {
      if (versionRows && versionRows.index === selectedVersion.index) {
        setVersionRows(null)
        setSelectedVersion(null)
      } else {
        getNestedVersionList(selectedVersion.record, selectedVersion.index)
      }
    }
  }, [selectedVersion])

  /**
   * To generate asset url
   * @function generateContentURL
   * @param {string} _id - id of asset
   * @param {string} assetType - assetType of asset
   * @param {string} contentCategory - contentCategory of asset
   * @param {string} assetCategory - asset category of asset
   * @returns {string}
   */
  const generateContentURL = (
    _id: string,
    assetType: string,
    contentCategory: string,
    assetCategory: string,
  ): string => {
    if (!_id || !assetType || !assetCategory) {
      return ''
    }
    return `/asset/view/${_id}/${assetType}/${contentCategory}/${assetCategory}/${CONSTANTS.CATEGORIES.RIGHTS_AND_AVAILABILITY}`
  }

  /**
   * To generate header based on the attributes data provided in search data mapping it with attributes list.
   * @function getListingHeaders
   * @returns {void}
   */
  const getListingHeaders = () => {
    const { records = [] } = searchData
    const existingHeaders = headers
      .filter(
        (item: { content: boolean; viewInListing: boolean }) => !item.content && item.viewInListing,
      )
      .map((head: { id: string }) => ({ filterBy: head.id }))
    const searchedAttributes = searchPayload?.attributeFilters ?? []
    const attributesToAddInTable: any = [...existingHeaders, ...searchedAttributes]

    if (attributes.length) {
      const headersObj: any = {}
      processRecordsAdvanceSearch(records, attributes, headersObj, attributesToAddInTable)
      let headersArray: any = [
        ...JSON.parse(JSON.stringify(assetProperties)),
        ...Object.values(headersObj),
      ]
      headersArray = headersArray.sort((a: any, b: any) =>
        a['position'] === undefined
          ? 1
          : b['position'] === undefined
          ? -1
          : a['position'] - b['position'],
      )

      if (savedFilters?.headers?.length && searchParams.get('filter') && mounted) {
        setHeaders(savedFilters.headers)
      } else if ((location?.state?.id || searchParams.get(',id')) && searchPayload?.headers) {
        setHeaders(getOrderColumn(headersArray, searchPayload?.headers || []))
      } else {
        setHeaders(getOrderColumn(headersArray, (headOrder && headOrder['AdvanceSearch']) || []))
      }
    }
  }

  /**
   * To process the search data to be shown in a table
   * @function handleListingDataForTable
   * @param {string} records - search data in the form of array of objects
   * @param {boolean} isChild - is the child listing for child
   * @returns {array}
   */
  const handleListingDataForTable = (records: any, isChild = false) => {
    const rowData: any = []
    let isVersionIconTemp = false
    if (!isChild) {
      // records[0].versionCount = 2 // For demo puprpose
      records.forEach((record: any) => {
        if (record.versionCount > 1) {
          isVersionIconTemp = true
        }
      })
      setShowVersionIcon(isVersionIconTemp)
    } else {
      // formatNestedRecords(records)
      records.forEach((element: any) => {
        if (element?.firstCreatedOn) {
          element.firstCreatedOn = getDateInFormat(
            element?.firstCreatedOn as string,
            CONSTANTS.DATE_FORMAT_TIME,
          )
        }
      })
    }

    records.map((record: Record<string, any>, index: number) => {
      const recordCopy: Record<string, any> = {
        ...record,
        contentId: record.contentId,
        image: <AssetImage imageUrl={''} />,
        state: <AssetStateLabel state={record.state} />,
        avsContentStatus: getStatusMessage(record?.avsContentStatus, record?.action) || 'NA',
        versionCloneCount: (
          <AssetCloneVersion
            showClone={false}
            isVersionIcon={isVersionIconTemp}
            isChild={isChild}
            index={index}
            assetDetails={record}
            onClickAssetVersion={(record: Record<string, any>, index: number) =>
              setSelectedVersion({ record, index })
            }
            isAnyMetaPermission={isAnyMetaPermission}
            onClickClone={() => {
              return
            }}
          />
        ),
      }
      if (record?.metaAttributes?.landscapeThumb?.length) {
        recordCopy.image = (
          <AssetImage imageUrl={getThumbnailFromImageDetails(record?.metaAttributes)} />
        )
      }
      recordCopy['rowURL'] = generateContentURL(
        record._id,
        record.assetType,
        record.contentCategory,
        record.assetCategory,
      )
      delete recordCopy.ContentMetaCategory
      rowData.push(recordCopy)
    })
    return rowData
  }

  /**
   * To prepare the listing for table and calls the submit method in case of sorting and page change
   * @function getListingData
   * @param {string} type - filter / search based on this it will call sort and page change or search form data
   * @returns {void}
   */
  const getListingData = async (type: string, selectedFilterArgs: any) => {
    const payload: any = {
      ...searchPayload,
      page: selectedFilterArgs?.pageNumber ?? initialFilters.pageNumber,
      limit: selectedFilterArgs?.limit ?? initialFilters.limit,
    }

    payload.sort = selectedFilterArgs?.sort?.length
      ? [
          {
            sortBy: selectedFilterArgs?.sort,
            sortType: +selectedFilterArgs?.sortType,
            sortIn: selectedFilterArgs?.content ? 'content' : 'attribute',
          },
        ]
      : []

    if (type === 'filter') await submitForm(payload, false)

    setVersionRows(null)
    const { records = [], count = 0 } = searchData
    setTotalCount(count)
    const rowData: Record<string, any>[] = handleListingDataForTable(records)
    setRows(rowData)
    getListingHeaders()
  }

  /**
   * To handle page change of table
   * @function handlePageChange
   * @param {number} pageNumber - current page of table
   * @returns {void}
   */
  const handlePageChange = (pageNumber: any) => {
    const selectedFiltersCopy = { ...selectedFilters, pageNumber }
    setSelectedFilters(selectedFiltersCopy)
    getListingData('filter', selectedFiltersCopy)
  }

  /**
   * To handle page count change of table
   * @function handlePageCountChange
   * @param {number} limit - page count no of items to be shown in table
   * @returns {void}
   */
  const handlePageCountChange = (limit: any) => {
    const selectedFiltersCopy = { ...selectedFilters, pageNumber: 1, limit }
    setSelectedFilters(selectedFiltersCopy)
    getListingData('filter', selectedFiltersCopy)
  }

  /**
   * To handle page count change of table
   * @function handleSortChange
   * @param {number} limit - page count no of items to be shown in table
   * @returns {void}
   */

  const handleSortChange = (header: Record<string, any>, sortType: number) => {
    const selectedFiltersCopy = {
      ...selectedFilters,
      pageNumber: 1,
      sort: header.fieldName,
      sortType: String(sortType),
      content: header.content,
    }
    setSelectedFilters(selectedFiltersCopy)
    getListingData('filter', selectedFiltersCopy)
  }
  const clearSortFilters = (_header: Record<string, string>, filter: any) => {
    const newFilters: any = {
      ...selectedFilters,
      sort: '',
      sortType: '',
      pageNumber: 1,
    }
    setSelectedFilters(newFilters)
    getListingData('filter', newFilters)
  }

  /**
   * To navigate to asset details page.
   * @function handleOpenAsset
   * @param {string} rowDetails - row details of table which may have _id, assetType, assetCategory, contentCategory,
   * @returns {void}
   */
  const handleOpenAsset = (rowDetails: Record<string, any>) => {
    const dt = rowDetails.row
    setRouteToLocalStorage(`${location.pathname}${location.search}`)
    navigate(
      `/asset/view/${dt._id}/${dt.assetType}/${dt.contentCategory}/${dt.assetCategory}/${CONSTANTS.CATEGORIES.RIGHTS_AND_AVAILABILITY}`,
    )
  }

  /**
   * To get the data for child asset
   * @function handleListingDataForTable
   * @param {string} record - single record of search from table
   * @param {boolean} index - index of particular row from table
   * @returns {void}
   */
  const getNestedVersionList = async (record: Record<string, any>, index: number) => {
    setVersionRows(null)
    dispatch(setLoader(true))
    const payload: any = {
      ...searchPayload,
      page: selectedFilters.pageNumber,
      limit: selectedFilters.limit,
    }

    if (selectedFilters.sort) {
      payload.sort = [
        {
          sortBy: selectedFilters.sort,
          sortType: selectedFilters.sortType,
          sortIn: selectedFilters?.content ? 'content' : 'attribute',
        },
      ]
    }

    payload.blitzId = record.blitzId.toString()

    const requestedParams = CONTENT_CRUD.NESTED_VERSION_LIST_POST(payload)
    const controller = new AbortController()
    apiCancelTokens.push(controller)

    const apiRes: any = await mainApiService({ ...requestedParams, signal: controller.signal })
    dispatch(setLoader(false))

    if (Array.isArray(apiRes?.data)) {
      const childData = apiRes.data.filter((item: any) => item._id !== record._id)
      if (childData?.length) {
        setVersionRows({
          index,
          records: handleListingDataForTable(childData, true),
        })
      }
    }
  }
  const handleCloseSaveSearch = () => {
    setDialogPropsSaveSearch({
      heading: '',
      description: '',
      color: 'primary',
      open: false,
    })
    setSavedFilterName('')
    setAllowPin(true)
    setIspined(false)
    setpinnedErrorMessage('')
  }

  // Function to generate selectAttributes object
  const generateSelectAttributes = (headers: any) => {
    return headers
      .filter((header: any) => header.viewInListing)
      .reduce((acc: any, header: any) => {
        if (header.fieldName === 'image') {
          acc['landscapeThumb'] = true
        } else {
          acc[header.fieldName] = true
        }
        return acc
      }, {})
  }

  const handleExport = async (initialSearchPayload: TypeObject) => {
    dispatch(setLoader(true))
    try {
      // Use the function to get the selectAttributes object
      const selectAttributes = generateSelectAttributes(headers)
      const { avsContentStatus, ...filteredSelectAttributes } = selectAttributes
      // Modify the searchPayload
      const { page, limit, ...restPayload } = initialSearchPayload
      const modifiedSearchPayload = {
        ...restPayload,
        selectAttributes: filteredSelectAttributes,
      }
      const reqPayload = GLOBAL_SEARCH.EXPORT_SEARCH(modifiedSearchPayload)
      const result: any = await mainApiService(reqPayload)

      if (result?.error?.responseCode === 400) {
        setDialogProps({
          heading: CONSTANTS?.LIMIT_EXCEED,
          description: CONSTANTS?.LIMIT_EXCEED_CONTENT,
          color: 'error',
          Icon: 'ExportLimitCloseIcon',
          open: true,
          iconColor: 'error',
          successButtonLabel: 'Okay',
          handleSuccess: handleClose,
        })
        return
      } else if (result?.error) {
        dispatch(showAlert([true, result?.error?.message, 'error']))
        dispatch(setLoader(false))
        return
      }

      if (result?.responseCode === 200) {
        const status = result?.data?.exportStatus
        const exportId = result?.data?._id
        setExportStatus(status)
        if (status === 'IN_PROGRESS') {
          pollExportStatus(exportId, dispatch, modifiedSearchPayload)
        } else if (status === 'SUCCESS') {
          setExportStatus('SUCCESS')
        } else {
          console.error('Unexpected export status', result?.data)
        }
      } else {
        dispatch(showAlert([true, 'Unexpected response from the server', 'error']))
      }
    } catch (error) {
      dispatch(showAlert([true, 'Error initiating export', 'error']))
    } finally {
      dispatch(setLoader(false))
    }
  }

  const pollExportStatus = async (exportId: any, dispatch: any, modifiedSearchPayload: any) => {
    const poll = async () => {
      try {
        const reqData = {
          url: `/global-search/export/action/list?${exportId}`,
          method: 'GET',
        }
        const result = await mainApiService(reqData)
        if (result?.error) {
          dispatch(showAlert([true, result?.error?.message, 'error']))
          clearTimeout(timeoutIdRef.current!)
          return
        }
        if (result?.responseCode === 200) {
          const status = result?.data[0]?.exportStatus
          setExportStatus(status) // Update export status
          if (status === 'SUCCESS') {
            clearTimeout(timeoutIdRef.current!) // Clear the timeout when export is successful
            dispatch(showAlert([true, 'Export successful!', 'success']))
          } else if (status === 'IN_PROGRESS') {
            setLongPullTimeout() // Set the timeout again for polling
          } else {
            dispatch(showAlert([true, 'Export failed', 'error']))
            clearTimeout(timeoutIdRef.current!) // Clear the timeout when export is successful
          }
        } else {
          dispatch(showAlert([true, 'Unexpected response from the server', 'error']))
          clearTimeout(timeoutIdRef.current!) // Clear the timeout when export is successful
        }
      } catch (error) {
        dispatch(showAlert([true, 'Error polling export status', 'error']))
        clearTimeout(timeoutIdRef.current!) // Clear the timeout when export is successful
      }
    }

    const setLongPullTimeout = () => {
      timeoutIdRef.current = setTimeout(poll, API_REFRESH_INTERVAL)
    }

    setLongPullTimeout() // Start the polling
  }

  const getExportSearchList = async () => {
    const reqData = GLOBAL_SEARCH.EXPORT_SEARCH_LIST()
    const result = await mainApiService(reqData)
    setExportStatus(result?.data && result?.data[0]?.exportStatus)
  }

  useEffect(() => {
    getExportSearchList()
  }, [])

  useEffect(() => {
    updateTagMetaData({ title: 'Search' })
    return () => {
      updateTagMetaData({})
    }
  }, [])

  const savePinSearchSubmit = async (searchPayload: any) => {
    const { page, limit, ...restPayload } = searchPayload
    const selectedColumns = headers
      .filter((item: any) => item.viewInListing)
      .map((item: any) => item.id)
    restPayload.headers = selectedColumns
    const saveSearchPayload = {
      payload: restPayload,
      name: savedFilterName,
      isPinnedToDashboard: ispined,
    }

    const requestedPayload = GLOBAL_SEARCH.ADD_SAVE_SEARCH(saveSearchPayload)
    const result = await mainApiService(requestedPayload)
    if (result.data) {
      handleCloseSaveSearch()
      setSavedFilterName('')
      setIspined(false)
      getPinnedSearch()
      // dispatch(showAlert([true, result?.message, 'success']))
      setpinnedErrorMessage('')
    } else {
      if (result?.error?.blitzCode === 'BLITZ_22011') {
        setpinnedErrorMessage(result?.error?.message)
      } else {
        dispatch(showAlert([true, result?.error?.message, 'error']))
      }
    }
  }

  const handleClose = () => {
    setDialogProps({
      heading: '',
      description: '',
      color: 'error',
      open: false,
    })
  }

  // const onReorderColumn = (data: TypeObject[]) => {
  //   setHeaders(data)
  // }

  return (
    <>
      <ReorderColumnDialog
        headers={headers?.filter((item: any) => item.viewInListing)}
        open={reorderDilaog}
        onSubmit={onReorderColumn}
        handleClose={() => setReorderDialog(false)}
      />
      <div className='wrapper pb-1 pl-2 pr-2' id='advanced_search_list'>
        <MUIGrid
          className='interFont'
          style={{
            width: '100%',
            backgroundColor: '#f5f4f4',
          }}
        >
          <MUIGrid
            container
            className='d-flex mb-1 gap-2 justify-content-end align-items-center listing-filter'
          >
            {rows && rows.length > 0 && (
              <>
                <div>
                  <Tooltip
                    title={exportStatus === 'IN_PROGRESS' && 'Previous download is in progress'}
                    placement='top'
                    arrow
                    componentsProps={{
                      tooltip: {
                        sx: {
                          padding: '1rem',
                          fontSize: '12px',
                          fontWeight: '500',
                          color: '#402080',
                          bgcolor: '#ECE9F2',
                          '& .MuiTooltip-arrow': {
                            color: '#ECE9F2',
                          },
                          display: `${exportStatus === 'IN_PROGRESS' ? 'block' : ' none'}`,
                        },
                      },
                    }}
                  >
                    <div>
                      <MUIButton
                        className={styles.exportBtn}
                        variant={'outlined'}
                        label={'Export'}
                        type='button'
                        handleClickCB={() => handleExport(searchPayload)}
                        fontWeight={500}
                        disabled={exportStatus === 'IN_PROGRESS'} // Disable button while export is in progress
                      />
                    </div>
                  </Tooltip>
                </div>
              </>
            )}
            <div
              className='bg-col70 d-flex align-items-center cursor-pointer'
              style={{
                height: '40px',
                padding: '0 12px 0 12px',
                borderRadius: '6px',
                border: '1px solid #B1B4B6',
                textAlign: 'end',
              }}
              data-testid='list_add_column-test'
              onClick={(event: any) => {
                if (headers?.length) {
                  const tempList = JSON.parse(JSON.stringify(headers))
                  setHeadersColListTemp(
                    tempList.map((item: any) => ({ ...item, select: item.viewInListing })),
                  )
                  setAnchorHeadersColEl(event.currentTarget)
                }
              }}
            >
              <span style={{ fontSize: '14px' }}>Add Column</span>
              <span>
                {anchorHeadersColEl ? (
                  <KeyboardArrowUp sx={{ marginTop: '10px', color: '#8E9092' }} />
                ) : (
                  <KeyboardArrowDown sx={{ marginTop: '10px', color: '#8E9092' }} />
                )}
              </span>
            </div>

            {anchorHeadersColEl && (
              <HeadersColMenu
                anchorEl={anchorHeadersColEl as any}
                open={true}
                search='Search Attibute'
                onClose={() => {
                  setAnchorHeadersColEl(null)
                }}
                clear
                headersColListTemp={headersColListTemp}
                setHeadersColListTemp={setHeadersColListTemp}
                setHeadersColList={(tempList) => {
                  if (tempList.length) {
                    const selectedHeadersCopy = tempList.map((item: any) => ({
                      ...item,
                      viewInListing: item.select,
                    }))
                    const columnHeader = selectedHeadersCopy.filter(
                      (item: any) => item.viewInListing,
                    )
                    onReorderColumn(columnHeader, selectedHeadersCopy)
                  } else {
                    setHeadersColListTemp([])
                  }
                }}
                showLoaderFn={(action: boolean) => {
                  dispatch(setLoader(action))
                }}
                columnDefaultOrder={headers}
              />
            )}
          </MUIGrid>

          <MUICommonTable
            headers={[
              {
                id: 'versionCloneCount',
                fieldName: 'versionCloneCount',
                label: '',
                sort: false,
                icon: (
                  <Tooltip title='Rearrange Columns' placement='right-end' arrow>
                    <span
                      className='d-flex justify-content-center align-items-center cursor-pointer'
                      onClick={() => setReorderDialog(true)}
                      data-testId='reOrderDragIcon'
                    >
                      <img src={rearrangeIcon} />
                    </span>
                  </Tooltip>
                ),

                class: showVersionIcon ? 'list_version_icon' : 'rearrange_icon',
              },

              ...headers.filter((item: any) => item.viewInListing),
            ]}
            rows={rows}
            childRows={versionRows}
            sortFilterKey={'fieldName'}
            selectedFilters={selectedFilters}
            onChangeSort={handleSortChange}
            onClearSortFilters={clearSortFilters}
            onClickRow={handleOpenAsset}
            pagination={{
              show: true,
              pageLimit: selectedFilters.limit,
              pageNumber: selectedFilters.pageNumber,
              totalCount: totalCount,
              onPageChange: handlePageChange,
              onPageCountChange: handlePageCountChange,
              limitOptions: [...CONSTANTS.LISTING_LIMIT_OPTIONS],
            }}
          />
        </MUIGrid>
        <div className='mt-15 d-flex justify-content-end'>
          <MUIButton
            size='large'
            type={'button'}
            label={'Save & Pin'}
            data-testid='submit-btn-search'
            variant='contained'
            // width={250}
            className='save-btn interfont'
            handleClickCB={onSaveBtnClick}
          />
        </div>
        <RoleConfirmation
          handleSuccess={() => {
            savePinSearchSubmit(searchPayload)
          }}
          description={
            <div>
              <div className='mt-1'>
                <TextField
                  label={
                    <span>
                      Name
                      <>
                        <span className='col37'>*</span>
                      </>
                    </span>
                  }
                  onChange={(evt: any) => {
                    if (savedFilterName.length < 25) {
                      setSavedFilterName(evt.target.value)
                    } else {
                      setSavedFilterName(evt.target.value.slice(0, 25))
                    }
                  }}
                  onPaste={(e: any) => {
                    setSavedFilterName(e.clipboardData.getData('text').slice(0, 25))
                  }}
                  value={savedFilterName}
                  name='search_name'
                  helperText={pinnedErrorMessage}
                  error={Boolean(pinnedErrorMessage.length)}
                  fullWidth
                  inputProps={{ 'data-testid': 'activationCode' }}
                />
              </div>
              <div className='mt-1 d-flex justify-content-between allign-items-center'>
                {allowPin ? (
                  <MUICheckbox
                    label={'Pin to Homepage'}
                    checked={ispined}
                    labelClass='ml-1 paragraph-regular-6 col36'
                    handleChange={() => {
                      setIspined((prevState) => !prevState)
                    }}
                  />
                ) : (
                  <div className='d-flex '>
                    <Tooltip
                      title={
                        'Maximum pin limit reached. Please unpin any result to pin the current one.'
                      }
                      placement='top-start'
                      arrow
                      componentsProps={VideoAssetGroupConstants.SAVE_SEARCH_TOOLTIP_CSS}
                    >
                      <CheckBoxOutlineBlankIcon sx={{ color: '#B1B4B6' }} />
                    </Tooltip>
                    <span className='ml-1'>Pin to Homepage</span>
                  </div>
                )}
                <p>{savedFilterName?.length}/25</p>
              </div>
            </div>
          }
          minWidth='778px'
          {...dialogPropsSaveSearch}
          handleClose={handleCloseSaveSearch}
        />
        <RoleConfirmation {...dialogProps} handleClose={handleClose} />
      </div>
    </>
  )
}

export default AdvancedSearchList
