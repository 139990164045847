import { Grid } from '@mui/material'
import RedCrossIcon from '../../Icons/RedCrossIcon'
import MUIButton from '../MUI-Button'
import MUIMultiselectAsset from '../MUI-Multiselect-Asset/MUI-Multiselect-Asset'

const JSONInputField1: React.FC<any> = ({
  index,
  label,
  fields,
  addMore,
  addMoreRemoveAction,
  formFieldsOptions,
  onInputChange,
}: any) => {
  return (
    <>
      {fields?.map((fieldsArray: any, fieldsIndex: number) => (
        <div className='partner-asset-type-config-wrapper' key={fieldsIndex}>
          <div className='text-left config-title'>
            <p className='center-vertical'>
              {label} {fields?.length > 1 ? fieldsIndex + 1 : ''}
            </p>
            {fields?.length > 1 && (
              <div
                className='ml-auto cursor-pointer red-cross-icon'
                onClick={() => addMoreRemoveAction('', index, fieldsIndex, false)}
                data-testid='remove-json1-field'
              >
                <RedCrossIcon />
              </div>
            )}
          </div>
          <div className='config-form'>
            <Grid container columnSpacing={10} rowSpacing={4}>
              {fieldsArray.map((field: any, fieldIndex: number) => {
                let options = field?.options
                if (field?.formFieldsOptionsKey && formFieldsOptions[field.formFieldsOptionsKey]) {
                  options = formFieldsOptions[field.formFieldsOptionsKey]
                }
                return (
                  <Grid item xs={6} md={6} lg={6} xl={6} key={fieldsIndex + '_' + fieldIndex}>
                    <MUIMultiselectAsset
                      id={field?.fieldName}
                      name={field?.fieldName}
                      className='autocomple-class'
                      multiple={field?.isMultiple}
                      label={field?.label}
                      options={options}
                      value={field?.value}
                      labelKey={field?.labelKey}
                      required={field?.validations?.required}
                      // error={getErrorObject(field)}
                      onInputChange={(_ev: any, newValue: any) => {
                        onInputChange({ ev: newValue, index, item: field, fieldsIndex, fieldIndex })
                      }}
                    />
                  </Grid>
                )
              })}
            </Grid>
          </div>
        </div>
      ))}
      {addMore && (
        <Grid item xs={12} className='d-flex justify-content-end'>
          <MUIButton
            width={160}
            height='48px'
            textTransform={'capitalize'}
            variant='outlined'
            size='large'
            color={'secondary'}
            type='button'
            label={'+ Add More'}
            handleClickCB={() => addMoreRemoveAction('', index, null, true)}
          />
        </Grid>
      )}
    </>
  )
}
export default JSONInputField1
