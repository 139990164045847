import { VideoAssetGroupConstants } from '../../../../../Constants'
import { isValidArray, updateAudioLanguage } from '../../../../../Helpers/Util'

export const prepareNewAssetGroupPayload = (contentId: string, assetGroupName: string) => {
  if (contentId && assetGroupName) {
    return {
      contentId: contentId,
      assetGroupName: assetGroupName,
      isIntegrated: false,
      validateAssetGroup: false,
    }
  }
  return null
}

export const prepareRenameAssetGroupPayload = (
  contentId: string,
  assetGroupUUID: string,
  assetGroupName: string,
) => {
  if (contentId && assetGroupUUID) {
    return {
      contentId: contentId,
      assetGroupId: assetGroupUUID,
      assetGroupName: assetGroupName,
    }
  }
  return null
}

export const prepareLinkAssetGroupPayload = (contentId: string, assetGroupUUID: string) => {
  if (contentId && assetGroupUUID) {
    return {
      contentId: contentId,
      assetGroupId: assetGroupUUID,
      linkContent: true,
    }
  }
  return null
}

export const prepareUnlinkAssetGroupPayload = (contentId: string, assetGroupTmpId: string) => {
  if (contentId && assetGroupTmpId) {
    return {
      contentId: contentId,
      assetGroupId: assetGroupTmpId,
    }
  }
  return null
}

const getAddEventType = (forType: string) => {
  switch (forType) {
    case VideoAssetGroupConstants.VIDEO_LABEL:
      return VideoAssetGroupConstants.VIDEO_ADD
    case VideoAssetGroupConstants.AUDIO_LABEL:
      return VideoAssetGroupConstants.AUDIO_ADD
    case VideoAssetGroupConstants.INTEGRATED_LABEL:
      return VideoAssetGroupConstants.INTEGRATED_ADD
    default:
      return ''
  }
}

export const prepareFilesUpdateAssetGroupPayload = (
  contentId: string,
  assetGroupDetails: any,
  files: [],
  forType: string,
  multiAsset: boolean,
) => {
  // const eventTypeMapping = {
  //   [VideoAssetGroupConstants.VIDEO_LABEL]: VideoAssetGroupConstants.VIDEO_ADD,
  //   [VideoAssetGroupConstants.AUDIO_LABEL]: VideoAssetGroupConstants.AUDIO_ADD,
  //   [VideoAssetGroupConstants.INTEGRATED_LABEL]: VideoAssetGroupConstants.INTEGRATED_ADD,
  // }
  if (contentId && assetGroupDetails?.uuid) {
    const payload: any = {
      contentId: contentId,
      assetGroupId: assetGroupDetails?.uuid,
      assetGroupName: assetGroupDetails?.name,
      multiAsset: multiAsset,
      eventType: getAddEventType(forType),
    }
    if (forType === VideoAssetGroupConstants.VIDEO_LABEL) {
      const tmpArr = files?.map((item: any) => item?.Key)
      assetGroupDetails?.video?.map((item: any) => {
        tmpArr.push(item?.path)
      })
      payload['video'] = tmpArr || []
    }
    if (forType === VideoAssetGroupConstants.AUDIO_LABEL) {
      const tmpArr: any = []
      files?.map((item: any) => {
        tmpArr.push({ language: '', path: item?.Key })
      })
      assetGroupDetails?.audio?.map((item: any) => {
        tmpArr.push(item)
      })
      payload['audio'] = tmpArr || []
    }
    if (forType === VideoAssetGroupConstants.INTEGRATED_LABEL) {
      const tmpArrV = files?.map((item: any) => item?.Key)
      assetGroupDetails?.video?.map((item: any) => {
        tmpArrV.push(item?.path)
      })
      payload['video'] = tmpArrV || []

      const tmpArrA: any = []
      files?.map((item: any) => {
        tmpArrA.push({ language: [], path: item?.Key })
      })
      assetGroupDetails?.audio?.map((item: any) => {
        tmpArrA.push(item)
      })
      payload['audio'] = tmpArrA || []
      payload['isIntegrated'] = true
    }
    return payload
  }
  return null
}

export const getSearchLabel = (type: string) => {
  switch (type) {
    case 'video':
      return 'Search Video Files'
    case 'audio':
      return 'Search Audio Files'
    case 'integrated':
      return 'Search Integrated Files'
    default:
      return 'Search Asset Group'
  }
}

const getRemoveEventType = (forType: string) => {
  switch (forType) {
    case VideoAssetGroupConstants.VIDEO_LABEL:
      return VideoAssetGroupConstants.VIDEO_REMOVE
    case VideoAssetGroupConstants.AUDIO_LABEL:
      return VideoAssetGroupConstants.AUDIO_REMOVE
    case VideoAssetGroupConstants.INTEGRATED_LABEL:
      return VideoAssetGroupConstants.INTEGRATED_REMOVE
    default:
      return ''
  }
}

export const prepareFilesDeleteAssetGroupPayload = (
  contentId: string,
  assetGroupDetails: any,
  index: number,
  forType: string,
  multiAsset: boolean,
) => {
  // const eventTypeMapping = {
  //   [VideoAssetGroupConstants.VIDEO_LABEL]: VideoAssetGroupConstants.VIDEO_REMOVE,
  //   [VideoAssetGroupConstants.AUDIO_LABEL]: VideoAssetGroupConstants.AUDIO_REMOVE,
  //   [VideoAssetGroupConstants.INTEGRATED_LABEL]: VideoAssetGroupConstants.INTEGRATED_REMOVE,
  // }
  if (contentId && assetGroupDetails?.uuid) {
    const payload: any = {
      contentId: contentId,
      assetGroupId: assetGroupDetails?.uuid,
      assetGroupName: assetGroupDetails?.name,
      multiAsset: multiAsset,
      eventType: getRemoveEventType(forType),
    }
    if (forType === VideoAssetGroupConstants.VIDEO_LABEL) {
      const copyArr = JSON.parse(JSON.stringify(assetGroupDetails?.video))
      copyArr?.splice(index, 1)
      const tmpArr = copyArr?.map((item: any) => item?.path)
      payload['video'] = tmpArr || []
    }
    if (forType === VideoAssetGroupConstants.AUDIO_LABEL) {
      const copyArr = JSON.parse(JSON.stringify(assetGroupDetails?.audio))
      copyArr?.splice(index, 1)
      payload['audio'] = copyArr || []
    }
    if (forType === VideoAssetGroupConstants.INTEGRATED_LABEL) {
      const copyArrA = JSON.parse(JSON.stringify(assetGroupDetails?.audio))
      copyArrA?.splice(index, 1)
      payload['audio'] = copyArrA || []

      const copyArrB = JSON.parse(JSON.stringify(assetGroupDetails?.video))
      copyArrB?.splice(index, 1)
      const tmpArr = copyArrB?.map((item: any) => item?.path)
      payload['video'] = tmpArr || []
      payload['isIntegrated'] = true
    }
    return payload
  }
  return null
}

const updateIntegratedLanguage = (assetGroupDetails: any, language: any, atIndex: any) => {
  let languagesArr: any = []
  if (language && isValidArray(language)) {
    languagesArr = language.map((element: any) => element.key)
  }
  const copyArr = JSON.parse(JSON.stringify(assetGroupDetails?.audio))
  copyArr.forEach((item: any, index: number) => {
    if (index === atIndex) {
      item.language = languagesArr || []
    }
  })
  return copyArr || []
}

export const prepareFilesLanguagePayload = (
  contentId: string,
  assetGroupDetails: any,
  forType: string,
  language: any,
  atIndex: number,
  multiAsset: boolean,
) => {
  if (contentId && assetGroupDetails?.uuid) {
    const payload: any = {
      contentId: contentId,
      assetGroupId: assetGroupDetails?.uuid,
      assetGroupName: assetGroupDetails?.name,
      multiAsset: multiAsset,
      eventType: VideoAssetGroupConstants.LANGUAGE,
    }
    if (forType === VideoAssetGroupConstants.AUDIO_LABEL) {
      payload['audio'] = updateAudioLanguage(assetGroupDetails, language, atIndex)
    }

    if (forType === VideoAssetGroupConstants.INTEGRATED_LABEL) {
      payload['audio'] = updateIntegratedLanguage(assetGroupDetails, language, atIndex)
      payload['isIntegrated'] = true
    }
    return payload
  }
  return null
}

export const prepareFetchValidatePayload = (contentId: string, assetGroupDetails: any) => {
  if (contentId && assetGroupDetails?.uuid) {
    return {
      contentId: contentId,
      assetGroupId: assetGroupDetails?.uuid,
    }
  }
  return null
}

export const prepareWorkflowPayload = (
  contentId: string,
  assetGroupDetails: any,
  assetGroupEvent: string,
) => {
  if (contentId && assetGroupDetails?.tmpId) {
    return {
      contentId: contentId,
      assetGroupId: assetGroupDetails?.tmpId,
      assetGroupEvent: assetGroupEvent,
      contentVersionId: contentId,
    }
  }
  return null
}

export const getIntegratedLanguageCompleteName = (
  languages: Array<string>,
  languageList: any[],
) => {
  try {
    const resultArr: Array<string> = []
    if (isValidArray(languages)) {
      languages.forEach((element: any) => {
        if (element === 'empty') {
          resultArr.push('EMPTY')
        } else {
          const languageValueUC = getLanguageCompleteName(element, languageList)
          resultArr.push(languageValueUC)
        }
      })
    }
    return resultArr.filter((item) => item?.length)
  } catch (error) {
    return []
  }
}

export const getLanguageCompleteName = (language: string, languageList: any[]) => {
  const matchedLang = languageList?.find?.((item: any) => item?.key === language?.toUpperCase())
  return matchedLang?.value || ''
}

export const prepareMediaProfileGroupPayload = (
  contentId: string,
  assetGroupDetails: any,
  mediaProfileValue: string,
) => {
  if (contentId && assetGroupDetails?.uuid) {
    return {
      contentId: contentId,
      assetGroupId: assetGroupDetails?.uuid,
      assetGroupName: assetGroupDetails?.name,
      mediaProfile: mediaProfileValue,
      eventType: VideoAssetGroupConstants.MEDIA_PROFILE,
    }
  }
  return null
}
/**
 * Preparing payload for transcoding process type api
 *
 * Renders Object of key value pare of required payload.
 *
 * @function
 * @param contentId - Content Id of selected asset group.
 * @param assetGroupDetails - containing details of assetgroup.
 * @param transcodingprocessTypeValue - Value of selected transcoding process type from dropdown.
 * @returns returning payload with expected key value pare
 */
export const prepareTranscodingProcessTypePayload = (
  contentId: string,
  assetGroupDetails: any,
  transcodingprocessTypeValue: string,
) => {
  if (contentId && assetGroupDetails?.uuid) {
    return {
      contentId: contentId,
      assetGroupId: assetGroupDetails?.uuid,
      assetGroupName: assetGroupDetails?.name,
      mediaProfile: assetGroupDetails.mediaProfile,
      assetType: transcodingprocessTypeValue,
      eventType: VideoAssetGroupConstants.TRANSCODING_PROCESS_TYPE,
    }
  }
  return null
}

export const prepareIntegratedCheckboxPayload = (
  contentId: string,
  assetGroupDetails: any,
  isIntegrated: boolean,
) => {
  if (contentId && assetGroupDetails?.uuid) {
    return {
      contentId: contentId,
      assetGroupId: assetGroupDetails?.uuid,
      assetGroupName: assetGroupDetails?.name,
      isIntegrated: isIntegrated,
    }
  }
  return null
}

export const getFieldError = (item: any, fieldErrors: any) => {
  return fieldErrors?.[item.fieldName] ?? ''
}

/**
 * Extracts the MIME type for a given URL based on its file extension.
 * @param {string} url - The URL for which the MIME type is to be extracted.
 * @returns {string | undefined} - The MIME type corresponding to the given URL's file extension. Returns undefined if the file extension is not recognized.
 */
export const extractType = (url: string) => {
  if (url.indexOf('m3u8') !== -1) {
    return 'application/x-mpegURL'
  } else if (url.indexOf('mpd') !== -1) {
    return 'application/dash+xml'
  } else {
    return
  }
}

/**
 * Comparator function for sorting language objects based on their keys.
 * @param {any} a - The first language object to compare.
 * @param {any} b - The second language object to compare.
 * @returns {number} - An integer indicating the relative ordering of the two language objects.
 */
export const languagesSort = (a: any, b: any) => {
  if (a.key < b.key) {
    return -1
  }
  if (a.key > b.key) {
    return 1
  }
  return 0
}

export const prepareLinkedAssetGroupIdsPayload = (contentId: string, assetGroupDetails: any) => {
  if (contentId && assetGroupDetails?.uuid) {
    return {
      contentId: contentId,
      assetGroupId: assetGroupDetails?.uuid,
      assetGroupName: assetGroupDetails?.name,
      multiAsset: true,
    }
  }
  return null
}

type ErrorDetails = {
  [key: string]: string
}[]

interface Response {
  error_details?: ErrorDetails
  'error-details'?: ErrorDetails | { [key: string]: string }
}

export const processTranscodingErrors = (response: Response): string[] => {
  const errors: string[] = []

  if (response?.error_details?.length) {
    response.error_details.forEach((ele) => {
      errors.push(...Object.values(ele))
      errors.push(' ')
    })
  } else {
    if (Array.isArray(response['error-details'])) {
      response['error-details'].forEach((ele) => {
        errors.push(...Object.values(ele))
        errors.push(' ')
      })
    } else if (response['error-details'] && typeof response['error-details'] === 'object') {
      if (!errors.length && Object.keys(response['error-details']).length) {
        Object.entries(response['error-details']).forEach(([key, value]) => {
          errors.push(`${key}: "${value}"`)
          errors.push(' ')
        })
      } else {
        errors.push(JSON.stringify(response))
        errors.push(' ')
      }
    } else {
      errors.push(JSON.stringify(response))
      errors.push(' ')
    }
  }

  return errors
}

export const sortS3List = (copyList: any[], sortingFor: string, sortingType: string): any[] => {
  return copyList.sort((a, b) => {
    let valueA, valueB

    if (sortingFor === 'name') {
      valueA = a.Key || '' // Treat blank as an empty string
      valueB = b.Key || ''
    } else if (sortingFor === 'type') {
      valueA = a.type || '' // Treat blank as an empty string
      valueB = b.type || ''
    } else if (sortingFor === 'time') {
      valueA = a.lastUpdatedOn ? new Date(a.lastUpdatedOn).getTime() : null
      valueB = b.lastUpdatedOn ? new Date(b.lastUpdatedOn).getTime() : null
    }

    if (sortingFor === 'time') {
      if (sortingType === 'asc') {
        if (valueA === null && valueB !== null) return -1 // Blank (null) values first
        if (valueB === null && valueA !== null) return 1
        return (valueA as number) - (valueB as number) // Smaller to larger
      } else {
        if (valueA === null && valueB !== null) return 1 // Blank (null) values last
        if (valueB === null && valueA !== null) return -1
        return (valueB as number) - (valueA as number) // Larger to smaller
      }
    } else {
      if (sortingType === 'asc') {
        if (valueA === '' && valueB !== '') return -1 // Blank values first
        if (valueB === '' && valueA !== '') return 1
        if (valueA < valueB) return -1
        if (valueA > valueB) return 1
      } else {
        if (valueA === '' && valueB !== '') return 1 // Blank values last
        if (valueB === '' && valueA !== '') return -1
        if (valueA < valueB) return 1
        if (valueA > valueB) return -1
      }
    }
    return 0
  })
}
