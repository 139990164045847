import { Close } from '@mui/icons-material'
import { Grid } from '@mui/material'
import React, { useState } from 'react'
import CONSTANTS from '../../Constants'
import { BULK_UPDATE } from '../../Constants/ApiDefinations'
import { isJobCancelledBeforeStart } from '../../Helpers/BulkUpload'
import {
  downloadBulkUpdateDataFunction,
  getCancelJobHeading,
  getDateInFormat,
  getSubstring,
} from '../../Helpers/Util'
import { TypeObject } from '../../Models'
import mainApiService from '../../Services'
import { useAppDispatch, useAppSelector } from '../../Store/Hooks'
import { showAlert } from '../../Store/Slice/alert.slice'
import { selectBulkUploadSlice, setcancelBulkUpdate } from '../../Store/Slice/bulkUpdate.slice'
import { setLoader } from '../../Store/Slice/loader.slice'
import RoleConfirmation from '../Dialogs/RoleConfirmation'
import { WatchPlusIcon } from '../Icons'
import DownloadIcon from '../Icons/DownloadIcon2'
import { ChipButton, MUIBox } from '../Shared'
import { EditIcon } from '../Shared/MUI-Drawer/MUI-Drawer.svgIcons'
import './BulkUpdate.scss'
import ScheduleBulkUpdate from './ScheduleBulkUpdate'
interface PartnerInformationModal {
  view?: any
  viewData?: any[]
  id: string
  jobData: TypeObject
  pageType: string
  handleDialog: any
  handleCloseDialog: any
  refresh: any
}
const BulkUpdateInformation: React.FC<PartnerInformationModal> = ({
  view,
  viewData,
  id,
  pageType,
  jobData,
  handleDialog,
  handleCloseDialog,
  refresh,
}: PartnerInformationModal) => {
  const [showScheduleBulkUpdate, setShowScheduleBulkUpdate] = useState<boolean>(false)
  const cancelBlukUpdateStatus = useAppSelector(selectBulkUploadSlice)
  const dispatch = useAppDispatch()
  const filePath = view?.filePath?.split('/') || []

  const downloadFile = () => {
    const link = document.createElement('a')
    link.href = CONSTANTS.CLOUDFRONT_BASE_URL + view?.filePath

    link.download = view?.filePath

    document.body.appendChild(link)

    link.click()

    document.body.removeChild(link)

    dispatch(showAlert([true, 'File downloaded successfully', 'success']))
  }
  const isCancelableStatus = (status: any) =>
    ['IN_PROGRESS', 'IN_QUEUE', 'SCHEDULED'].includes(status)

  return (
    <>
      <MUIBox>
        {view?.status && (
          <div className='view-schedule'>
            <div className='heading-6 d-flex text-left rolebox__header-padding'>
              <div className='d-flex align-items-center'>{jobData?.jobName}</div>
              <div className='ml-auto d-flex'>
                {view?.status === 'IN_QUEUE' && (
                  <div className='mr-1'>
                    <ChipButton type={'secondary'} label={'In Queue'} />
                  </div>
                )}
                {view?.status === 'IN_PROGRESS' && (
                  <div className='mr-1'>
                    <ChipButton type={'info'} label={'In Progress'} />
                  </div>
                )}
                {view?.status === 'COMPLETED' && (
                  <div className='mr-1'>
                    <ChipButton type={'success'} label={'Completed'} />
                  </div>
                )}
                {view?.status === 'SCHEDULED' && (
                  <div className='mr-1'>
                    <ChipButton type={'primary'} label={'Scheduled'} />
                  </div>
                )}
                {isCancelableStatus(view?.status) && (
                  <div
                    data-testid='cancel-btn'
                    onClick={() => dispatch(setcancelBulkUpdate({ show: true, data: view }) as any)}
                  >
                    <ChipButton type={'danger'} Icon={Close as any} label={'Cancel'} />
                  </div>
                )}
                {view?.status === 'COMPLETED' && (
                  <div
                    onClick={(e: any) => {
                      if (isJobCancelledBeforeStart(view)) {
                        dispatch(showAlert([true, CONSTANTS.BULK_UPDATE_CANCELLED_JOB, 'error']))
                        return
                      }
                      e.stopPropagation()
                      downloadBulkUpdateDataFunction(view, dispatch)
                    }}
                    data-testid='download-id'
                  >
                    <ChipButton
                      type={'primary'}
                      Icon={DownloadIcon as any}
                      label={'Download Report'}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className={'hr bulk__update'}></div>
            {viewData?.map((item: any, inx: number) => {
              const keyName = item?.label + '_' + inx // NOSONAR
              return (
                <div className='' key={keyName}>
                  <Grid container>
                    <Grid item xs={6} md={3}>
                      <div className='sub-heading-6 view__role-list  text-left col34'>
                        {item?.label}
                      </div>
                    </Grid>
                    <Grid item xs={6} md={9}>
                      <div
                        className='sub-heading-6 view__role-list  text-left col36 word-wrap'
                        title={item?.isTitle && item?.value?.length > 40 ? item?.value : ''}
                      >
                        {item?.isTitle
                          ? getSubstring(item?.value, 40) || 'NA'
                          : item?.value || 'NA'}
                      </div>
                    </Grid>
                  </Grid>
                </div>
              )
            })}

            <div>
              <div className='heading-6 d-flex text-left rolebox__header-padding'>
                <div className='d-flex align-items-center'>Uploaded File</div>
                <div
                  className='ml-auto d-flex bulk-update-btn'
                  data-testid='dowload-file'
                  onClick={downloadFile}
                >
                  <ChipButton type={'primary'} Icon={DownloadIcon as any} label={'Download File'} />
                </div>
              </div>

              <Grid container>
                <Grid item xs={6} md={3}>
                  <div className='sub-heading-6 view__role-list text-left col34'>File Name</div>
                </Grid>
                <Grid item xs={6} md={9}>
                  <div className='sub-heading-6 view__role-list text-left col36 word-wrap'>
                    {filePath[filePath.length - 1]}
                  </div>
                </Grid>
              </Grid>
            </div>

            {view?.status === 'SCHEDULED' && (
              <div>
                <div className={'hr bulk__update'}></div>
                {showScheduleBulkUpdate ? (
                  <ScheduleBulkUpdate
                    id={id as string}
                    jobDetails={jobData}
                    pageType={pageType as string}
                    handleShow={(value: boolean) => setShowScheduleBulkUpdate(value)}
                    handleDialog={handleDialog}
                    handleCloseDialog={handleCloseDialog}
                    refresh={refresh}
                  />
                ) : (
                  <>
                    <Grid container>
                      <Grid item xs={12} md={12}>
                        <div className='heading-6 d-flex text-left view__role-list rolebox__header-padding'>
                          <div className='heading'>
                            <div className='icon'>
                              <WatchPlusIcon />
                            </div>
                            <div className='text'>Schedule Update</div>
                          </div>
                          <div
                            className='ml-auto d-flex bulk-update-btn'
                            data-testid='edit-schedule'
                            onClick={() => setShowScheduleBulkUpdate(true)}
                          >
                            <ChipButton
                              type={'primary'}
                              Icon={EditIcon as any}
                              label={'Edit Schedule'}
                            />
                          </div>
                        </div>
                      </Grid>
                    </Grid>

                    <Grid container>
                      <Grid item xs={6} md={3}>
                        <div className='sub-heading-6 view__role-list text-left col34'>
                          Update Date and Time
                        </div>
                      </Grid>
                      <Grid item xs={6} md={9}>
                        <div
                          data-testid='schedule-text'
                          className='sub-heading-6 view__role-list text-left col36 word-wrap'
                        >
                          {getDateInFormat(view?.scheduleDate ?? '', 'dd/MM/yyyy HH:mm')}
                        </div>
                      </Grid>
                    </Grid>
                  </>
                )}
              </div>
            )}
          </div>
        )}
      </MUIBox>
      <RoleConfirmation
        Icon={'DeleteRoleIcon'}
        color='error'
        open={cancelBlukUpdateStatus?.show}
        heading={getCancelJobHeading(view?.status)}
        description={(() => {
          let description: any = ''
          if (view?.status === 'IN_PROGRESS') {
            description = (
              <p>
                Are you sure you want to cancel the job
                <strong> {cancelBlukUpdateStatus?.data.jobName}</strong>? Canceling the job will
                only stop updates for pending assets. Please note that some assets may have already
                been updated, and canceling the job will not revert any changes to those particular
                assets.
              </p>
            )
          } else if (view?.status === 'IN_QUEUE') {
            description = (
              <p>
                Are you sure you want to cancel the job
                <strong> {view?.jobName}</strong>? The job is already in the queue for the update.
              </p>
            )
          } else if (view?.status === 'SCHEDULED') {
            description = (
              <p>
                Are you sure you want to cancel the scheduled job <strong> {view.jobName}</strong>?
              </p>
            )
          }
          return description
        })()}
        successButtonLabel='Yes'
        handleSuccess={async () => {
          const { data = {} } = cancelBlukUpdateStatus
          const payload = { jobId: data._id }
          const requestedData: any = BULK_UPDATE.CANCEL_JOB(payload)
          const result = await mainApiService(requestedData)
          dispatch(setLoader(false))
          if (result?.responseCode === 200) {
            dispatch(showAlert([true, result.message, 'success']))
            dispatch(setcancelBulkUpdate({ show: false, data: {} }) as any)
            refresh()
          } else {
            dispatch(showAlert([true, result?.error?.errors || result?.error?.message, 'error']))
            dispatch(setcancelBulkUpdate({ show: false, data: {} }) as any)
          }
        }}
        cancelButtonLabel='No'
        handleClose={() => {
          dispatch(setcancelBulkUpdate({ show: false, data: {} }) as any)
        }}
      />
    </>
  )
}

export default BulkUpdateInformation
