import { TextField } from '@mui/material'
import React from 'react'
import CONSTANTS from '../../../Constants'

const MUIFieldWithoutControl: React.FC<any> = ({
  name,
  type,
  error,
  label,
  onChange,
  onKeyDown = () => {
    return
  },
  onPaste = () => {
    return
  },
  required = false,
  placeholder = '',
  value,
  inputProps,
  style = {},
  ...props
}) => {
  return (
    <TextField
      label={
        <span>
          {label}
          {required && (
            <>
              <span style={{ color: '#D4351C' }}>*</span>
            </>
          )}
        </span>
      }
      data-testid={name ? name : label}
      placeholder={placeholder ?? label}
      variant='outlined'
      inputProps={{
        id: name,
        maxLength: props.maxLength,
        ...inputProps,
      }}
      sx={{
        padding: props?.multiline && 0,
        ...style,
      }}
      fullWidth
      type={type}
      error={!!error?.[name]}
      helperText={error?.[name]?.message as string}
      value={value}
      onKeyDown={(ev?: any) => {
        if (
          type === CONSTANTS.FIELDTYPES.NUMBER &&
          (ev?.key === 'e' || ev?.key === 'E' || ev?.key === '+' || ev?.key === '-')
        ) {
          ev?.preventDefault()
          return
        }
        onKeyDown(ev)
      }}
      onPaste={(ev?: any) => {
        if (type === CONSTANTS.FIELDTYPES.NUMBER) {
          const pastedData = ev.clipboardData.getData('Text')
          if (/[^0-9.]/.test(pastedData)) {
            ev.preventDefault()
            return
          }
        }
        onPaste(ev)
      }}
      onChange={(event: any) => {
        onChange(event?.target.value)
      }}
      {...props}
    />
  )
}

export default MUIFieldWithoutControl
