const InfoIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='15' height='13' viewBox='0 0 15 13' fill='none'>
      <path
        d='M2.11675 12.7478H13.4118C14.5668 12.7478 15.2868 11.4953 14.7093 10.4978L9.06175 0.740313C8.48425 -0.257187 7.04425 -0.257187 6.46675 0.740313L0.819251 10.4978C0.241751 11.4953 0.961751 12.7478 2.11675 12.7478ZM7.76425 7.49781C7.35175 7.49781 7.01425 7.16031 7.01425 6.74781V5.24781C7.01425 4.83531 7.35175 4.49781 7.76425 4.49781C8.17675 4.49781 8.51425 4.83531 8.51425 5.24781V6.74781C8.51425 7.16031 8.17675 7.49781 7.76425 7.49781ZM8.51425 10.4978H7.01425V8.99781H8.51425V10.4978Z'
        fill='#CC8F00'
      />
    </svg>
  )
}

export default InfoIcon
