import { Button } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import EditIcon from '../../../../Assets/Images/svgs/edit'
import { CONSTANTS } from '../../../../Constants/Constant'
import { PERMISSIONS, PERMISSION_TYPE, checkPermission } from '../../../../Helpers/Permission'
import { checkDraftBeforeEditingMode } from '../../../../Helpers/Workflow'
import { useAppDispatch } from '../../../../Store/Hooks'
import { showAlert } from '../../../../Store/Slice/alert.slice'
import { setLoader } from '../../../../Store/Slice/loader.slice'
import { EditSolidIcon } from '../../../Icons/PlayListPlay'
import { useDialog } from '../../../Shared'
import styles from './VideoComponent.module.scss'

/**
 * EditAssetBtn Component
 *
 * Displays an image for the content media in the asset.
 *
 * @component
 * @param {object} contentDetails - Details of the content.
 *
 * @returns {React.JSX.Element} JSX Element representing the EditAssetBtn component.
 */
const EditAssetBtn = ({
  contentDetails,
}: {
  contentDetails: Record<string, any>
}): React.JSX.Element => {
  const showDialog = useDialog()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { pageType, contentId, metaCategory } = useParams()
  const permisionObj: any = PERMISSIONS
  const permisionKey = permisionObj[`META_CAT_${metaCategory?.toUpperCase()}`]
  const isPermission = checkPermission(permisionKey, [PERMISSION_TYPE.CAN_WRITE])
  const checkCanEdit = async () => {
    if (contentId && isPermission) {
      checkDraftBeforeEditingMode(
        contentDetails.data,
        metaCategory,
        dispatch,
        setLoader,
        navigate,
        showDialog,
        showAlert,
      )
    }
  }
  return pageType === CONSTANTS.PAGE_TYPE.VIEW ? (
    <>
      <Button
        onClick={checkCanEdit}
        data-testid='edit__chip-btn'
        variant='outlined'
        startIcon={<EditIcon />}
        disabled={!isPermission}
        sx={{ textTransform: 'capitalize', marginLeft: '20px', height: '26px' }}
      >
        Edit
      </Button>
    </>
  ) : (
    <></>
  )
}

export default EditAssetBtn

export const EditAssetVideoBtn = ({
  onClickBtn,
}: {
  onClickBtn: (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}) => {
  const { metaCategory } = useParams()
  const permisionObj: any = PERMISSIONS
  const permisionKey = permisionObj[`META_CAT_${metaCategory?.toUpperCase()}`]
  const isPermission = checkPermission(permisionKey, [PERMISSION_TYPE.CAN_WRITE])

  return (
    <Button
      className={styles['edit-video-button']}
      onClick={onClickBtn}
      aria-label='edit video section'
      disabled={!isPermission}
      data-testid='edit-video-btn'
      sx={{
        position: 'absolute',
        right: '60px',
        top: '10px',
        '&:disabled': {
          background: 'transparent',
          border: 'none',
        },
      }}
    >
      <EditSolidIcon />
    </Button>
  )
}
