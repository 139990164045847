import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import React from 'react'
import { UserMuiTableModal } from '../../../Models/ComponentModel'
import { MUIGrid } from '../../Shared'
import AssetViewMoreAndLessValue from '../ViewMoreLess/AssetViewMoreAndLessValue'
import style from './ComplexTypeTable.module.scss'

const ComplexTypeTable: React.FC<UserMuiTableModal> = ({ columns, tableRows = [], tableTitle }) => {
  return (
    <MUIGrid
      className='interFont'
      style={{
        width: '100%',
        backgroundColor: '#f5f4f4',
      }}
    >
      <Grid className={`interFont ${style['view_table']}`}>
        <MUIGrid container className='d-flex justify-content-between align-items-center'>
          <MUIGrid item md={6} lg={6}>
            {tableTitle && <p className='listing__title'>{tableTitle}</p>}
          </MUIGrid>
          <MUIGrid item md={6} lg={6}>
            <MUIGrid container className='d-flex justify-content-end align-items-center'>
              <MUIGrid display='flex' direction='row' gap={3}></MUIGrid>
            </MUIGrid>
          </MUIGrid>
        </MUIGrid>
        <TableContainer className='border-6 ' style={{ backgroundColor: '#ffff' }}>
          <Table>
            <TableHead>
              <TableRow>
                {columns?.map((column: any) => (
                  <TableCell key={column.id} align='center'>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        alignContent: 'space-between',
                        fontSize: '12px',
                        fontWeight: 'bold',
                      }}
                    >
                      <Box sx={{ display: 'inline-flex' }}>
                        <div style={{ marginRight: '10px' }}>
                          <span>
                            {column.label}
                            {column?.isRequired && <span className='col37 pl-05'>*</span>}
                          </span>
                        </div>
                      </Box>
                    </div>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {tableRows?.length > 0
                ? tableRows?.map((row: any, ind: number) => (
                    <TableRow key={ind}>
                      {columns?.map((column: any, index) => {
                        const element = row[column.id]
                        return (
                          <TableCell key={index} className={style['max_w_10']}>
                            <AssetViewMoreAndLessValue
                              value={element?.attribute?.value ?? ''}
                              fieldType={element?.fieldType}
                              tagInput={element?.attribute?.additionalProperties?.tagInput ?? false}
                              dataType={element?.attribute?.dataType}
                              element={element}
                            />
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  ))
                : null}
            </TableBody>
          </Table>
          {!tableRows.length && (
            <Typography className='d-flex justify-content-center' style={{ padding: '50px' }}>
              No Data Found
            </Typography>
          )}
        </TableContainer>
      </Grid>
    </MUIGrid>
  )
}

export default ComplexTypeTable
