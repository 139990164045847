import { Grid } from '@mui/material'
import { useEffect, useState } from 'react'
import CONSTANTS from '../../Constants'
import { ASSET_CURD_API } from '../../Constants/ApiDefinations'
import {
  convertFilesArrayToBase64,
  handleConfirmationDialog,
  handleEditImage,
  separateMappedUnmappedItems,
} from '../../Helpers/BulkImageUpload'
import { validImageFormat } from '../../Helpers/Util'
import { RoleConfirmationModal } from '../../Models'
import mainApiService from '../../Services'
import { useAppDispatch, useAppSelector } from '../../Store/Hooks'
import { showAlert } from '../../Store/Slice/alert.slice'
import { selectAsset } from '../../Store/Slice/asset-definition.slice'
import { setLoader } from '../../Store/Slice/loader.slice'
import RoleConfirmation from '../Dialogs/RoleConfirmation'
import { BulbIcon } from '../Icons'
import BulkImageUploadIconButton from '../Icons/BulkImageUploadButtonIcon'
import PreviewImagePopup from '../Molecule/PreviewImagePopup'
import { ChipButton, MUIBox, MUISelectWithoutControl, MUITabs } from '../Shared'
import ImageDetailsCard from './ImageDetailsCard'
import UpdateImageDialog from './updateImageDialog'

interface PropTypes {
  getMappedImages: (mappedImages: any) => void
}
const BulkImageUploadCard: React.FC<PropTypes> = ({ getMappedImages }: PropTypes) => {
  const [imageType, setImageType] = useState<string>('primary_image_atttribute')
  const [currentTab, setCurrentTab] = useState<number>(0)
  const [mappedImageData, setMappedImageData] = useState<Record<string, string>[]>([])
  const [unMappedImageData, setUnMappedImageData] = useState<Record<string, string>[]>([])
  const [previewImageData, setPreviewImageData] = useState<any>({})
  const [isPreviewImageOpen, setIsPreviewImageOpen] = useState<boolean>(false)
  const asset = useAppSelector(selectAsset)
  const [showCount, setshowCount] = useState(false)
  const [dialogProps, setDialogProps] = useState<RoleConfirmationModal>({
    heading: '',
    description: '',
    color: 'primary',
    open: false,
  })
  const [imagePrefixData, setImagePrefixData] = useState<any[]>([])
  const [updateImageDialogProps, setUpdateImageDialogProps] = useState<{
    open: boolean
    idx: number
    handleSuccess?: (i: number, item: string) => void
    handleClose?: () => void
  }>({ open: false, idx: 0 })

  const handleClose = () => {
    setDialogProps({ open: false, heading: '', description: '', color: 'primary' })
    setUpdateImageDialogProps({ open: false, idx: 0 })
  }

  const dispatch = useAppDispatch()
  const getImagePrefix = async () => {
    dispatch(setLoader(true))
    const requestedData = ASSET_CURD_API.IMAGE_CONTENT_TYPE.GET_IMAGE_PREFIX_DETAILS()
    const result: any = await mainApiService(requestedData)
    const tempPrefix: any = []
    result?.data?.forEach((ele: any) => {
      tempPrefix.push({ name: ele?.label, fieldName: ele?.name, initials: ele?.initials })
    })
    setImagePrefixData(tempPrefix)
    dispatch(setLoader(false))
  }
  useEffect(() => {
    getMappedImages(mappedImageData)
  }, [mappedImageData])
  useEffect(() => {
    getImagePrefix()
  }, [])
  useEffect(() => {
    if (unMappedImageData.length === 0) {
      setCurrentTab(0)
    }
  }, [unMappedImageData])
  const handleCurrentIndex = (idx: number, type: string) => {
    const attributesImage =
      asset?.attributes?.find(
        (item: { metaCategory: string }) =>
          item.metaCategory === CONSTANTS.CATEGORIES.CONTENT_MEDIA_IMAGE,
      )?.metaAttributes ?? []
    if (type === 'remove-image')
      handleConfirmationDialog(
        'remove-image',
        idx,
        currentTab,
        setMappedImageData,
        setUnMappedImageData,
        mappedImageData,
        unMappedImageData,
        handleClose,
        setDialogProps,
      )
    else
      handleEditImage(
        idx,
        currentTab,
        mappedImageData,
        setMappedImageData,
        unMappedImageData,
        setUnMappedImageData,
        dispatch,
        setUpdateImageDialogProps,
        handleClose,
        attributesImage,
      )
  }

  const handleChangeImage = (e: any) => {
    if (!validImageFormat(e.target.files[0])) {
      dispatch(showAlert([true, 'Incorrect file format', 'error']))
      return
    }
    convertFilesArrayToBase64(e.target.files)
      .then((base64DataArray: any) => {
        const imageData: {
          mappedImages: Record<string, string>[]
          unMappedImages: Record<string, string>[]
        } = separateMappedUnmappedItems(base64DataArray, asset, imagePrefixData)
        if (imageData.unMappedImages?.length > 0) {
          setCurrentTab(1)
        } else {
          setCurrentTab(0)
        }
        setMappedImageData([...mappedImageData, ...imageData.mappedImages])
        setUnMappedImageData([...unMappedImageData, ...imageData.unMappedImages])
      })
      .catch((error: any) => console.error('Error converting files to base64:', error))
    setshowCount(true)
  }

  return (
    <div className='mb-2'>
      <MUIBox>
        <div className='text-left'>
          <div className='heading-6 d-flex text-left rolebox__header-padding box__padding_bulk'>
            <div className='d-flex align-items-center'>Content Media: Image</div>
          </div>
          <hr />
          <div className='box__padding_bulk'>
            <div className='col36 paragraph-bold-5 '>Upload Image</div>
            <div className='d-flex w-100 mt-1'>
              <div className='d-flex w-100'>
                <div style={{ minWidth: '200px', width: '40%' }}>
                  <MUISelectWithoutControl
                    name='primary'
                    lable={'Primary'}
                    value={imageType}
                    onChange={(value: string) => setImageType(value)}
                    options={CONSTANTS.BULK_IMAGE_TYPE}
                  />
                </div>
                <label htmlFor='bulk_image_upload' className='ml-1 d-flex align-items-center'>
                  <input
                    type='file'
                    name='bulk_image_upload'
                    id='bulk_image_upload'
                    data-testid='file__input'
                    multiple={true}
                    className='d-none'
                    accept='image/png,image/jpeg,image/jpg,image/webp'
                    onChange={handleChangeImage}
                  />
                  <ChipButton
                    Icon={BulkImageUploadIconButton as any}
                    type='primary'
                    label='Upload Images'
                    style={{ height: 'calc(100% - 8px)' }}
                  />
                </label>
              </div>
            </div>
          </div>

          <div className='tip__box'>
            <div className='tip__box_icon'>
              <div className='text-center paragraph-regular-3'>
                <BulbIcon />
              </div>
              <div className='text-center'>Tip</div>
            </div>
            <div className='tip__box_content'>
              Mapped Items: Contains images auto-mapped to image attributes based on identified
              prefixes.
              <br />
              Unmapped Items: Images with unrecognised prefixes, available for manual mapping with
              image attributes.
            </div>
          </div>

          <div className='mt-1 box__padding_bulk'>
            <MUITabs
              tabs={[
                {
                  value: 0,
                  label: `Mapped Items ${showCount ? `(${mappedImageData.length})` : ''}`,
                },
                {
                  value: 1,
                  label: `Unmapped Items ${showCount ? `(${unMappedImageData.length})` : ''}`,
                },
              ]}
              currentTab={currentTab}
              handleChange={(e: any, value: string | number) => {
                setCurrentTab(value as number)
              }}
            />
          </div>

          <div className='box__padding_bulk'>
            <Grid container spacing={2}>
              {(() => {
                const arr = currentTab === 0 ? mappedImageData : unMappedImageData
                return arr?.map((image: any, idx: number) => {
                  return (
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={3} key={image.fileName}>
                      <ImageDetailsCard
                        editAction={() => handleCurrentIndex(idx, 'edit-image')}
                        removeAction={() => handleCurrentIndex(idx, 'remove-image')}
                        imageData={image}
                        handleCallback={(data: any) => {
                          setPreviewImageData(data)
                          setIsPreviewImageOpen(true)
                        }}
                      />
                    </Grid>
                  )
                })
              })()}

              {(() => {
                const arr = currentTab === 0 ? mappedImageData : unMappedImageData
                return arr?.length === 0 ? (
                  <div className='heading-3 w-100 d-flex mt-3 mb-3 align-items-center justify-content-center text-center'>
                    You have not uploaded any image files
                  </div>
                ) : null
              })()}
            </Grid>
          </div>
        </div>
      </MUIBox>
      {isPreviewImageOpen && (
        <PreviewImagePopup
          isPreviewImageOpen={isPreviewImageOpen}
          previewImageUrl={previewImageData.base64}
          handlePreviewClose={() => setIsPreviewImageOpen(false)}
        />
      )}
      <RoleConfirmation {...dialogProps} />
      <UpdateImageDialog
        {...updateImageDialogProps}
        imageCategoryAttribute={asset?.attributes}
        imgData={{ mappedImageData, unMappedImageData, currentTab }}
      />
    </div>
  )
}

export default BulkImageUploadCard
