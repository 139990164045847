import { DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import * as React from 'react'
import WarningIcon from '../../Assets/Images/Featured_icon.png'
import { TypeObject } from '../../Models'
import { MUIButton, MUIDialog } from '../Shared'

interface PropTypes {
  handleClose?: () => void
  open: boolean
  duplicateImages: TypeObject[]
}

const DuplicateImageDialog: React.FC<PropTypes> = ({
  open,
  handleClose = () => {
    return
  },
  duplicateImages,
}: PropTypes) => {
  return (
    <>
      <div>
        <MUIDialog open={open} handleClose={handleClose}>
          <div style={{ maxWidth: '400px' }}>
            <DialogTitle>
              <img src={WarningIcon} alt='' />
            </DialogTitle>
            <DialogContent className='p-0'>
              <DialogContentText component={'div'} id='alert-dialog-slide-description'>
                <div className='col2 paragraph-bold-3 dialog__padding-horizontal mb-1'>
                  Duplication of Files
                </div>
                <div className='col34 paragraph-regular-5'>
                  <div className='dialog__padding-horizontal mb-1'>
                    Duplication of files. Please ensure one file per image attribute.
                    <br />
                  </div>
                  <div className='dialog__padding-horizontal dialog__overflow'>
                    {duplicateImages.map((content, idx) => (
                      <div
                        key={content.imageName}
                        data-testid='content_existing_exist_list'
                        className='cursor-pointer col1 mb-1'
                      >
                        <div className='heading-5 col34 mb-1'>
                          {idx + 1}. {content.fieldName}
                        </div>
                        <ul className='pl-2'>
                          {content?.imageKey?.map((imagek: string) => (
                            <li key={imagek} className='mb-0 word-break-all'>
                              {imagek}
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <div className='d-flex flex-wrap w-100 dialog-btns p-8'>
                <MUIButton
                  textTransform={'capitalize'}
                  variant='contained'
                  size='large'
                  color='primary'
                  type='button'
                  handleClickCB={() => handleClose()}
                  label={'Okay'}
                  width={'100%'}
                />
              </div>
            </DialogActions>
          </div>
        </MUIDialog>
      </div>
    </>
  )
}

export default DuplicateImageDialog
