import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import ListingComponent from '../../Components/Molecule/ListingComponent'
import { PAGES_ROUTES } from '../../Constants'
import { MASTER_MANAGEMENT_CONSTANTS, PAGINATION_OPTION } from '../../Constants/Constant/Masters'
import { PERMISSIONS, PERMISSION_TYPE, checkPermission } from '../../Helpers/Permission'
import { emptyFunction } from '../../Helpers/Util'
import updateTagMetaData from '../../Helpers/updateTagMetaData'
import { useAppDispatch, useAppSelector } from '../../Store/Hooks'
import { clearSystemConfigurationStore } from '../../Store/Slice/system-configuration.slice'
import {
  getSystemConfigurationMaster,
  selectSystemConfigurationMasterList,
} from '../../Store/Slice/systemConfigurations.slice'
import AccessDenied from '../AccessDenied'
import styles from './MastersList.module.scss'

const MastersList: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const systemconfigurationMasterData = useAppSelector(selectSystemConfigurationMasterList)
  const accessWrite = checkPermission(PERMISSIONS.META_MASTER_MASTERS, [PERMISSION_TYPE.CAN_WRITE])
  const accessRead = checkPermission(PERMISSIONS.META_MASTER_MASTERS, [PERMISSION_TYPE.CAN_READ])

  useEffect(() => {
    dispatch(clearSystemConfigurationStore({}) as any)
    updateTagMetaData({ title: 'Manage Masters' })
    return () => {
      updateTagMetaData({})
    }
  }, [])

  const handleMasterClick = (row: any) => {
    const { url = '' } = row
    if (url) {
      navigate(url)
    }
  }

  const handleMetaMasterClick = () => {
    navigate(
      `${PAGES_ROUTES.CreateSystemConfigurationPageType}/${PAGES_ROUTES.CreateSystemConfirgurationPage}`,
    )
  }
  const getSystemconfigurationMaster = async () => {
    await dispatch(getSystemConfigurationMaster('some data') as any)
  }

  useEffect(() => {
    if (accessRead) {
      getSystemconfigurationMaster()
    }
  }, [])

  if (!accessRead) {
    return <AccessDenied />
  }

  return (
    <div className={styles.mastersListWrapper}>
      <ListingComponent
        className='pb-0'
        columns={MASTER_MANAGEMENT_CONSTANTS.MASTERS_COLUMNS}
        tableRows={MASTER_MANAGEMENT_CONSTANTS.OTHER_MASTERS_LIST}
        dataCount={0}
        headerBtnLable={''}
        tableTitle={'Masters'}
        searchFunction={emptyFunction}
        showCreateButton={false}
        createButtonClick={emptyFunction}
        paginationOption={PAGINATION_OPTION}
        showSearchField={false}
        navigateToEdit={emptyFunction}
        isRowClickable={true}
        isCellClickable={false}
        handleRowClick={handleMasterClick}
      />
      <ListingComponent
        columns={MASTER_MANAGEMENT_CONSTANTS.MASTERS_COLUMNS}
        tableRows={systemconfigurationMasterData}
        dataCount={0}
        headerBtnLable={'Create Master'}
        tableTitle={'System Configurations'}
        searchFunction={emptyFunction}
        showCreateButton={accessWrite}
        createButtonClick={handleMetaMasterClick}
        paginationOption={PAGINATION_OPTION}
        showSearchField={false}
        navigateToEdit={emptyFunction}
        isRowClickable={true}
        isCellClickable={false}
        handleRowClick={handleMasterClick}
      />
    </div>
  )
}

export default React.memo(MastersList)
