import { setEditObj } from '../../../../Store/Slice/system-configuration.slice'
import ChipButton from '../../ChipButton'
import { EditIcon } from '../../MUI-Drawer/MUI-Drawer.svgIcons'

const commonListingEditBtn = (dispatch: any, navigate: any, item: any, url: string) => {
  return (
    <div
      onClick={() => {
        dispatch(setEditObj({ key: item.key, value: item.value }) as any)
        navigate(url)
      }}
      className='cursor-pointer'
      data-testid='edit__chip-btn'
      style={{ width: 'fit-content' }}
    >
      <ChipButton type='primary' Icon={EditIcon as any} label='Edit' />
    </div>
  )
}

export default commonListingEditBtn
