const SuccessIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='none'>
      <path
        d='M0.0976562 7.0026C0.0976562 5.794 0.393949 4.67902 0.986543 3.65758C1.58694 2.63613 2.39785 1.82912 3.4193 1.23652C4.44854 0.636131 5.56352 0.335938 6.76432 0.335938C7.97292 0.335938 9.08792 0.636131 10.1093 1.23652C11.1308 1.82912 11.9378 2.64003 12.5304 3.66927C13.1308 4.69071 13.431 5.8018 13.431 7.0026C13.431 8.2034 13.1308 9.3184 12.5304 10.3476C11.9378 11.3691 11.1269 12.18 10.0977 12.7804C9.07619 13.373 7.96512 13.6693 6.76432 13.6693C5.56352 13.6693 4.44854 13.373 3.4193 12.7804C2.39785 12.18 1.58694 11.3691 0.986543 10.3476C0.393949 9.3184 0.0976562 8.2034 0.0976562 7.0026ZM4.8228 9.0026L5.77019 9.95L6.71752 9.0026L10.7175 5.0026L9.77019 4.05524L5.77019 8.0552L3.77017 6.0552L2.8228 7.0026L4.8228 9.0026Z'
        fill='#005A30'
      />
    </svg>
  )
}

export default SuccessIcon
