import { Grid } from '@mui/material'
import { unwrapResult } from '@reduxjs/toolkit'
import React, { useEffect, useState } from 'react'
import { Control, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import CONSTANTS, { PAGES_ROUTES, VALIDATIONS } from '../../../Constants'
import updateTagMetaData from '../../../Helpers/updateTagMetaData'
import {
  combineErrorMessage,
  convertBooleanIntoYesAndNo,
  createAttributePayload,
  isValidArray,
  isValidJSON,
  stringifyIfNotNull,
} from '../../../Helpers/Util'
import { useAppDispatch, useAppSelector } from '../../../Store/Hooks'
import { showAlert } from '../../../Store/Slice/alert.slice'
import { selectDataSource } from '../../../Store/Slice/asset-definition.slice'
import {
  createAttributeService,
  editAttributeService,
  getAttributeService,
  selectaffiliatePartnersList,
  selectEditAttributeData,
  selectFieldTypesList,
  selectNonComplexTypeAttributesList,
  setAttributeData,
} from '../../../Store/Slice/attribute.slice'
import { BulbIcon } from '../../Icons'
import { MUIButton } from '../../Shared'
import MUIAutocompleteMultiSelect from '../../Shared/MUI-AutocompleteMultiSelect'
import MUIBox from '../../Shared/MUI-Box'
import MUIField from '../../Shared/MUI-Field'
import MUISelect from '../../Shared/MUI-Select'
import style from './AttributeEditor.module.scss'

interface AttributeDetailFormPropsInterface {
  type: string
  attributeId?: string
}

const AttributeDetailForm = ({
  type = '',
  attributeId = '',
}: AttributeDetailFormPropsInterface) => {
  const { MetaMaster, AttributeRepository, PageTypeView } = PAGES_ROUTES
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const masterDataListStore: any[] = useAppSelector(selectDataSource)
  const masterDataListWithCustomOption = JSON.parse(JSON.stringify(masterDataListStore))
  if (masterDataListWithCustomOption?.length) {
    masterDataListWithCustomOption.sort((a: any, b: any) => a.value.localeCompare(b.value))
    masterDataListWithCustomOption.unshift({
      value: 'Custom Option',
      key: 'customOption',
    })
  }
  const allNonComplexFieldTypes: [] = useAppSelector(selectNonComplexTypeAttributesList)
  const allFieldTypes: [] = useAppSelector(selectFieldTypesList)
  const affiliatePartnerList = useAppSelector(selectaffiliatePartnersList)
  const attributeData = useAppSelector(selectEditAttributeData)
  const [attributeRefData, setAttributeRefData] = useState<any>([])
  const [selectedAffiliatePartnerList, setSelectedAffiliatePartnerList] = useState<any>([])
  const [toggleFields, setToggleFields] = useState<any>({
    metaAttributes: false,
    affiliatePartners: false,
    options: true,
    master: true,
    isMultiple: true,
    defaultValue: true,
    placeholder: true,
  })
  const [showOptionField, setShowOptionField] = useState(false)
  const [masterDataList] = useState(masterDataListWithCustomOption)

  const {
    control,
    register,
    unregister,
    handleSubmit,
    watch,
    getValues,
    setFocus,
    setValue,
    setError,
    clearErrors,
    trigger,
    formState: { errors, isValid },
  } = useForm<any>({
    mode: 'onChange',
    defaultValues: {
      fieldType: type === CONSTANTS.PAGE_TYPE.EDIT ? attributeData?.fieldType : null,
      dataType: type === CONSTANTS.PAGE_TYPE.EDIT ? attributeData?.dataType : null,
      fieldName: type === CONSTANTS.PAGE_TYPE.EDIT ? attributeData?.fieldName : null,
      isRequired:
        type === CONSTANTS.PAGE_TYPE.EDIT
          ? convertBooleanIntoYesAndNo(attributeData?.isRequired)
          : 'Yes',
      isMultiple:
        type === CONSTANTS.PAGE_TYPE.EDIT
          ? convertBooleanIntoYesAndNo(attributeData?.isMultiple)
          : 'No',
      label: type === CONSTANTS.PAGE_TYPE.EDIT ? attributeData?.label : null,
      isB2B:
        type === CONSTANTS.PAGE_TYPE.EDIT ? convertBooleanIntoYesAndNo(attributeData?.isB2B) : 'No',
      defaultValue: type === CONSTANTS.PAGE_TYPE.EDIT ? attributeData?.defaultValue : null,
      affiliatePartner: type === CONSTANTS.PAGE_TYPE.EDIT ? attributeData?.affiliatePartner : [],
      placeholder: type === CONSTANTS.PAGE_TYPE.EDIT ? attributeData?.placeholder : null,
      master: type === CONSTANTS.PAGE_TYPE.EDIT ? attributeData?.master : null,
      options: type === CONSTANTS.PAGE_TYPE.EDIT ? attributeData?.options : null,
      addMore:
        type === CONSTANTS.PAGE_TYPE.EDIT
          ? convertBooleanIntoYesAndNo(attributeData?.addMore)
          : 'No',
      status: type === CONSTANTS.PAGE_TYPE.EDIT ? attributeData?.status : 'ACTIVE',
      isMultilingual:
        type === CONSTANTS.PAGE_TYPE.EDIT
          ? convertBooleanIntoYesAndNo(attributeData?.isMultilingual)
          : 'No',
      esField:
        type === CONSTANTS.PAGE_TYPE.EDIT && attributeData?.esField ? attributeData.esField : null,
      description: type === CONSTANTS.PAGE_TYPE.EDIT ? attributeData?.description : null,
      additionalProperties:
        type === CONSTANTS.PAGE_TYPE.EDIT
          ? stringifyIfNotNull(attributeData?.additionalProperties)
          : '',
      attributeRef: [],
      metaCategory: type === CONSTANTS.PAGE_TYPE.EDIT ? attributeData?.metaCategory : null,
    },
    shouldUnregister: true,
  })

  const getAttributeData = async () => {
    await dispatch(getAttributeService(attributeId as string) as any)
  }
  useEffect(() => {
    if (attributeId) {
      getAttributeData()
    }
    if (type === CONSTANTS.PAGE_TYPE.CREATE) {
      setToggleFields({
        ...toggleFields,
        metaAttributes: false,
      })
      setAttributeRefData([])
      dispatch(setAttributeData({}))
    }
    if (type === CONSTANTS.PAGE_TYPE.EDIT) {
      handleEditMode()
    }
  }, [])

  useEffect(() => {
    if (type !== CONSTANTS.PAGE_TYPE.CREATE) {
      if (attributeData?.master) setValue('master', attributeData?.master)
      if (attributeData?.master === '' && attributeData?.options?.length) {
        setValue('master', 'customOption')
      }
    }
  }, [masterDataList])

  useEffect(() => {
    updateTagMetaData({
      title: attributeId ? `Attribute: ${attributeData?.label}` : 'Create Attribute',
    })
    return () => {
      updateTagMetaData({})
    }
  }, [attributeId])

  const handleEditMode = () => {
    let copyToggleFields = JSON.parse(JSON.stringify(toggleFields))
    const fieldTypeValue: string = getValues('fieldType') || ''
    if (fieldTypeValue === 'COMPLEXTYPE') {
      copyToggleFields = {
        ...copyToggleFields,
        metaAttributes: true,
        options: false,
        master: false,
        isMultiple: false,
        defaultValue: false,
        placeholder: false,
      }
      register('attributeRef', { required: 'This field is required' })
      unregister('options')
      unregister('master')
      unregister('isMultiple')
      unregister('defaultValue')
      unregister('placeholder')
      setFocus('dataType')
      if (isValidArray(attributeData?.attributeRef)) {
        setAttributeRefData(attributeData?.attributeRef)
        setValue('attributeRef', attributeData?.attributeRef)
      }
    }
    const isB2BValue = getValues('isB2B') || ''
    if (isB2BValue === 'Yes') {
      copyToggleFields = {
        ...copyToggleFields,
        affiliatePartners: true,
      }
      const selectedAffiliatePartners: any = []
      if (isValidArray(attributeData?.affiliatePartner) && isValidArray(affiliatePartnerList)) {
        attributeData?.affiliatePartner?.forEach((element: string) => {
          const matchedValue = affiliatePartnerList?.find((x: any) => x?.key === element)
          selectedAffiliatePartners.push(matchedValue)
        })
        if (isValidArray(selectedAffiliatePartners)) {
          setTimeout(() => {
            setSelectedAffiliatePartnerList(selectedAffiliatePartners)
            setValue('affiliatePartner', selectedAffiliatePartners)
          }, 500)
        }
      }
    }
    const dataTypeValue: string = getValues('dataType') || ''
    if (
      fieldTypeValue !== 'COMPLEXTYPE' &&
      fieldTypeValue === 'SELECT' &&
      dataTypeValue === 'BOOLEAN'
    ) {
      copyToggleFields = {
        ...copyToggleFields,
        options: false,
      }
      unregister('options')
    }
    setToggleFields({
      ...copyToggleFields,
    })
    if (attributeData?.master) setValue('master', attributeData?.master)
    if (attributeData?.master === '' && attributeData?.options?.length) {
      setValue('master', 'customOption')
    }
  }

  watch((data, { name }) => {
    let copyToggleFields = JSON.parse(JSON.stringify(toggleFields))
    if (data?.fieldType === 'COMPLEXTYPE' && name === 'fieldType') {
      copyToggleFields = {
        ...copyToggleFields,
        metaAttributes: true,
        options: false,
        master: false,
        isMultiple: false,
        defaultValue: false,
        placeholder: false,
      }
      register('attributeRef', { required: 'This field is required' })
      unregister('options')
      unregister('master')
      unregister('isMultiple')
      unregister('defaultValue')
      unregister('placeholder')
      setFocus('dataType')
      if (isValidArray(attributeData?.attributeRef)) {
        setAttributeRefData(attributeData?.attributeRef)
        setValue('attributeRef', attributeData?.attributeRef)
      }
    }
    if (data?.fieldType !== 'COMPLEXTYPE' && name === 'fieldType') {
      copyToggleFields = {
        ...copyToggleFields,
        metaAttributes: false,
        options: true,
        master: true,
        isMultiple: true,
        defaultValue: true,
        placeholder: true,
      }
      unregister('attributeRef')
      register('options')
      register('master')
      register('isMultiple', { required: 'This field is required' })
      register('defaultValue')
      register('placeholder', { required: 'This field is required' })
    }
    if (data?.isB2B === 'Yes' && name === 'isB2B') {
      copyToggleFields = {
        ...copyToggleFields,
        affiliatePartners: true,
      }
      register('affiliatePartner', { required: 'This field is required' })
    }
    if (data?.isB2B !== 'Yes' && name === 'isB2B') {
      copyToggleFields = {
        ...copyToggleFields,
        affiliatePartners: false,
      }
      unregister('affiliatePartner')
    }
    if (name === 'fieldType' || name === 'dataType') {
      const fieldTypeValue: string = data?.fieldType
      const dataTypeValue: string = data?.dataType
      if (fieldTypeValue !== 'COMPLEXTYPE' && dataTypeValue) {
        if (fieldTypeValue === 'SELECT' && dataTypeValue === 'BOOLEAN') {
          copyToggleFields = {
            ...copyToggleFields,
            options: false,
          }
          unregister('options')
        }
        if (fieldTypeValue !== 'SELECT' || dataTypeValue !== 'BOOLEAN') {
          copyToggleFields = {
            ...copyToggleFields,
            options: true,
          }
          register('options')
        }
      }
    }
    setToggleFields({
      ...copyToggleFields,
    })
    if (name === 'master') {
      data?.master === 'customOption' ? setShowOptionField(true) : setShowOptionField(false)
    }
  })

  const handleMetaAttributeChange = (event: any, newValue: any[], reason: any) => {
    if (reason === CONSTANTS.AUTOCOMPLETE_EVENT_TYPES.INPUT) {
      return
    }
    if (reason === CONSTANTS.AUTOCOMPLETE_EVENT_TYPES.CLEAR) {
      setValue('attributeRef', [])
      setAttributeRefData([])
    }
    if (
      reason === CONSTANTS.AUTOCOMPLETE_EVENT_TYPES.SELECTOPTION ||
      reason === CONSTANTS.AUTOCOMPLETE_EVENT_TYPES.REMOVEOPTION
    ) {
      setValue('attributeRef', newValue)
      setAttributeRefData(newValue)
      newValue?.length > 0
        ? clearErrors('attributeRef')
        : setError('attributeRef', {
            types: {
              required: 'This field is required',
            },
          })
    }
  }

  const handleAffiliatePartnerChange = async (event: any, newValue: any[], reason: any) => {
    if (reason === CONSTANTS.AUTOCOMPLETE_EVENT_TYPES.INPUT) {
      return
    }
    if (reason === CONSTANTS.AUTOCOMPLETE_EVENT_TYPES.CLEAR) {
      setValue('affiliatePartner', [])
      setSelectedAffiliatePartnerList([])
    }
    if (
      reason === CONSTANTS.AUTOCOMPLETE_EVENT_TYPES.SELECTOPTION ||
      reason === CONSTANTS.AUTOCOMPLETE_EVENT_TYPES.REMOVEOPTION
    ) {
      setValue('affiliatePartner', newValue)
      setSelectedAffiliatePartnerList(newValue)
      const validationResult = await trigger('affiliatePartner')
      if (validationResult) {
        clearErrors('affiliatePartner')
      } else {
        setError('affiliatePartner', {
          types: {
            required: 'This field is required',
          },
        })
      }
    }
  }

  const createAttribute = async (data: any) => {
    const body = createAttributePayload(data, CONSTANTS.PAGE_TYPE.CREATE)
    const result = await dispatch(createAttributeService(body) as any)
    return result
  }

  const editAttribute = async (data: any) => {
    let body = createAttributePayload(data, CONSTANTS.PAGE_TYPE.EDIT)
    body = {
      ...body,
      attributeId,
    }
    const result = await dispatch(editAttributeService(body) as any)
    return result
  }

  const onSubmit = async (data: any) => {
    const { additionalProperties = '' } = data
    if (additionalProperties !== '' && !isValidJSON(additionalProperties)) {
      setError('additionalProperties', {
        type: 'manual',
        message: 'Invalid JSON value',
      })
      return
    }

    let result: any
    if (attributeId && type === CONSTANTS.PAGE_TYPE.EDIT) {
      result = await editAttribute(data)
    }
    if (type === CONSTANTS.PAGE_TYPE.CREATE) {
      result = await createAttribute(data)
    }
    const unwrappableRes = unwrapResult(result)
    if (unwrappableRes?.error) {
      dispatch(showAlert([true, combineErrorMessage(unwrappableRes), 'error']))
      return
    }
    dispatch(showAlert([true, unwrappableRes.message, 'success']))
    if (attributeId && type === CONSTANTS.PAGE_TYPE.EDIT) {
      navigate(`/${MetaMaster}/${AttributeRepository}/${PageTypeView}/${attributeId}`)
    }
    if (type === CONSTANTS.PAGE_TYPE.CREATE) {
      navigate(`/${MetaMaster}/${AttributeRepository}`)
    }
  }
  return (
    <MUIBox>
      <div>
        <div className='heading-6 d-flex text-left rolebox__header-padding'>Attribute Contents</div>
        <div className={'hr'}></div>
        {attributeData?.isAssigned ? (
          <div className={style.tip__box}>
            <div className={style.tip__box_icon}>
              <div className='text-center paragraph-regular-3'>
                <BulbIcon />
              </div>
              <div className='text-center'>Tip</div>
            </div>
            <div className={style.tip__box_content}>
              Certain fields will be non-editable due to the assignment of this attribute to an
              asset type.
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className='rolebox__content-padding'>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container columnSpacing={10} rowSpacing={4}>
              <Grid item xs={6} md={6} lg={6} xl={6}>
                <MUIField
                  name='fieldName'
                  label='Field Name'
                  required
                  type='text'
                  disabled={attributeData?.isAssigned}
                  rules={{
                    required: VALIDATIONS.REQUIRED,
                  }}
                  control={control as Control | undefined}
                  error={errors}
                  maxLength={100}
                />
              </Grid>
              <Grid item xs={6} md={6} lg={6} xl={6}>
                <MUIField
                  name='label'
                  label='Label'
                  required
                  type='text'
                  rules={{
                    required: VALIDATIONS.REQUIRED,
                  }}
                  control={control as Control | undefined}
                  error={errors}
                  maxLength={100}
                />
              </Grid>
              {!!allFieldTypes?.length && (
                <Grid item xs={6} md={6} lg={6} xl={6}>
                  <MUISelect
                    name='fieldType'
                    lable={'Field Type'}
                    required
                    disabled={attributeData?.isAssigned}
                    rules={{ required: VALIDATIONS.REQUIRED }}
                    control={control as Control | undefined}
                    error={errors}
                    labelKey={'key'}
                    valueKey={'value'}
                    options={allFieldTypes || []}
                  />
                </Grid>
              )}
              <Grid item xs={6} md={6} lg={6} xl={6}>
                <MUISelect
                  name='dataType'
                  lable={'Data Type'}
                  required
                  disabled={attributeData?.isAssigned}
                  rules={{ required: VALIDATIONS.REQUIRED }}
                  control={control as Control | undefined}
                  error={errors}
                  labelKey={'key'}
                  valueKey={'value'}
                  options={CONSTANTS.DATA_TYPE_LIST}
                />
              </Grid>
              <Grid item xs={6} md={6} lg={6} xl={6}>
                <MUIField
                  name='esField'
                  label='ES Field'
                  disabled={attributeData?.isAssigned}
                  type='text'
                  control={control as Control | undefined}
                  error={errors}
                  maxLength={100}
                />
              </Grid>
              <Grid item xs={6} md={6} lg={6} xl={6}>
                <MUISelect
                  name='isRequired'
                  lable={'Required'}
                  required
                  rules={{ required: VALIDATIONS.REQUIRED }}
                  control={control as Control | undefined}
                  error={errors}
                  options={CONSTANTS.YES_NO_LIST}
                />
              </Grid>
              <Grid item xs={6} md={6} lg={6} xl={6}>
                <MUISelect
                  name='status'
                  lable={'Status'}
                  required
                  disabled={attributeData?.isAssigned}
                  rules={{ required: VALIDATIONS.REQUIRED }}
                  control={control as Control | undefined}
                  error={errors}
                  options={CONSTANTS.STATUS_LIST}
                />
              </Grid>
              <Grid item xs={6} md={6} lg={6} xl={6}>
                <MUISelect
                  name='isMultilingual'
                  lable={'Is Multilingual'}
                  control={control as Control | undefined}
                  error={errors}
                  options={CONSTANTS.YES_NO_LIST}
                />
              </Grid>
              {toggleFields.placeholder && (
                <Grid item xs={6} md={6} lg={6} xl={6}>
                  <MUIField
                    name='placeholder'
                    label='Placeholder'
                    required
                    type='text'
                    rules={{ required: VALIDATIONS.REQUIRED }}
                    control={control as Control | undefined}
                    error={errors}
                    maxLength={100}
                  />
                </Grid>
              )}
              <Grid item xs={6} md={6} lg={6} xl={6}>
                <MUISelect
                  name='isB2B'
                  lable={'B2B'}
                  required
                  rules={{ required: VALIDATIONS.REQUIRED }}
                  control={control as Control | undefined}
                  error={errors}
                  options={CONSTANTS.YES_NO_LIST}
                />
              </Grid>
              <Grid item xs={6} md={6} lg={6} xl={6}>
                <MUISelect
                  name='addMore'
                  lable={'Add More'}
                  disabled={attributeData?.isAssigned}
                  control={control as Control | undefined}
                  error={errors}
                  options={CONSTANTS.YES_NO_LIST}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12} xl={12}>
                <MUIField
                  name='description'
                  label='Description'
                  required
                  type='text'
                  rules={{ required: VALIDATIONS.REQUIRED }}
                  control={control as Control | undefined}
                  error={errors}
                  maxLength={300}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12} xl={12}>
                <MUIField
                  name='additionalProperties'
                  label='Additional Properties'
                  type='text'
                  control={control as Control | undefined}
                  error={errors}
                  maxLength={300}
                />
              </Grid>
              {toggleFields.defaultValue && (
                <Grid item xs={6} md={6} lg={6} xl={6}>
                  <MUIField
                    name='defaultValue'
                    label='Default Value'
                    type='text'
                    control={control as Control | undefined}
                    error={errors}
                    maxLength={5000}
                  />
                </Grid>
              )}
              {toggleFields.isMultiple && (
                <Grid item xs={6} md={6} lg={6} xl={6}>
                  <MUISelect
                    name='isMultiple'
                    lable={'Multi Select'}
                    required
                    disabled={attributeData?.isAssigned}
                    rules={{ required: VALIDATIONS.REQUIRED }}
                    control={control as Control | undefined}
                    error={errors}
                    options={CONSTANTS.YES_NO_LIST}
                  />
                </Grid>
              )}
              {toggleFields.master && masterDataListWithCustomOption?.length > 0 && (
                <Grid item xs={6} md={6} lg={6} xl={6}>
                  <MUISelect
                    name='master'
                    lable={'Master'}
                    disabled={attributeData?.isAssigned}
                    control={control as Control | undefined}
                    error={errors}
                    options={masterDataListWithCustomOption || []}
                    labelKey={'value'}
                    valueKey={'key'}
                  />
                </Grid>
              )}
              {showOptionField && (
                <Grid item xs={6} md={6} lg={6} xl={6}>
                  <MUIField
                    name='options'
                    label='Options'
                    type='text'
                    control={control as Control | undefined}
                    error={errors}
                    maxLength={2000}
                  />
                </Grid>
              )}

              {toggleFields.metaAttributes && (
                <Grid item xs={6} md={6} lg={6} xl={6}>
                  <MUIAutocompleteMultiSelect
                    usingFor={'metaAttributes'}
                    label={'Meta Attributes'}
                    required={true}
                    LabelIcon={null}
                    labelKey='label'
                    name='attributeRef'
                    disabled={attributeData?.isAssigned}
                    options={allNonComplexFieldTypes || []}
                    control={control as Control | undefined}
                    error={errors}
                    value={attributeRefData}
                    onInputChange={handleMetaAttributeChange}
                    rules={{ required: VALIDATIONS.REQUIRED }}
                    limitTags={2}
                    id={'attributeRef'}
                  />
                </Grid>
              )}

              {toggleFields.affiliatePartners && (
                <Grid item xs={6} md={6} lg={6} xl={6}>
                  <MUIAutocompleteMultiSelect
                    usingFor={'attributePartnersName'}
                    label={'Partner Name'}
                    required={true}
                    LabelIcon={null}
                    labelKey='key'
                    name='affiliatePartner'
                    options={affiliatePartnerList ?? []}
                    control={control as Control | undefined}
                    error={errors}
                    value={selectedAffiliatePartnerList}
                    onInputChange={handleAffiliatePartnerChange}
                    rules={{ required: VALIDATIONS.REQUIRED }}
                    limitTags={2}
                    id={'affiliatePartner'}
                  />
                </Grid>
              )}
            </Grid>
            <div className='submit__handler'>
              <div className='submit__handler-btn-cont'>
                <MUIButton
                  disabled={!isValid && type === CONSTANTS.PAGE_TYPE.CREATE}
                  size='large'
                  type={'submit'}
                  label={'Submit'}
                  variant='contained'
                  width={200}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </MUIBox>
  )
}

export default React.memo(AttributeDetailForm)
