import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import { useLocation, useNavigate } from 'react-router-dom'
import CONSTANTS from '../../../Constants'
import { removeLocalStorage } from '../../../Helpers/Storage'
import { getRouteFromLocalStorage } from '../../../Helpers/Util'
import styles from './BackButton.module.scss'

interface BackButtonProps {
  className?: string
  handleOnClick?: any
  label?: string
  contentId?: any
}

const BackButton = ({ className, handleOnClick, label, contentId }: BackButtonProps) => {
  const location = useLocation()
  const navigate = useNavigate()
  const handleBack = (e: any) => {
    const routesToCheck = CONSTANTS.LIST_HISTORY_ROUTES
    const pathAvl = routesToCheck.some((path: string) => location?.pathname?.includes(path))
    if (pathAvl) {
      const redirectPath = getRouteFromLocalStorage(true, contentId)
      if (redirectPath) {
        navigate(redirectPath)
        return
      } else {
        removeLocalStorage('pathHistory')
        handleOnClick(e)
        return
      }
    } else {
      removeLocalStorage('pathHistory')
      handleOnClick(e)
    }
  }

  return (
    <div className={`${styles.backBtnWrapper} ${className}`} data-testid='backBtnWrapper'>
      <div
        data-testid='t-click-btn'
        className={`${styles.leftAerrow}`}
        onClick={(e: any) => handleBack(e)}
      >
        <KeyboardBackspaceIcon />
      </div>
      <p className={`${className} capitalize`}>{label}</p>
    </div>
  )
}

export default BackButton
