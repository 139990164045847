import { Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PARTNER_APIS } from '../../../Constants/ApiDefinations'
import {
  getValuesFromFormConfig,
  validateField,
  validateForm,
} from '../../../Helpers/FormRenderHelper'
import {
  getAllowedAssetTypePayload,
  getEditPartnerMastersValue,
} from '../../../Helpers/InboundPartnerHelper'
import mainApiService from '../../../Services'
import { useAppDispatch } from '../../../Store/Hooks'
import { showAlert } from '../../../Store/Slice/alert.slice'
import { setLoader } from '../../../Store/Slice/loader.slice'
import FormRender from '../../FormRender'
import { MUIBox, MUIButton } from '../../Shared'
import { PARTNER_CONSTENT } from './PartnersConstent'

interface PartnersFormType {
  partnersData?: any
  id?: string
  listOptions?: any
}
const PartnersForm: React.FC<PartnersFormType> = ({ partnersData, id, listOptions }) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [partnersFormFields, setPartnersFormFields] = useState<any>(PARTNER_CONSTENT.initialField)
  const [initialField, setinitialField] = useState([])
  const [formFieldsOptions, setFormFieldsOptions] = useState({})
  const [mount, setMount] = useState(false)

  useEffect(() => {
    setFormFieldsOptions(listOptions)
    if (id === 'edit' && partnersData?._id && listOptions.masterList) {
      const updatedFields = partnersFormFields.map((field: any) => {
        if (field.fieldType === 'SELECT') {
          return {
            ...field,
            value: getEditPartnerMastersValue(field, partnersData[field.fieldName], listOptions),
          }
        } else if (field.fieldType === 'JSON1') {
          const fieldArray: any = []
          partnersData[field.fieldName]?.forEach((ele: any) => {
            const fieldsObj: any = JSON.parse(JSON.stringify(field.fieldConfig))
            fieldsObj.forEach((fieldObj: any) => {
              fieldObj.value = getEditPartnerMastersValue(
                fieldObj,
                ele[fieldObj.fieldName],
                listOptions,
              )
            })
            fieldArray.push(fieldsObj)
          })
          return {
            ...field,
            fields: fieldArray.length
              ? fieldArray
              : [JSON.parse(JSON.stringify(field.fieldConfig))],
          }
        } else {
          return { ...field, value: partnersData[field.fieldName] }
        }
      })

      setPartnersFormFields(updatedFields)
      setinitialField(updatedFields)
      setMount(true)
    } else if (id === 'create') {
      const updatedCreateFields = partnersFormFields.map((field: any) => {
        if (field.fieldName === 'contractEndDate') {
          return { ...field, validations: { ...field.validations, futureDate: true } }
        } else if (field.fieldType === 'JSON1') {
          return {
            ...field,
            fields: [JSON.parse(JSON.stringify(field.fieldConfig))],
          }
        } else {
          return field
        }
      })
      setPartnersFormFields(updatedCreateFields)
      setMount(true)
    }
  }, [partnersData, listOptions])

  const handleChangeInput = (params: any) => {
    if (!mount) {
      return
    }

    const { ev: inputvalue, index, item: field, fieldsIndex, fieldIndex } = params
    let inputValueCopy = inputvalue
    if (field.fieldName === 'serviceAlias') {
      inputValueCopy = String(inputValueCopy).toUpperCase()
      const inputElement = document.getElementById(field.fieldName) as HTMLInputElement
      if (inputElement) {
        const { selectionStart, selectionEnd } = inputElement
        const newValue = inputValueCopy.toUpperCase()
        inputElement.value = newValue
        inputElement.setSelectionRange(selectionStart, selectionEnd)
      }
    }
    const partnersFormFieldsCopy = JSON.parse(JSON.stringify(partnersFormFields))
    if (partnersFormFieldsCopy[index]?.fieldType === 'JSON1') {
      partnersFormFieldsCopy[index].fields[fieldsIndex][fieldIndex].value = inputValueCopy
    } else {
      const { value, isValid, errorMessage } = validateField(
        partnersFormFieldsCopy[index],
        inputValueCopy,
        false,
        partnersFormFieldsCopy,
      )
      partnersFormFieldsCopy[index] = {
        ...partnersFormFieldsCopy[index],
        value,
        isValid,
        errorMessage,
      }
    }
    if (field?.dependencyIndex) {
      const conpareField = validateField(
        partnersFormFieldsCopy[field?.dependencyIndex],
        partnersFormFieldsCopy[field?.dependencyIndex]?.value,
        false,
        partnersFormFieldsCopy,
      )
      partnersFormFieldsCopy[field?.dependencyIndex] = {
        ...partnersFormFieldsCopy[field?.dependencyIndex],
        isValid: conpareField.isValid,
        errorMessage: conpareField.errorMessage,
      }
    }
    setPartnersFormFields(partnersFormFieldsCopy)
  }

  const updatePartnerFunction = async () => {
    const payload = getValuesFromFormConfig(partnersFormFields, {
      partnerId: partnersData?._id,
      resendEmail: false,
    })
    getAllowedAssetTypePayload(payload)
    if (payload.allowedMasters.length || payload.allowedAssetTypeConfigurations?.length) {
      if (payload.contactPhone === '') {
        payload.contactPhone = null
      }
      dispatch(setLoader(true))
      const requestedData: any = PARTNER_APIS.EDIT_PARTNER(payload)
      const result = await mainApiService(requestedData)
      dispatch(setLoader(false))
      if (result?.responseCode === 200) {
        dispatch(showAlert([true, result?.message, 'success']))
        setPartnersFormFields(PARTNER_CONSTENT.initialField)
        navigate(`/partners/view/${partnersData?._id}`)
      } else {
        dispatch(showAlert([true, result?.error?.errors || result?.error?.message, 'error']))
      }
    } else {
      dispatch(showAlert([true, PARTNER_CONSTENT.errorMessageForAllowed, 'error']))
    }
  }

  const createPartnerFunction = async () => {
    const payload = getValuesFromFormConfig(partnersFormFields)
    if (payload.contactPhone === '') {
      payload.contactPhone = null
    }
    getAllowedAssetTypePayload(payload)
    if (payload.allowedMasters.length || payload.allowedAssetTypeConfigurations?.length) {
      dispatch(setLoader(true))
      const requestedData: any = PARTNER_APIS.CREATE_PARTNER(payload)
      const result = await mainApiService(requestedData)
      dispatch(setLoader(false))

      if (result?.responseCode === 200) {
        dispatch(showAlert([true, result.message, 'success']))
        setPartnersFormFields(PARTNER_CONSTENT.initialField)
        navigate('/partners')
      } else {
        dispatch(showAlert([true, result?.error?.errors || result?.error?.message, 'error']))
      }
    } else {
      dispatch(showAlert([true, PARTNER_CONSTENT.errorMessageForAllowed, 'error']))
    }
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    const { isValid, formConfig } = validateForm(partnersFormFields, true)
    if (isValid) {
      if (id === 'create') {
        createPartnerFunction()
      } else {
        updatePartnerFunction()
      }
    } else {
      setPartnersFormFields(formConfig)
    }
  }

  const handleAddMoreRemoveAction = (
    _ev: any,
    formFieldIndex: number,
    FormJsonIndex: number,
    action: boolean,
  ) => {
    const partnersFormFieldsCopy = JSON.parse(JSON.stringify(partnersFormFields))
    const formField = partnersFormFieldsCopy[formFieldIndex]
    if (action) {
      formField.fields.push(JSON.parse(JSON.stringify(formField.fieldConfig)))
    } else {
      formField.fields.splice(FormJsonIndex, 1)
    }
    setPartnersFormFields(partnersFormFieldsCopy)
  }

  return (
    <form onSubmit={handleSubmit}>
      <MUIBox>
        <div>
          <div className='heading-6 d-flex text-left rolebox__header-padding'>
            Partner Information
          </div>
          <div className={'hr'}></div>
          <div className='rolebox__content-padding'>
            <Grid container columnSpacing={10} rowSpacing={4}>
              <FormRender
                formFields={partnersFormFields}
                onChange={handleChangeInput}
                addMoreRemoveAction={handleAddMoreRemoveAction}
                formFieldsOptions={formFieldsOptions}
              />
              <Grid item xs={12} className='d-flex justify-content-end'>
                <MUIButton
                  size='large'
                  type={'submit'}
                  label={'Submit'}
                  variant='contained'
                  width={200}
                  className='c-btn'
                  disabled={
                    id === 'edit' &&
                    JSON.stringify(initialField) === JSON.stringify(partnersFormFields)
                  }
                />
              </Grid>
            </Grid>
          </div>
        </div>
      </MUIBox>
    </form>
  )
}

export default PartnersForm
