export const TIPS_MODULE = {
  ATTRIBUTE_REPOSITORY: 'ATTRIBUTE_REPOSITORY',
  ASSET_DEFINITION: 'ASSET_DEFINITION',
  GEO_POLICY: 'GEO_POLICY',
  MASTERS: 'MASTERS',
  MANAGE_ROLE: 'MANAGE_ROLE',
  MANAGE_USER: 'MANAGE_USER',
  MANAGE_CONTENT: 'MANAGE_CONTENT',
  MANAGE_PARTNER: 'MANAGE_PARTNER',
  BULK_UPDATE: 'BULK_UPDATE',
}

const TIPS_SELECTION: any = {
  ATTRIBUTE_REPOSITORY: {
    MODULE: 'Attribute Repository',
    VIEW: 'View Attribute',
    EDIT: 'Edit Attribute',
    CREATE: 'Create Attribute',
  },
  ASSET_DEFINITION: {
    MODULE: 'Asset Definition',
    VIEW: 'View Asset Type',
    EDIT: 'Edit Asset Type',
    CREATE: 'Create Asset Type',
  },
  GEO_POLICY: {
    MODULE: 'Geo Policy',
    VIEW: 'View Geo Policy',
    EDIT: 'Edit Geo Policy',
    CREATE: 'Create Geo Policy',
  },
  MASTERS: {
    MODULE: 'Master',
    VIEW: 'View Value',
    EDIT: 'Edit Value',
    CREATE: 'Create Value',
  },
  MANAGE_ROLE: {
    MODULE: 'Role Management',
    VIEW: 'View Role',
    EDIT: 'Edit Role',
    CREATE: 'Create Role, View/Edit Role',
  },
  MANAGE_USER: {
    MODULE: 'User Management',
    VIEW: 'View User',
    EDIT: 'Edit User',
    CREATE: 'Create User',
  },
  MANAGE_PARTNER: {
    MODULE: 'Inbound Partners',
    VIEW: 'View Partner',
    EDIT: 'Update Partner',
    CREATE: 'Create Partner',
  },
  BULK_UPDATE: {
    MODULE: 'Bulk Update',
    VIEW: 'View Job',
    EDIT: 'Create Job',
    CREATE: 'Create Job',
  },
  MANAGE_CONTENT: {
    MODULE: 'Manage Content',
    RIGHTS_AND_AVAILABILITY: {
      VIEW: 'Asset Creation: Rights & Availability View',
      UPDATE: 'Asset Creation: Rights & Availability Add/Edit',
    },
    BUSINESS_RULES: {
      VIEW: 'Asset Creation: Business Rules View',
      UPDATE: 'Asset Creation: Business Rules Add/Edit',
    },
    CONTENT_MEDIA_VIDEO: {
      VIEW: 'Asset Creation: Content Media: Video View',
      UPDATE: 'Asset Creation: Content Media: Video Add/Edit',
    },
    CONTENT_MEDIA_IMAGE: {
      VIEW: 'Asset Creation: Content Media: Images View',
      UPDATE: 'Asset Creation: Content Media: Images Add/Edit',
    },
    DESCRIPTIVE_METADATA: {
      VIEW: 'Asset Creation: Descriptive View',
      UPDATE: 'Asset Creation: Descriptive Add/Edit',
    },
    TECHNICAL_METADATA: {
      VIEW: 'Asset Creation: Technical View',
      UPDATE: 'Asset Creation: Technical Add/Edit',
    },
    ADVERTISEMENT_METADATA: {
      VIEW: 'Asset Creation: Advertisement View',
      UPDATE: 'Asset Creation: Advertisement Add/Edit',
    },
    SEO_METADATA: {
      VIEW: 'Asset Creation: SEO View',
      UPDATE: 'Asset Creation: SEO Add/Edit',
    },
    SPORTS_METADATA: {
      VIEW: 'Asset Creation: Sports View',
      UPDATE: 'Asset Creation: Sports Add/Edit',
    },
    MISCELLANEOUS_METADATA: {
      VIEW: 'Asset Creation: Miscellaneous View',
      UPDATE: 'Asset Creation: Miscellaneous Add/Edit',
    },
    CHECKLIST: {
      VIEW: 'Asset Creation: Checklist',
      UPDATE: 'Asset Creation: Checklist',
    },
  },
}

export const formatTootipData = (records: any = []) => {
  const tootipsData: any = {}
  records.forEach((record: any) => {
    const { module } = record
    if (tootipsData[module]) {
      tootipsData[module] = {
        module,
        pages: [
          ...tootipsData[module].pages,
          { ...record, tooltipMessages: record.tooltipMessages },
        ],
      }
    } else {
      tootipsData[module] = {
        module,
        pages: [{ ...record, tooltipMessages: record.tooltipMessages }],
      }
    }
  })
  return tootipsData
}

const formatViewTootipData = (records: any = []) => {
  const tootipsData: any = {}
  records.forEach((record: any) => {
    const { module, page } = record
    if (tootipsData[module]) {
      tootipsData[module] = {
        ...tootipsData[module],
        [page]: record.tooltipMessages,
      }
    } else {
      tootipsData[module] = {
        [page]: record.tooltipMessages,
      }
    }
  })
  return tootipsData
}

export const getTipsForPage = (
  tipsDataRes: any = [],
  module: string,
  pageType: string,
  page?: string,
) => {
  try {
    let tips = []
    const tipsData = formatViewTootipData(tipsDataRes)
    const tipsModule = TIPS_SELECTION[module.toUpperCase()]
    if (tipsModule?.MODULE && tipsData[tipsModule.MODULE]) {
      const dataMudule = tipsModule.MODULE
      if (page) {
        const dataPage = tipsModule[page.toUpperCase()]
        const dataPageType = dataPage[pageType.toUpperCase()]
        tips = tipsData[dataMudule][dataPageType] || []
      } else {
        const dataPageType = tipsModule[pageType.toUpperCase()]
        tips = tipsData[dataMudule][dataPageType] || []
      }
    }
    return tips
  } catch (_error) {
    return []
  }
}
