import React from 'react'

const ReTriggerIcon: React.FC = () => {
  return (
    <svg width='15' height='21' viewBox='0 0 15 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g id='Group'>
        <g id='Group_2'>
          <path
            id='Vector'
            d='M3.79006 8.24V4.5C3.79006 3.12 4.91006 2 6.29006 2C7.67006 2 8.79006 3.12 8.79006 4.5V8.24C10.0001 7.43 10.7901 6.06 10.7901 4.5C10.7901 2.01 8.78006 0 6.29006 0C3.80006 0 1.79006 2.01 1.79006 4.5C1.79006 6.06 2.58006 7.43 3.79006 8.24ZM9.29006 10.71C9.01006 10.57 8.71006 10.5 8.40006 10.5H7.79006V4.5C7.79006 3.67 7.12006 3 6.29006 3C5.46006 3 4.79006 3.67 4.79006 4.5V15.24L1.35006 14.52C0.980058 14.44 0.590059 14.56 0.320059 14.83C-0.109941 15.27 -0.109941 15.97 0.320059 16.41L4.33006 20.42C4.71006 20.79 5.22006 21 5.75006 21H11.8501C12.8501 21 13.6901 20.27 13.8301 19.28L14.4601 14.81C14.5801 13.96 14.1401 13.12 13.3701 12.74L9.29006 10.71Z'
            fill='#323232'
          />
        </g>
      </g>
    </svg>
  )
}

export default ReTriggerIcon
