export const getPartnerStatus = (status: any) => {
  if (status === 'ACTIVE') {
    return { value: 'Active', key: 'ACTIVE' }
  } else {
    return { value: 'In Active', key: 'INACTIVE' }
  }
}

export const getMasterDropdown = (value: string[], master: any[]) => {
  return master.filter((item) => value?.includes(item.masterKey))
}

export const getAssetTypeKeyValue = (value: string[], master: any[]) => {
  return master.filter((item) => value?.includes(item.value))
}

export const getEditPartnerMastersValue = (field: any, value: any, options: any) => {
  // Helper function to determine what to return for a given option
  const getOptionKey = (option: any) => {
    // If the first element in options is a string, use the option directly
    if (typeof option === 'string') {
      return option
    }

    // If the valueKey exists in the field, use option[field.valueKey]
    if (field.valueKey && option[field.valueKey]) {
      return option[field.valueKey]
    }

    // Default fallback is option.key
    return option.key
  }

  // Check if options exist and value is defined
  if (field.options?.length && value) {
    if (field.isMultiple) {
      // Filter for multiple selections
      return field.options.filter((option: any) => value?.includes(getOptionKey(option)))
    } else {
      // Find for a single selection
      return field.options.find((option: any) => getOptionKey(option) === value)
    }
  } else if (field.formFieldsOptionsKey && options[field.formFieldsOptionsKey]) {
    // Handle cases where formFieldsOptionsKey is used
    if (field.isMultiple) {
      return options[field.formFieldsOptionsKey].filter(
        (option: any) => value?.includes(getOptionKey(option)),
      )
    } else {
      return options[field.formFieldsOptionsKey].find(
        (option: any) => getOptionKey(option) === value,
      )
    }
  }

  // Return field value as default
  return field.value
}

export const getAllowedAssetTypePayload = (payload: any) => {
  if (payload?.allowedAssetTypeConfigurations?.length) {
    payload.allowedAssetTypeConfigurations = payload.allowedAssetTypeConfigurations.filter(
      (item: any) => !!item.assetType,
    )
  }
  if (payload.allowedAssetTypeConfigurations?.length) {
    payload.allowedAssetTypes = payload.allowedAssetTypeConfigurations.map(
      (item: any) => item.assetType,
    )
  } else if (payload.allowedAssetTypeConfigurations) {
    delete payload.allowedAssetTypeConfigurations
  }
}
