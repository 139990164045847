import { Grid } from '@mui/material'
import { CONSTANTS } from '../../Constants/Constant'
import { checkErrorData } from '../../Helpers/Util'
import MUIDateTimePicker from '../MUIDateTimePicker'
import { PARTNER_CONSTENT } from '../Partners/PartnersEditor/PartnersConstent'
import { MUIFieldWithoutControl, MUIMultiselectAsset } from '../Shared'
import JSONInputField1 from '../Shared/JSONInputField1'

interface FormRenderProps {
  formFields: any[]
  onChange: (params: any) => void
  addMoreRemoveAction?: (
    ev: any,
    formFieldIndex: number,
    FormJsonIndex: number,
    action: boolean,
  ) => void
  formFieldsOptions?: any
}
const getErrorObject = (item: any) => {
  if (!item.errorMessage) return {}
  return { [item.fieldName]: { message: item.errorMessage } }
}
const FormRender = ({
  formFields,
  onChange,
  addMoreRemoveAction,
  formFieldsOptions,
}: FormRenderProps) => {
  const validFieldTypes = [CONSTANTS.FIELDTYPES.TEXT, CONSTANTS.FIELDTYPES.NUMBER]

  return (
    <Grid container spacing={2}>
      {formFields.map((item, index: number) => {
        let DateValue = null
        DateValue = item?.value && new Date(item.value)
        if (validFieldTypes?.includes(item.fieldType)) {
          return (
            <Grid item xs={6} md={6} lg={6} xl={6} key={item.id}>
              <MUIFieldWithoutControl
                style={{
                  '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button':
                    {
                      '-webkit-appearance': 'none',
                    },
                }}
                name={item.fieldName}
                type={item.fieldType}
                error={getErrorObject(item)}
                onKeyDown={(ev: any) => {
                  if (
                    item.fieldType === 'NUMBER' &&
                    PARTNER_CONSTENT.AVOID_KEY_FOR_PHONE?.includes(ev.key)
                  )
                    ev.preventDefault()
                }}
                onPaste={(ev: any) => {
                  if (
                    item.fieldType === 'NUMBER' &&
                    checkErrorData(ev.clipboardData.getData('text'))
                  )
                    ev.preventDefault()
                }}
                label={item.label}
                placeholder={item.label}
                value={item.value}
                onChange={(ev: any) => {
                  onChange({ ev, index, item })
                }}
                required={item?.validations?.required}
              />
            </Grid>
          )
        } else if (item.fieldType === CONSTANTS.FIELDTYPES.DATETIME) {
          return (
            <Grid item xs={6} md={6} lg={6} xl={6} key={item.id}>
              <MUIDateTimePicker
                label={item?.label}
                type='datetime'
                views={CONSTANTS.DATE_TIME_FORMAT_VIEWS}
                name={item?.fieldName}
                error={getErrorObject(item)}
                value={DateValue}
                onChange={(ev: any) => onChange({ ev, index, item })}
                required={item?.validations?.required}
              />
            </Grid>
          )
        } else if (item.fieldType === CONSTANTS.FIELDTYPES.SELECT) {
          let options = item?.options
          if (item?.formFieldsOptionsKey && formFieldsOptions[item.formFieldsOptionsKey]) {
            options = formFieldsOptions[item.formFieldsOptionsKey]
          }
          return (
            <Grid item xs={6} md={6} lg={6} xl={6} key={item.id}>
              <MUIMultiselectAsset
                name={item?.fieldName}
                className='autocomple-class'
                multiple={item?.isMultiple}
                label={item?.label}
                options={options}
                error={getErrorObject(item)}
                onInputChange={(_ev: any, newValue: any) => {
                  onChange({ ev: newValue, index, item })
                }}
                id={item?.fieldName}
                value={item?.value}
                labelKey={item?.labelKey}
                required={item?.validations?.required}
              />
            </Grid>
          )
        } else if (item.fieldType === CONSTANTS.FIELDTYPES.JSON1) {
          return (
            <JSONInputField1
              key={item.id}
              label={item?.label}
              fields={item?.fields}
              addMore={item?.addMore}
              index={index}
              addMoreRemoveAction={addMoreRemoveAction}
              formFieldsOptions={formFieldsOptions}
              onInputChange={onChange}
            />
          )
        } else {
          return null
        }
      })}
    </Grid>
  )
}

export default FormRender
