import { Button } from '@mui/material'
import { FC } from 'react'
import { MUIButtonModel } from '../../../Models'

const MUIButton: FC<MUIButtonModel> = ({
  id,
  type,
  label,
  variant,
  color,
  disabled,
  size = 'medium',
  fullWidth = false,
  className = '',
  handleClickCB,
  width = 200,
  height = 'fit-content',
  fontWeight = 500,
  fontSize = '16px',
  Icon = null,
  borderRadius = '',
}) => {
  return (
    <Button
      startIcon={Icon !== null && Icon !== undefined ? Icon : undefined}
      disabled={disabled}
      color={color}
      variant={variant}
      fullWidth={fullWidth}
      type={type}
      size={size}
      data-testid={id ? id : label}
      sx={{
        minWidth: width,
        height: height,
        fontWeight: fontWeight,
        fontSize: fontSize,
        borderRadius: borderRadius,
      }}
      className={className}
      onClick={handleClickCB}
    >
      <span data-testid='button-test' style={{ textTransform: 'none' }}>
        {label}
      </span>
    </Button>
  )
}

export default MUIButton
