import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import {
  Box,
  CssBaseline,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material'
import { ReactNode, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { PAGES_ROUTES } from '../../../Constants'
import { AUTH_MANAGEMENT } from '../../../Constants/ApiDefinations'
import { checkModuleAccess } from '../../../Helpers/Util'
import mainApiService from '../../../Services'
import Header from '../Header'
import { Drawer, DrawerHeader } from './MUI-Drawer-Style'
import './muiDrawer.scss'

export interface MUIDrawerProps {
  children?: ReactNode
  drawerData?: any[]
}

export default function MUIDrawer({ children, drawerData = [] }: MUIDrawerProps) {
  const navigate = useNavigate()
  const location = useLocation()
  const [sidebarItems, setSidebarItems] = useState<any>([])
  const [buildReleaseInfo, setBuildReleaseInfo] = useState({
    build: '',
    date: '',
  })

  const [open, setOpen] = useState(true)
  const [openNav, setOpenNav] = useState<any>([])

  const fetchReleaseInfo = async () => {
    try {
      const { data = {} } = await mainApiService(AUTH_MANAGEMENT.RELEASE_INFO())
      const { releaseDate = '', version = '' } = data
      setBuildReleaseInfo({
        ...buildReleaseInfo,
        build: version,
        date: releaseDate,
      })
    } catch (error: any) {
      console.log(error, 'Error in build version')
    }
  }

  useEffect(() => {
    fetchReleaseInfo()
  }, [])

  useEffect(() => {
    activateSelectedRoute(drawerData)
  }, [location.pathname, location?.state?.fromPage, drawerData])

  const activateSelectedRoute = (drawerDataArgs: any = []) => {
    const fromPage = location?.state?.fromPage || ''
    const openNavCopy: any = []
    const makeActive = (data: any, parentDetails: any = '') => {
      for (let i = 0; i < data.length; i++) {
        if (location.pathname.includes(data[i].path) || fromPage === data[i].id) {
          data[i].active = true
          if (parentDetails) {
            parentDetails.active = true
            openNavCopy.push(parentDetails.id)
          }
          openNavCopy.push(data[i].id)
        } else {
          data[i].active = false
        }
        if (data[i].child?.length) {
          makeActive(data[i].child, data[i])
        }
      }
    }
    makeActive(drawerDataArgs)
    setOpenNav(openNavCopy)
    setSidebarItems(drawerData)
  }

  const handleOnClickItem = (listItemObj: any) => {
    let openNavCopy = [...openNav]
    if (listItemObj.child?.length) {
      if (!open) {
        let path = listItemObj.path
        if (!checkModuleAccess(listItemObj?.role)) {
          path = `${PAGES_ROUTES.AccessDenied}`
        }
        path === `${PAGES_ROUTES.AccessDenied}`
          ? navigate(path, { state: { fromPage: listItemObj?.id } })
          : navigate(path)
        return
      }
      if (openNavCopy.includes(listItemObj.id)) {
        openNavCopy = openNavCopy.filter((item) => item !== listItemObj.id)
      } else {
        openNavCopy = [listItemObj.id]
      }
      setOpenNav(openNavCopy)
    } else {
      let path = listItemObj.path
      if (!checkModuleAccess(listItemObj?.role)) {
        path = `${PAGES_ROUTES.AccessDenied}`
      }
      path === `${PAGES_ROUTES.AccessDenied}`
        ? navigate(path, { state: { fromPage: listItemObj?.id } })
        : navigate(path)
    }
  }

  return (
    <Box>
      <CssBaseline />
      <Header setOpen={setOpen} drawerState={open} />
      <Drawer className='left-sidebar' variant='permanent' open={open}>
        <Divider />
        <List
          className={`sidebar-list text-inter ${open ? 'open-sidebar' : ''}`}
          style={{ marginTop: '60px' }}
        >
          {sidebarItems.map((listItemObj: any) => (
            <ListItem
              className='text-inter'
              key={listItemObj.id}
              disablePadding
              sx={{ display: 'block' }}
            >
              <DrawerListItem
                listItemObj={listItemObj}
                openNav={openNav}
                onClickItem={(e: any) => {
                  e.preventDefault()
                  handleOnClickItem(listItemObj)
                }}
                isChild={false}
                open={open}
              />
              {(openNav || []).includes(listItemObj.id) &&
                open &&
                listItemObj.child?.map((childItemObj: any) => (
                  <DrawerListItem
                    key={childItemObj.id}
                    listItemObj={childItemObj}
                    openNav={openNav}
                    onClickItem={(e: any) => {
                      e.preventDefault()
                      handleOnClickItem(childItemObj)
                    }}
                    isChild={true}
                    open={open}
                  />
                ))}
            </ListItem>
          ))}
        </List>
        {open && (
          <div className='sidebar-footer-main'>
            <h6>Application Version : {buildReleaseInfo?.build}</h6>
            <p>Last Released on {buildReleaseInfo?.date}</p>
          </div>
        )}
      </Drawer>
      <Box component='main' sx={{ flexGrow: 1, p: 0 }}>
        <Grid
          className={`${open ? 'content-open-layout' : 'content-close-layout'}`}
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 12 }}
        >
          <DrawerHeader />
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export interface ListItemPorpsType {
  listItemObj: any
  openNav?: any[]
  onClickItem?: any
  isChild: boolean
  open: boolean
}

export const DrawerListItem = ({
  listItemObj,
  openNav,
  onClickItem,
  isChild,
  open,
}: ListItemPorpsType) => {
  const isOpen = (openNav || []).includes(listItemObj.id)

  return (
    <ListItemButton
      sx={{
        minHeight: '52px',
        display: 'flex',
        alignItems: 'center',
        margin: '10px',
        borderRadius: '4px',
        justifyContent: open ? 'initial' : 'center',
        borderBottom: isChild ? '1px solid #ECE9F2' : '',
        color: listItemObj.active ? (isChild ? '#ffff' : '#402080') : '',
        borderLeft: isChild ? '' : listItemObj.active ? '2px solid #402080' : '2px solid #ffff',
        backgroundColor: listItemObj.active ? (isChild ? '#402080' : '#ECE9F2') : '',
        ':hover': { backgroundColor: '#ECE9F2', color: 'black' },
        // ':hover': isChild ? { backgroundColor: '#ECE9F2', color: 'black' } : {},
      }}
      className={`text-inter ${listItemObj.active ? 'active' : ''}`}
      onClick={onClickItem}
      data-testid={listItemObj.id}
    >
      {listItemObj.icon && (
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: open ? 1.5 : 0,
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {isOpen ? listItemObj.activeIcon : listItemObj.icon}
        </ListItemIcon>
      )}
      {listItemObj.child?.length ? (
        <ListItemText
          primary={
            <div className='d-flex align-items-center justify-content-between'>
              <Typography className='text-inter font-medium'> {listItemObj.lable}</Typography>
              {isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </div>
          }
          sx={{ opacity: open ? 1 : 0 }}
        />
      ) : (
        <Link
          to={listItemObj.path}
          state={{ fromPage: listItemObj?.id }}
          style={{
            textDecoration: 'none',
            display: 'contents',
            alignItems: 'center',
            width: '100%',
          }}
          className='table-link'
        >
          <ListItemText
            className='text-inter font-medium'
            primary={listItemObj.lable}
            sx={{ paddingLeft: isChild ? 4 : 0, opacity: open ? 1 : 0 }}
          />
        </Link>
      )}
    </ListItemButton>
  )
}
