import { TypeObject } from '@mui/material/styles/createPalette'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import BulkImageUploadCard from '../../../Components/BulkImageUpload'
import DuplicateImageDialog from '../../../Components/BulkImageUpload/DupicateImageDialog'
import ExistingImageDialog from '../../../Components/BulkImageUpload/ExistingImageDialog'
import RoleConfirmation from '../../../Components/Dialogs/RoleConfirmation'
import ContentCategory from '../../../Components/Home/ContentCategory'
import '../../../Components/ManageContent/ManageAsset/AssetEditor.scss'
import AssetHeaderComponent from '../../../Components/Molecule/AssetCreationHeader'
import { BackButton, Breadcrumbs, MUIButton, MUITipsCard } from '../../../Components/Shared'
import CONSTANTS, { BREADCRUMS, PAGES_ROUTES } from '../../../Constants'
import { ASSET_CREATION, ASSET_CURD_API } from '../../../Constants/ApiDefinations'
import {
  findDuplicates,
  findExistingImage,
  prepareBulkImageUploadPayload,
  redirect,
} from '../../../Helpers/BulkImageUpload'
import { TIPS_MODULE } from '../../../Helpers/ManageTips'
import {
  createBulkMediaImageReqData,
  uploadBulkImageUsingPresignedUrl,
} from '../../../Helpers/MediaImage'
import { checkAnyMetaWritePermission } from '../../../Helpers/Permission'
import { combineErrorMessage, getDateInFormat } from '../../../Helpers/Util'
import { BreadcrumsModel, RoleConfirmationModal } from '../../../Models'
import { assetViewApiModal } from '../../../Models/AssetModal'
import mainApiService from '../../../Services'
import { useAppSelector } from '../../../Store/Hooks'
import { showAlert } from '../../../Store/Slice/alert.slice'
import {
  selectAsset,
  viewAssetDefinitionByAssetType,
} from '../../../Store/Slice/asset-definition.slice'
import { setLoader } from '../../../Store/Slice/loader.slice'
import './BulkImageUpload.scss'

const BulkImageUpload = () => {
  const asset = useAppSelector(selectAsset)
  const [assetTitle, setAssetTitle] = useState<string>('')
  const [mappedImagesData, setMappedImagesData] = useState<TypeObject[]>([])
  const [detailViewState, setDetailViewState] = useState<Record<string, any>>({})
  const [assetOtherDetails, setAssetOtherDetails] = useState<Record<string, any>>({})
  const [isAnyMetaPermission, setIsAnyMetaPermission] = useState(true)

  const { contentId, assetType, metaCategory, assetCategory, conCategory } = useParams<{
    contentId: string | undefined
    assetType: string | undefined
    metaCategory: string | undefined
    assetCategory: string | undefined
    conCategory: string | undefined
  }>()

  const [existingImageDialogProps, setExistingImageDialogProps] = useState<{
    open: boolean
    existingImages: TypeObject[]
    handleSuccess?: () => void
    handleClose?: () => void
  }>({ open: false, existingImages: [] })

  const [dialogProps, setDialogProps] = useState<RoleConfirmationModal>({
    heading: '',
    description: '',
    color: 'primary',
    open: false,
  })

  const handleClose = () => {
    setExistingImageDialogProps({ open: false, existingImages: [] })
    setDuplicateImageDialogProps({ open: false, duplicateImages: [] })
    setDialogProps({
      heading: '',
      description: '',
      color: 'primary',
      open: false,
    })
  }

  const [duplicateImageDialogProps, setDuplicateImageDialogProps] = useState<{
    open: boolean
    duplicateImages: TypeObject[]
    handleClose?: () => void
  }>({ open: false, duplicateImages: [] })

  const handleExistingImageDialog = (existingImagesData: TypeObject[]) => {
    setExistingImageDialogProps({
      open: true,
      handleSuccess: () => {
        onSubmit(true)
      },
      handleClose: handleClose,
      existingImages: existingImagesData,
    })
  }

  const handleDuplicateImagesDilaog = (duplicateImagesData: TypeObject[]) => {
    setDuplicateImageDialogProps({
      open: true,
      handleClose: handleClose,
      duplicateImages: duplicateImagesData,
    })
  }

  const getCategoryData = useCallback(async () => {
    const reqData: assetViewApiModal = ASSET_CREATION.VIEW_MARK_DONE_STATUS(contentId as string)
    const res = await mainApiService(reqData)

    if (res?.data) {
      setAssetTitle(res?.data?.assetTitle ?? '')

      if (res?.data?.parentDetails) {
        res.data.parentDetails.isParentPublished = res.data.isParentPublished
      }
      setAssetOtherDetails({
        lastPublished: res?.data?.lastPublished,
        contractEndDate: res?.data?.contractEndDate,
        parentDetails: res?.data?.parentDetails,
        contractStartDate: res?.data?.contractStartDate,
        grandParentDetails: res?.data?.grandParentDetails,
        avsContentStatus: res?.data?.contentDetails?.avsContentStatus,
        action: res?.data?.contentDetails?.action,
      })
    }

    const requestedData: assetViewApiModal = ASSET_CREATION.VIEW_ASSET_REPO_BASED_ON_CATEGORY(
      contentId as string,
      metaCategory === CONSTANTS.CATEGORIES.CHECKLIST
        ? CONSTANTS.CATEGORIES.RIGHTS_AND_AVAILABILITY
        : (metaCategory as string),
    )

    const result = await mainApiService(requestedData)

    if (result?.error?.responseCode === 403) {
      dispatch(
        showAlert([true, result?.error?.message ?? CONSTANTS.MESSAGES.NO_DATA_CONTENT_ID, 'error']),
      )
      return
    }

    if (result?.error) {
      dispatch(
        showAlert([true, result?.error?.message ?? CONSTANTS.MESSAGES.NO_DATA_CONTENT_ID, 'error']),
      )
      navigate(`/${PAGES_ROUTES.ManageContent}/${conCategory?.toLowerCase()}`)
      return
    }

    setDetailViewState({
      data: {
        ...result?.data,
        blitzId: res?.data?.contentDetails?.blitzId,
        isStandAlonePublishing: res?.data?.contentDetails?.isStandAlonePublishing,
      },
    })
  }, [metaCategory, contentId])

  const getAssetDefinition = useCallback(async () => {
    await dispatch(
      viewAssetDefinitionByAssetType({
        assetType,
        contentCategory: conCategory,
        assetCategory,
      }) as any,
    )
  }, [assetType, ContentCategory, assetCategory])

  useEffect(() => {
    setIsAnyMetaPermission(checkAnyMetaWritePermission())
    getCategoryData()
    getAssetDefinition()
  }, [getCategoryData, getAssetDefinition])

  const { appLabels } = useAppSelector((state) => state.appConfig)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const getMappedImages = (mappedImages: TypeObject[]) => {
    setMappedImagesData(mappedImages)
  }

  const onSubmit = async (submitAnyHow?: boolean | undefined) => {
    if (!submitAnyHow) {
      const duplicates = findDuplicates(mappedImagesData)
      if (duplicates?.length) {
        handleDuplicateImagesDilaog(duplicates as any)
        return
      }

      const existingImgs = findExistingImage(mappedImagesData, detailViewState)
      if (existingImgs?.length) {
        handleExistingImageDialog(existingImgs)
        return
      }
    }

    try {
      dispatch(setLoader(true))
      const imageKeysData = createBulkMediaImageReqData(detailViewState.data, mappedImagesData)
      await uploadBulkImageUsingPresignedUrl(imageKeysData)

      const imagesData = prepareBulkImageUploadPayload({
        contentId,
        metaCategory,
        detailViewState,
        imageKeysData,
        asset,
      })

      const payloadData = ASSET_CURD_API.IMAGE_CONTENT_TYPE.BULK_IMAGE_UPLOAD(imagesData)
      const result = await mainApiService(payloadData)

      if (result?.error) {
        const error = combineErrorMessage(result)
        dispatch(setLoader(false))
        handleClose()
        dispatch(showAlert([true, error, 'error']))
        return
      }
      dispatch(setLoader(false))
      handleClose()
      dispatch(showAlert([true, 'Images Uploaded Successfully', 'success']))
      const navUrl = `/asset/${'view'}/${contentId}/${assetType}/${conCategory}/${assetCategory}/${metaCategory}`
      navigate(navUrl)
    } catch (e: any) {
      handleClose()
      dispatch(setLoader(false))
      dispatch(showAlert([true, e?.error?.message ?? e?.error ?? e, 'error']))
    }
  }

  return (
    <>
      <div className='wrapper bulk__image p-16'>
        <div className='p-8'>
          <Breadcrumbs
            breadcrums={
              BREADCRUMS.CREATE_ASSET({
                pageType: 'update',
                contentId,
                assetType,
                conCategory,
                conCategoryLabel: appLabels[conCategory as string],
                assetCategory,
                metaCategory,
                state: detailViewState?.data?.state,
                parentDetails: assetOtherDetails.parentDetails,
                type: 'bulk-image-upload',
              }) as BreadcrumsModel[]
            }
          />
        </div>
        <div className='p-8'>
          <BackButton
            label={appLabels[conCategory as string]}
            className='col73'
            handleOnClick={() => {
              redirect(-1, false, navigate, handleClose, mappedImagesData, setDialogProps)
            }}
          />
          <div className='asset__editor'>
            <div className='asset__editor-content fullWidth_content'>
              <div className='pb-1'>
                <AssetHeaderComponent
                  isAnyMetaPermission={isAnyMetaPermission}
                  blitzId={detailViewState?.data?.blitzId}
                  contentID={detailViewState?.data?.contentId}
                  creationDate={getDateInFormat(
                    detailViewState?.data?.createdAt ?? '',
                    CONSTANTS.DATE_FORMAT_TIME,
                  )}
                  updatedTime={getDateInFormat(
                    detailViewState?.data?.updatedAt ?? '',
                    CONSTANTS.DATE_FORMAT_TIME,
                  )}
                  title={assetTitle}
                  assetType={detailViewState?.data?.assetType ?? ''}
                  status={detailViewState?.data?.state ?? ''}
                  assetOtherDetails={assetOtherDetails}
                />
              </div>
              <BulkImageUploadCard getMappedImages={getMappedImages} />
            </div>
            <div className='asset__editor-extras'>
              <div className='ml-1 mt-1'>
                <MUITipsCard
                  tipsArr={CONSTANTS.VIDEO_SECTION_TIPS}
                  module={TIPS_MODULE.MANAGE_CONTENT}
                  pageType={CONSTANTS.PAGE_TYPE.UPDATE}
                  page={metaCategory}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='submit__handler asset__editor-btn'>
        <div className='submit__handler-btn-cont'>
          <MUIButton
            size='large'
            type={'button'}
            label={'Submit'}
            variant='contained'
            width={200}
            handleClickCB={() => onSubmit()}
            disabled={!mappedImagesData?.length}
          />
        </div>
      </div>
      <ExistingImageDialog {...existingImageDialogProps} />
      <DuplicateImageDialog {...duplicateImageDialogProps} />
      <RoleConfirmation {...dialogProps} />
    </>
  )
}

export default BulkImageUpload
