const ErrorSolidIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='17' height='16' viewBox='0 0 17 16' fill='none'>
      <path
        d='M8.76432 1.33594C5.08432 1.33594 2.09766 4.3226 2.09766 8.0026C2.09766 11.6826 5.08432 14.6693 8.76432 14.6693C12.4443 14.6693 15.431 11.6826 15.431 8.0026C15.431 4.3226 12.4443 1.33594 8.76432 1.33594ZM8.76432 8.66927C8.39766 8.66927 8.09766 8.36927 8.09766 8.0026V5.33594C8.09766 4.96927 8.39766 4.66927 8.76432 4.66927C9.13099 4.66927 9.43099 4.96927 9.43099 5.33594V8.0026C9.43099 8.36927 9.13099 8.66927 8.76432 8.66927ZM9.43099 11.3359H8.09766V10.0026H9.43099V11.3359Z'
        fill='#D4351C'
      />
    </svg>
  )
}

export default ErrorSolidIcon
