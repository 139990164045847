import { unwrapResult } from '@reduxjs/toolkit'
import { format, getTime } from 'date-fns'
import { jwtDecode } from 'jwt-decode'
import { PARTNER_CONSTENT } from '../Components/Partners/PartnersEditor/PartnersConstent'
import CONSTANTS, { PAGES_ROUTES } from '../Constants'
import { AUTH_MANAGEMENT, BULK_UPDATE_APIS } from '../Constants/ApiDefinations'
import { TypeObject, roleModulesModal } from '../Models'
import mainApiService from '../Services'
import { store } from '../Store'
import { showAlert } from '../Store/Slice/alert.slice'
import { generatePresignedUrlForSubtitle } from '../Store/Slice/orchestration.slice'
import { setAuthConfig } from '../Store/Slice/user.slice'
import { secondsToHMS } from './DurationHelper'
import { getLocalStorage, setLocalStorage } from './Storage'

export const getStatus = (status: any) => {
  const statusMap: Record<string, string> = {
    [CONSTANTS.ASSET_STATUS.CREATED]: 'Draft-Created',
    [CONSTANTS.ASSET_STATUS.DRAFT]: 'Draft',
    [CONSTANTS.ASSET_STATUS.DRAFT_COMPLETE]: 'Draft Complete',
    [CONSTANTS.ASSET_STATUS.DRAFT_UNDER_QC]: 'Draft Under QC',
    [CONSTANTS.ASSET_STATUS.DRAFT_READY_FOR_PUBLISHING]: 'Ready for Publishing',
    [CONSTANTS.ASSET_STATUS.SCHEDULE_PUBLISH]: 'Scheduled For Publish',
    [CONSTANTS.ASSET_STATUS.FAILED]: 'Failed',
    [CONSTANTS.ASSET_STATUS.PUBLISH_FAIL]: 'Publishing Failed',
    [CONSTANTS.ASSET_STATUS.PUBLISHED]: 'Published',
    [CONSTANTS.ASSET_STATUS.ARCHIEVED]: 'Archived',
    [CONSTANTS.ASSET_STATUS.DE_PUBLISH]: 'Depublished',
    [CONSTANTS.ASSET_STATUS.DEPUBLISHED]: 'De Publish',
    [CONSTANTS.ASSET_STATUS.RIGHTS_EXPIRED]: 'Rights Expired',
  }

  return statusMap[status] || status || ''
}

export const getAssetStatusBackgroundColor = (status: any) => {
  const colorMap: Record<string, string> = {
    [CONSTANTS.ASSET_STATUS.CREATED]: '#0073A1',
    [CONSTANTS.ASSET_STATUS.DRAFT]: '#9F760D',
    [CONSTANTS.ASSET_STATUS.DRAFT_COMPLETE]: '#5C4200',
    [CONSTANTS.ASSET_STATUS.DRAFT_UNDER_QC]: '#402080',
    [CONSTANTS.ASSET_STATUS.DRAFT_READY_FOR_PUBLISHING]: '#160B2D',
    [CONSTANTS.ASSET_STATUS.SCHEDULE_PUBLISH]: '#0073A1',
    [CONSTANTS.ASSET_STATUS.FAILED]: '#D4351C',
    [CONSTANTS.ASSET_STATUS.PUBLISH_FAIL]: '#D4351C',
    [CONSTANTS.ASSET_STATUS.PUBLISHED]: '#005A30',
    [CONSTANTS.ASSET_STATUS.ARCHIEVED]: '#D4351C',
    [CONSTANTS.ASSET_STATUS.DE_PUBLISH]: '#D4351C',
    [CONSTANTS.ASSET_STATUS.DEPUBLISHED]: '#D4351C',
    [CONSTANTS.ASSET_STATUS.RIGHTS_EXPIRED]: '#D4351C',
  }

  return colorMap[status] || '#E6F7FD'
}
export const stringToHexColor = (str: string) => {
  let hashCode = 0
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i)
    hashCode = (hashCode << 5) - hashCode + char
    hashCode &= hashCode
  }

  const colorCode = '#' + (hashCode & 0xffffff).toString(16).padStart(6, '0')

  return colorCode
}
export const getContentCategoryColor = (status: any) => {
  const colorMap: Record<string, string> = {
    ['TV_SHOW']: '#7CAB13',
    ['ORIGINALS']: '#F96E5A',
    ['MOVIES']: '#7557FB',
    ['NON_VOD_ASSETS']: '#FFB26A',
    ['SPORTS']: '#DE76C1',
    ['INDEPENDENT_VOD_ASSETS']: '#79DEFE',
  }

  return colorMap[status] || stringToHexColor(status)
}

export const getMetaCategoryName = (status: any) => {
  const statusMap: Record<string, string> = {
    ['TV_SHOW']: 'TV Shows',
    ['ORIGINALS']: 'Originals',
    ['MOVIES']: 'Movies',
    ['SPORTS']: 'Sports',
    ['INDEPENDENT_VOD_ASSETS']: 'Independent VOD Assets',
    ['NON_VOD_ASSETS']: 'Non VOD Assets',
  }

  return statusMap[status] || status || ''
}
export const getMetaCategoryNameHomepage = (status: any) => {
  const statusMap: Record<string, string> = {
    ['TV_SHOW']: 'TV Shows',
    ['ORIGINALS']: 'Originals',
    ['MOVIES']: 'Movies',
    ['SPORTS']: 'Sports',
    ['INDEPENDENT_VOD_ASSETS']: 'Independent VOD',
    ['NON_VOD_ASSETS']: 'Non VOD',
  }

  return statusMap[status] || status || ''
}
export const toMilliseconds = (hrs: number, min: number, sec: number) =>
  (hrs * 60 * 60 + min * 60 + sec) * 1000

export const setToken = (token: string) => {
  const decoded = jwtDecode(token)
  const { exp = 0 } = decoded || {}
  const expireTimeInMiliSec = exp * 1000
  const authConfig: any = {
    token: token,
    expireTime: expireTimeInMiliSec,
  }
  setLocalStorage(CONSTANTS.LOCAL_KEYS.AUTH_CONFIG, authConfig)
  store.dispatch(setAuthConfig(authConfig) as any)
  setRefreshTokenCallback()
}

export const setRefreshTokenCallback = () => {
  const token: { token: string; expireTime: number } = JSON.parse(
    localStorage.getItem(CONSTANTS.LOCAL_KEYS.AUTH_CONFIG) as string,
  )
  if (token?.expireTime) {
    const expireTimeInMiliSec = new Date(token?.expireTime).setMinutes(
      new Date(token.expireTime).getMinutes() - 5,
    )
    if (new Date(expireTimeInMiliSec) < new Date()) refreshToken()
  }
}

export const refreshToken = async () => {
  const { data = {} } = await mainApiService(AUTH_MANAGEMENT.REFRESH_TOKEN())
  const { token = '' } = data
  if (token) setToken(token)
}

export const getDateInFormat = (date: string, dFormat: string) => {
  if (date === 'NA') return 'NA'
  if (date) return format(new Date(date), dFormat)
  return ''
}

export const isAtLeastOneTrue = (arr: roleModulesModal[]) => {
  for (const module of arr) {
    for (const submodule of module.submodules) {
      const permissions = submodule.permission
      if (Object.values(permissions).some((value) => value === true)) {
        return true
      }
    }
  }
  return false
}

export const objectToQueryString = (obj: any): any => {
  const keys = Object.keys(obj)
  const keyValuePairs = keys.map((key) => {
    if (obj[key]) {
      return (key + '=' + obj[key]) as string | number | boolean
    }
  })

  const filteredArray = keyValuePairs.filter((element) => element !== undefined)
  return filteredArray.join('&')
}

export const getPageTitle = (id: string) => {
  const { VIEW, EDIT, CREATE } = CONSTANTS.PAGE_TYPE
  switch (id) {
    case VIEW as string:
      return VIEW
    case CREATE:
      return CREATE
    default:
      return EDIT
  }
}

export const getSubstring = (value: string, limit: number) => {
  return value?.length > limit ? `${value?.substring(0, limit)}...` : value
}

export const getStringLimit = (value: string, limit: number): string => {
  if (value?.length > limit) {
    return value
  } else return ''
}

export const combineErrorMessage = (unwrappableRes: any) => {
  if (Array.isArray(unwrappableRes?.error?.errors)) {
    if (unwrappableRes?.error?.errors?.length > 0) {
      return unwrappableRes?.error?.errors.join(',\n')
    }
    if (unwrappableRes?.error?.data?.length > 0) {
      return unwrappableRes?.error?.data.join(',\n')
    }
  }

  return unwrappableRes?.error?.message ?? unwrappableRes?.error
}

export const isValidObject = (obj: any) => {
  if (obj) {
    return Object.keys(obj).length > 0 ? true : false
  }
  return false
}

export const getValueFromObjArr = (obArr: any[], key: string) => {
  let value = ''
  if (obArr?.length > 0 && key) {
    obArr?.forEach((obj: any) => {
      if (isValidObject(obj) && obj?.key === key) {
        value = obj?.value
      }
    })
  }
  return value ? value : ''
}

export const createAttributePayload = (attributeFormData: any, type: string) => {
  const tmpArr: any[] = []
  attributeFormData?.attributeRef?.forEach((item: any, index: number) => {
    tmpArr.push({
      _id: item?._id,
      sortOrder: index + 1,
    })
  })

  let affiliatePartner = []
  if (attributeFormData?.affiliatePartner?.length > 0) {
    affiliatePartner = attributeFormData?.affiliatePartner.map((x: any) => x.key)
  }

  let masterValue
  if (!attributeFormData?.master) masterValue = undefined
  if (attributeFormData?.master === 'customOption') masterValue = ''
  if (attributeFormData?.master && attributeFormData?.master !== 'customOption')
    masterValue = attributeFormData?.master

  const defaultPayload = {
    fieldType: attributeFormData?.fieldType ? attributeFormData?.fieldType : undefined,
    dataType: attributeFormData?.dataType ? attributeFormData?.dataType : undefined,
    fieldName: attributeFormData?.fieldName ? attributeFormData?.fieldName?.trim() : undefined,
    isRequired: attributeFormData?.isRequired
      ? attributeFormData?.isRequired === 'Yes'
        ? true
        : false
      : undefined,
    isMultiple: attributeFormData?.isMultiple
      ? attributeFormData?.isMultiple === 'Yes'
        ? true
        : false
      : undefined,
    label: attributeFormData?.label ? attributeFormData?.label : undefined,
    isB2B: attributeFormData?.isB2B
      ? attributeFormData?.isB2B === 'Yes'
        ? true
        : false
      : undefined,
    defaultValue: attributeFormData?.defaultValue ? attributeFormData?.defaultValue : '',
    affiliatePartner: attributeFormData?.affiliatePartner?.length > 0 ? affiliatePartner : [],
    placeholder: attributeFormData?.placeholder ? attributeFormData?.placeholder : undefined,
    master: masterValue,
    options: attributeFormData?.dataType ? attributeFormData?.dataType : undefined,
    addMore: attributeFormData?.addMore
      ? attributeFormData?.addMore === 'Yes'
        ? true
        : false
      : undefined,
    status: attributeFormData?.status ? attributeFormData?.status : undefined,
    isMultilingual: attributeFormData?.isMultilingual
      ? attributeFormData?.isMultilingual === 'Yes'
        ? true
        : false
      : undefined,
    esField: attributeFormData?.esField ? attributeFormData?.esField : null,
    description: attributeFormData?.description ? attributeFormData?.description : undefined,
    additionalProperties: attributeFormData?.additionalProperties
      ? JSON.parse(attributeFormData?.additionalProperties)
      : null,

    attributeRef: tmpArr,
    metaCategory: 'NA',
    validations: {},
  }
  if (type === CONSTANTS.PAGE_TYPE.CREATE) {
    const reqData = {
      ...defaultPayload,
      options: attributeFormData?.options?.split(',') || [],
    }
    return reqData
  }
  if (type === CONSTANTS.PAGE_TYPE.EDIT) {
    let options = []
    if (Array.isArray(attributeFormData?.options)) {
      options = attributeFormData?.options?.toString()
      options = options?.split(',')
    } else {
      options = attributeFormData?.options?.split(',') || []
    }
    const reqData = {
      ...defaultPayload,
      options: options,
    }
    return reqData
  }
  return {}
}

export const removeDuplicateAttributes = (arr: any[]) => {
  const uniqueArr: any[] = []
  arr?.forEach((x: any) => {
    const index = uniqueArr.findIndex((y: any) => y?.fieldName === x?.fieldName)
    if (index < 0) {
      uniqueArr.push(x)
    }
  })
  return uniqueArr
}

export const isValidArray = (array: any[]) => {
  return typeof array === 'object' && typeof array !== 'string' && array?.length > 0 ? true : false
}

export const isValidJSON = (str: string) => {
  /*
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
  */
  try {
    const o = JSON.parse(str)
    if (o && typeof o === 'object') return true
    return false
  } catch (e) {
    return false
  }
}

// delete element from object if its null/undefined/empty
export const ValidateObject = (Obj: any) => {
  try {
    Object.keys(Obj).forEach((key) => {
      if (Obj[key] === null || Obj[key] === undefined || Obj[key] === '') {
        delete Obj[key]
      }
    })
    return Obj
  } catch (e) {
    return {}
  }
}

export const getSystemConfigurationMasterObject = (
  systemConfigurationPageType: string,
  systemConfigurationList?: any[],
) => {
  let result: any = {}
  systemConfigurationList?.forEach((sc: any) => {
    const { url = '', pageName = '', name = '' } = sc
    if (url && pageName && name) {
      const splittedURL = url?.split('/')
      const pageType = splittedURL[splittedURL?.length - 1]
      if (systemConfigurationPageType === pageType) {
        result = sc
      }
    }
  })
  return result
}

export const emptyFunction = () => {
  return
}

export const getMasterPayload = (masterKey: string, obj: any) => {
  const body = {
    key: masterKey,
    value: {
      key: obj?.key || '',
      value: obj?.value || '',
    },
  }
  return body
}

const replacer = (match: any, pIndent: any, pKey: any, pVal: any, pEnd: any) => {
  const key = '<span class=json-key>'
  const val = '<span class=json-value>'
  const str = '<span class=json-string>'
  let r = pIndent || ''
  if (pKey) r = r + key + pKey.replace(/[": ]/g, '') + '</span>: '
  if (pVal) r = r + (pVal[0] === '"' ? str : val) + pVal + '</span>'
  return r + (pEnd || '')
}

export const prettyPrint = (obj: any) => {
  const jsonLine = /^( *)("[\w]+": )?("[^"]*"|[\w.+-]*)?([,[{])?$/gm
  return JSON.stringify(obj, null, 3)
    .replace(/&/g, '&amp;')
    .replace(/\\"/g, '&quot;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(jsonLine, replacer)
}

export const updatingSortOrderValue = (array: any[]) => {
  const copyArr = JSON.parse(JSON.stringify(array))
  copyArr?.forEach((item: any, index: number) => {
    item.sortOrder = index
  })
  return copyArr
}

export const uploadInPresignedURL = async (
  presignedUrl: any,
  file: any,
  dispatch: any,
  uploadFileName: any,
) => {
  try {
    if (!presignedUrl) return ''
    if (!file) {
      dispatch(showAlert([true, 'Please select a file', 'error']))
      return
    }
    const response = await fetch(presignedUrl, {
      method: 'PUT',
      body: file,
    })
    if (response.ok) {
      return uploadFileName
    } else {
      dispatch(showAlert([true, 'Upload Failed', 'error']))
      return ''
    }
  } catch (error) {
    dispatch(showAlert([true, 'Upload Failed', 'error']))
    return ''
  }
}

export const uploadSubtitleToS3 = async (
  dispatch: any,
  uploadFileName: string,
  file: any,
  contentId: string,
) => {
  try {
    let reqUrl: any = ''
    const result: any = dispatch(
      generatePresignedUrlForSubtitle(`key=${uploadFileName}&contentId=${contentId}`) as any,
    )
    const unwrapRes = unwrapResult(await result)
    if (unwrapRes?.error) {
      dispatch(showAlert([true, combineErrorMessage(unwrapRes), 'error']))
      return reqUrl
    }
    const resUrl = unwrapRes?.data?.url || ''
    const fileName = await uploadInPresignedURL(resUrl, file, dispatch, uploadFileName)
    reqUrl = fileName ? `${CONSTANTS.S3_SUBTITLE_BUCKET_BASE_URL}/${fileName}` : ''
    return reqUrl
  } catch (error: any) {
    console.log('error', error)
    return ''
  }
}

export const downloadFile = async (dispatch: any, path: string, markAsDoneDetail: any) => {
  try {
    let id = markAsDoneDetail?.legacyHouseId
    if (!id) {
      id = markAsDoneDetail?.blitzId
    }
    const url = `${CONSTANTS.CLOUDFRONT_BASE_URL_SUBTITLE}/subtitles/${id}/${path}`
    const res: any = await fetch(url)
    const blob: any = await res.blob()

    if (blob) {
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = `${path}`
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  } catch (error: any) {
    dispatch(showAlert([true, `'Error downloading file:' ${error}`, 'error']))
  }
}
export const downloadBulkUpdateDataFunction = async (data: any, dispatch: any) => {
  try {
    const requestedData = BULK_UPDATE_APIS.DOWNLOAD_JOB_CSV(data._id)
    const result = await mainApiService(requestedData)
    if (result.responseCode === 200) {
      const url = result.data
      const res: any = await fetch(url)
      const blob: any = await res.blob()
      if (blob) {
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = `BulkUpdateReport_${data.jobName}.csv`
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
      dispatch(showAlert([true, 'Report downloaded successfully', 'success']))
    }
  } catch (error: any) {
    dispatch(showAlert([true, `'Error downloading file:' ${error}`, 'error']))
  }
}
export const checkModuleAccess = (role: string | undefined) => {
  let result = true
  if (role) {
    const roleData: any = getLocalStorage(CONSTANTS.LOCAL_KEYS.ROLE_DATA)
    for (let i = 0; i < roleData?.length; i++) {
      for (let j = 0; j < roleData[i]?.submodules?.length; j++) {
        if (roleData[i]?.submodules[j]?.key === role) {
          result = roleData[i]?.submodules[j]?.permission?.canAccess
          break
        }
      }
    }
  }
  return result
}
export const getCapitalise = (value: string) => {
  return value?.charAt(0)?.toUpperCase() + value?.replace('_', ' ')?.slice(1)?.toLowerCase()
}

export const fetchOptionsValue = (options: any, value: string) => {
  if (options && options.length) {
    const displayObj = options.find((item: any) => item.key === value)
    return displayObj?.value ?? ''
  }
  return ''
}

export const getValueBasedOnString = (
  value: any,
  fieldType: string,
  tagInput: boolean,
  dataType: string,
  element: {
    attribute?: {
      dataType?: string
      options?: {
        value: any
        label: string
      }[]
    }
  },
): string => {
  if (fieldType !== CONSTANTS.FIELDTYPES.FILE) {
    if ((typeof value !== 'boolean' && !value) || (Array.isArray(value) && value.length === 0)) {
      return 'NA'
    }
    if (
      tagInput ||
      (fieldType === CONSTANTS.FIELDTYPES.SELECT &&
        dataType === CONSTANTS.ASSET_CREATION_OBJ.BOOLEAN)
    ) {
      if (Array.isArray(value)) {
        return value.join(', ')
      } else {
        return typeof value === 'boolean' ? (value ? 'Yes' : 'No') : value
      }
    } else if (fieldType === CONSTANTS.FIELDTYPES.SELECT) {
      if (Array.isArray(value)) {
        return value
          .map((item: any) => fetchOptionsValue(element?.attribute?.options, item))
          .join(', ')
      } else {
        return fetchOptionsValue(element?.attribute?.options, value)
      }
    } else if (
      fieldType === CONSTANTS.FIELDTYPES.DATE ||
      fieldType === CONSTANTS.FIELDTYPES.DATETIME ||
      fieldType === CONSTANTS.FIELDTYPES.TIME
    ) {
      if (dataType === CONSTANTS.FIELDTYPES.NUMBER) {
        return secondsToHMS(value)
      }
      return getDateInFormat(value ?? '', CONSTANTS.DATE_FORMAT_TIME)
    } else if (
      fieldType === CONSTANTS.FIELDTYPES.CHECKBOX ||
      fieldType === CONSTANTS.FIELDTYPES.RADIO ||
      fieldType === CONSTANTS.FIELDTYPES.SWITCH
    ) {
      return value ? 'Yes' : 'No'
    } else {
      return value
    }
  }
  return ''
}

export const subStringWithEllipses = (value: string, count = 50, elipses = true): string => {
  if (value.length <= count) {
    return value
  }
  const truncatedString = value.substring(0, count)
  return elipses ? `${truncatedString}...` : truncatedString
}
// Utilized it if you need temporary unique id
export const uniqueId = () => {
  return Math.random() + getTime(new Date())
}

export const replaceUnwanted = (data: string) => {
  return data?.replace(/[^A-Z0-9]+/gi, '_')?.toLowerCase()
}
export const filterObject = <T extends Record<string, string | number>>(obj: T): T => {
  const result: Partial<T> = {}
  for (const key in obj) {
    if (
      obj[key] !== 0 &&
      obj[key] !== '' &&
      key !== 'filterCondition' &&
      key !== 'filterValue' &&
      key !== 'filterBy'
    ) {
      result[key] = obj[key]
    }
  }

  return result as T
}
export const getCurrentStatus = (status: string) => {
  switch (status) {
    case 'PREVIEW_INITIATED':
      return 'Preview request in-progress'
    case 'TRANSCODING_INITIATED':
      return 'Transcoding in-progress'
    case 'VERIFICATION_INITIATED':
      return 'Fetching in-progress'
    default:
      return ''
  }
}

export const getSuccessStatus = (state: string) => {
  switch (state) {
    case 'validation':
      return 'Fetching Successful'
    case 'transcoding':
      return 'Transcoding Success'
    case 'preview':
      return 'Preview Request Successful'
    default:
      return `${state} is Successfully Done`
  }
}

export const changeToCapitalCase = (value: string) => {
  if (value) {
    return value.charAt(0).toUpperCase() + value.slice(1)
  }
}

/**
 * Function to return parsed data of JWT token in the form of object.
 * @function getParsedToken
 * @returns {object} JWT parsed object which is parsed from token stored in localstorage
 */
export const getParsedToken = () => {
  const authConfig: { token: string } = getLocalStorage('auth_config')
  if (authConfig?.token) {
    const parsedToken = jwtDecode(authConfig.token)
    return parsedToken
  }
}

export const logoutCyberArk = (message = '') => {
  if (message) {
    setLocalStorage(CONSTANTS.LOCAL_KEYS.LOGOUT_MESSAGE, { message })
  }
  const authToken: any = getLocalStorage(CONSTANTS.LOCAL_KEYS.AUTH_CONFIG)
  if (authToken && authToken.token) {
    const parsedToken: { sub: { accessToken: string } } = jwtDecode(authToken.token)
    const origin = process.env.REACT_APP_URL
    if (parsedToken?.sub?.accessToken) {
      const redirectUrl = `${CONSTANTS.SESSION_END_URL}?id_token_hint=${parsedToken?.sub
        ?.accessToken}&post_logout_redirect_uri=${`${origin}/login`}`
      window.location.href = redirectUrl
    }
  } else {
    window.location.href = PAGES_ROUTES.LoginPage
  }
}

export const logout = async () => {
  const { responseCode, message, error = {} } = await mainApiService(AUTH_MANAGEMENT.LOGOUT())
  if (responseCode === 200) {
    logoutCyberArk(message)
    return
  }
  if (error) store.dispatch(showAlert([true, error?.message, 'error']))
}
export const getSelectedAttributeDetails = (selectedAttri: any, attributes: any) => {
  const matchingObjects = []

  for (const selectedObj of selectedAttri) {
    const matchingDataObject = attributes.find((ele: any) => ele._id === selectedObj._id)

    if (matchingDataObject) {
      matchingObjects.push(matchingDataObject)
    }
  }

  return matchingObjects
}

export const AVS_MESSAGES: any = {
  SUCCESS: 'Success',
  FAILED: 'Failed',
  AVS_INGESTION: 'AVS Ingestion',
  AVS_PUBLISH: 'AVS Publishing',
  AVS_DEPUBLISH: 'AVS De-Publish',
}

export const getColor = (status: string) => {
  return status === 'SUCCESS' ? '#4C6A0B' : status === 'FAILED' ? '#D4351C' : ''
}

export const getBGColor = (status: string) => {
  return status === 'SUCCESS' ? '#F4F9EA' : status === 'FAILED' ? '#FFDBD5' : ''
}

export const getStatusMessage = (avsStatus: string, avsAction: string) => {
  const statusMessage = AVS_MESSAGES[avsStatus] || ''
  const actionMessage = AVS_MESSAGES[avsAction] || ''
  return actionMessage && statusMessage ? `${actionMessage}: ${statusMessage}` : ''
}

export const validValueOrEmptyString = (value: any) => {
  if (!value) {
    return ''
  }
  return value
}

/**
 * To get the uniqe array obj removes deuplicate value based on keys
 * @function removeDuplicates
 * @param arr Array of object from which duplicate value need to be removed
 * @param key the key of by which we need to remove duplicate data from array
 * @returns {array} returns unique array of object based on key.
 */
export function removeDuplicates(arr: TypeObject[], key: string) {
  const seenAssetTypes = new Set()
  const uniqueObjects: TypeObject[] = []

  arr?.forEach((obj: TypeObject) => {
    const keyObj = obj[key]
    if (!seenAssetTypes.has(keyObj)) {
      seenAssetTypes.add(keyObj)
      uniqueObjects.push(obj)
    }
  })

  return uniqueObjects
}
export const nullValuesPresent = (data: any) => {
  return Object?.values(data)?.every((value) => value === null)
}
export const getInheritanceRuleName = (inheritanceRule: string, inheritanceData: any) => {
  return (
    (Array.isArray(inheritanceData) ? inheritanceData : [])?.find(
      (item: any) => item?.key === inheritanceRule,
    )?.value || 'NA'
  )
}
export const getAttrDefValidationName = (validationNameKey: string, validationsList: any) => {
  return (
    (Array.isArray(validationsList) ? validationsList : [])?.find(
      (item: any) => item?.functionName === validationNameKey,
    )?.displayName || 'NA'
  )
}
export const getAttrDefDataSourceName = (dataSourceKey: string, dataSourceList: any) => {
  return (
    (Array.isArray(dataSourceList) ? dataSourceList : [])?.find(
      (item: any) => item?.key === dataSourceKey,
    )?.value || 'NA'
  )
}
export const getAttrDefScopeName = (scopeValueKey: string, scopesList: any) => {
  return (
    (Array.isArray(scopesList) ? scopesList : [])?.find((item: any) => item?.key === scopeValueKey)
      ?.value || 'NA'
  )
}
export const getAttrDefSourceAttributeName = (sourceAttributeKey: string, attributesList: any) => {
  return (
    (Array.isArray(attributesList) ? attributesList : [])?.find(
      (item: any) => item?.fieldName === sourceAttributeKey,
    )?.label || 'NA'
  )
}
export const staticCheckToHideAsterisk = (element: any) => {
  return element?.fieldName === 'class' && element?.fieldType === 'SELECT'
}
export const getfilterby = (tab: any, assetCategory: any, contentCategoryOrder: any) => {
  if (assetCategory !== 'CONTENT_ITEM') {
    if (contentCategoryOrder.indexOf(tab) < contentCategoryOrder.indexOf(assetCategory)) {
      return true
    } else {
      return false
    }
  } else {
    return true
  }
}
export const groupAndSortBasedOnBlitzId = (data: Record<string, any>[]) => {
  // Step 1: Group by blitzId
  const grouped = data.reduce((acc: any, item: any) => {
    if (!acc[item.blitzId]) {
      acc[item.blitzId] = []
    }
    acc[item.blitzId].push(item)
    return acc
  }, {})

  // Define the order of statuses
  const statusOrder = ['DRAFT', 'PUBLISHED', 'SCHEDULE PUBLISHED']

  // Function to get the order index
  const getStatusOrderIndex = (status: string) => {
    const index = statusOrder.indexOf(status)
    return index === -1 ? statusOrder.length : index
  }

  // Step 2: Sort each group by status order
  for (const blitzId in grouped) {
    grouped[blitzId].sort((a: any, b: any) => {
      return getStatusOrderIndex(a.status) - getStatusOrderIndex(b.status)
    })
  }

  // Convert the grouped object back to an array of grouped arrays
  const groupedArray = Object.entries(grouped).map(([blitzId, items]) => ({
    blitzId: Number(blitzId),
    items: items,
  }))

  return groupedArray
}

export const normalizeCuepoints = (jsonObj: any) => {
  for (const cuepoint of jsonObj?.cuePointList || []) {
    cuepoint.cuepointStartTime = parseInt(cuepoint.cuepointStartTime)
    cuepoint.cuepointEndTime = parseInt(cuepoint.cuepointEndTime)
  }
  return jsonObj
}

export const getDataBasedOnkey = (arr: Record<string, any>[], key: string, fetchValue: any) => {
  const data = new Map(arr?.map((item: Record<string, any>) => [item.key, item]))
  const dataObj = data.get(fetchValue)
  return dataObj?.value ?? ''
}

export const validImageFormat = (file: File) => {
  if (file) {
    const acceptedFormats = ['image/png', 'image/jpeg', 'image/jpg', 'image/webp']
    return acceptedFormats.includes(file.type)
  } else {
    return false
  }
}

export const getValueByKey = (keyName: string, arrayOfObjects: any[]) => {
  const keyValueMap = new Map(arrayOfObjects.map((obj: any) => [obj?.key, obj?.value]))
  return keyValueMap.get(keyName)
}

/**
 * Deep comparison of two values.
 * @param {any} val1 - First value to compare.
 * @param {any} val2 - Second value to compare.
 * @returns {boolean} - True if values are deeply equal, false otherwise.
 */
export const deepCompare = (val1: any, val2: any) => {
  // Check if both values are the same object or primitive value
  if (val1 === val2) {
    return true
  }

  // Check if either of the values is null or not an object/array
  if (val1 === null || val2 === null || typeof val1 !== 'object' || typeof val2 !== 'object') {
    return false
  }

  // Handle arrays
  if (Array.isArray(val1) && Array.isArray(val2)) {
    if (val1.length !== val2.length) {
      return false
    }

    for (let i = 0; i < val1.length; i++) {
      if (!deepCompare(val1[i], val2[i])) {
        return false
      }
    }

    return true
  }

  // Handle objects
  if (
    Object.prototype.toString.call(val1) === '[object Object]' &&
    Object.prototype.toString.call(val2) === '[object Object]'
  ) {
    const keys1 = Object.keys(val1)
    const keys2 = Object.keys(val2)

    if (keys1.length !== keys2.length) {
      return false
    }

    for (const key of keys1) {
      if (!keys2.includes(key) || !deepCompare(val1[key], val2[key])) {
        return false
      }
    }

    return true
  }

  return false
}

export const getDetailedErrorMessage = (jsonString: string, error: { message: string }) => {
  const lines = jsonString.split('\n')
  const positionMatch: any = error.message.match(/position (\d+)/)

  if (positionMatch) {
    const position = parseInt(positionMatch[1], 10)
    let lineNumber = 0
    let charCount = 0
    const lineLengthArr = []
    // let count = 0
    for (let i = 0; i < lines.length; i++) {
      const lineLength = lines[i].length + 1
      lineLengthArr.push(lines[i].length)
      // count += lines[i].length
      const charValue = charCount + lineLength
      if (charValue > position) {
        lineNumber = i + 1
        break
      }

      charCount += lineLength
    }

    const nearbyCode = jsonString.substring(position - 10, position + 10)
    if (
      nearbyCode.includes(',') &&
      nearbyCode.includes('}') &&
      !error.message.includes('non-whitespace')
    ) {
      return `Parse error on line ${lineNumber - 1}: Misplaced comma near line ${
        lineNumber - 1
      } in JSON at position ${charCount - 2} (line ${lineNumber - 1} column ${
        lines[lineNumber - 2].length
      })`
    }

    if (
      nearbyCode.includes(',') &&
      nearbyCode.includes('{') &&
      !error.message.includes('non-whitespace')
    ) {
      return `Parse error on line ${
        lineNumber - 1
      }: Misplaced comma near line ${lineNumber} in JSON at position ${
        positionMatch[1]
      } (line ${lineNumber} column ${lines[lineNumber - 1].length})`
    }

    return `Parse error on line ${lineNumber}: ${error.message}`
  } else if (!error.message.includes('position') && error.message.includes('Unexpected token')) {
    const undefinedPosition: any = []
    const errorIndex: any = []
    let positionCount = 0
    const lineBreakPosition = []
    let errorLineNumber = 0
    let errorLinerPosition = 0
    for (let i = 0; i < lines.length; i++) {
      const lineLength = lines[i]?.length + 1
      const elements = lines[i]?.split(' ')
      positionCount = positionCount + lines[i].length
      lineBreakPosition?.push(lineLength)
      for (const ele of elements) {
        const trimmedElement = `'${ele.trim()}'`
        if (trimmedElement === error.message?.split('Unexpected token')[1]?.split(',')[0]?.trim()) {
          errorIndex.push(lines[i].indexOf(ele.charAt(0)))
          undefinedPosition?.push(ele)
          errorLineNumber = lineBreakPosition.length - 1
          errorLinerPosition = lineBreakPosition[lineBreakPosition.length - 1]
          break
        }
      }
    }
    return `${error.message} at position ${positionCount} (line ${errorLineNumber} column ${errorLinerPosition})`
  }
  return error.message
}

export const getRouteFromLocalStorage = (returnSingle = false, contentId = '') => {
  const paths: any = localStorage.getItem('pathHistory')
  if (paths) {
    const parsePaths = JSON.parse(paths)
    if (returnSingle) {
      const lastPathFromArray = contentId
        ? parsePaths.find((item: string) => item.includes(contentId))
        : parsePaths[parsePaths.length - 1]

      parsePaths.pop()
      localStorage.setItem('pathHistory', JSON.stringify(parsePaths))
      return lastPathFromArray
    }
    if (parsePaths?.length) {
      return parsePaths
    }
  }

  return returnSingle ? false : []
}

export const setRouteToLocalStorage = (path: string) => {
  let paths = getRouteFromLocalStorage(false)
  const pathIdx = paths.findIndex((item: string) => path === item)
  if (pathIdx !== -1) {
    paths.splice(pathIdx + 1, paths.length - 1)
    localStorage.setItem('pathHistory', JSON.stringify(paths))
    return
  }
  paths = [...paths, path]
  localStorage.setItem('pathHistory', JSON.stringify(paths))
}
export const removeFilterKey = (currentTab?: string) => {
  return currentTab === 'Parent' ? 'blitzId' : currentTab === 'Child' ? 'parentId' : ''
}

export const cascadeKeyValueForLabel = (records: []) => {
  if (records?.length) {
    const result = records?.map((item: any) => {
      return {
        _id: item?._id || '',
        key: item?.key || '',
        value: `${item?.key} ${item?.value}` || '',
      }
    })
    return result
  }
  return []
}

export const saveTransactionIDInStorage = (contentId: string, metaCategory: string): string => {
  const timestamp = Date.now()
  const codedContentId = contentId.slice(contentId.length - 5)
  const metaCategoryInitial = metaCategory
    ?.split('_')
    ?.map((item) => item?.slice(0, 1)?.toLowerCase())
    ?.join('')

  const transactionId = `${metaCategoryInitial}-${codedContentId}-${timestamp}`
  localStorage.setItem(CONSTANTS.LOCAL_KEYS.TRANSACTION_ID, transactionId)

  return transactionId
}

export const getStatusText = (status: any) => {
  return status === 'Active' ? 'ACTIVE' : status === 'In Active' ? 'INACTIVE' : status
}

export const getValueObjbyKeyfromArr = (keyName: string, keyValue: string, array: any[]) => {
  const valueObj = array?.find((element: any) => element[keyName] === keyValue)
  return valueObj || {}
}

export const findScrollableParent = (element: HTMLElement | null): HTMLElement | Window => {
  if (!element) return window

  const isScrollable = (ele: HTMLElement) => {
    const hasScrollableContent = ele.scrollHeight > ele.clientHeight
    const overflowYStyle = window.getComputedStyle(ele).overflowY
    const isOverflowHidden = overflowYStyle.indexOf('hidden') !== -1

    return hasScrollableContent && !isOverflowHidden
  }

  if (isScrollable(element)) {
    return element
  }

  return findScrollableParent(element.parentElement) || window
}

export const checkErrorData = (ev: any) => {
  const regex = /[eE+-.]/
  if (regex.test(ev)) {
    return true
  } else {
    return false
  }
}
export const getStickyIndex = (sticky?: 'R' | 'L', headers?: any[]) => {
  return sticky === 'L' ? 0 : sticky === 'R' ? headers?.length && headers?.length - 1 : ''
}

export const convertBooleanIntoYesAndNo = (value: boolean | undefined): string =>
  value ? 'Yes' : 'No'

export const getStatusBoxClass = (status: string) => {
  switch (status) {
    case 'SUCCESS':
      return 'avs-success-color'
    case 'FAILED':
      return 'avs-failed-color'
    default:
      return ''
  }
}

export const getStatusLabelClass = (status: string) => {
  switch (status) {
    case 'SUCCESS':
      return 'avs-success-label-color'
    case 'FAILED':
      return 'avs-failed-label-color'
    default:
      return ''
  }
}

export const truncatedValueByLength = (value: string | null | undefined, count = 40): string => {
  if (!value) return ''

  const shouldTruncate = value.length >= count
  const truncatedValue = value.slice(0, count)

  return `${truncatedValue}${shouldTruncate ? '...' : ''}`
}

export const sortByLocaleCompare = (
  array: { [key: string]: any }[] | null | undefined,
  key: string,
) => {
  if (!Array.isArray(array)) return []

  return array.sort((a, b) => {
    const aValue = a?.[key]
    const bValue = b?.[key]

    if (typeof aValue === 'string' && typeof bValue === 'string') {
      return aValue.localeCompare(bValue)
    } else if (aValue === null || aValue === undefined) {
      return 1 // Null/undefined values should appear last
    } else if (bValue === null || bValue === undefined) {
      return -1 // Null/undefined values should appear last
    } else if (typeof aValue === 'string') {
      return -1 // Strings should come before non-strings
    } else if (typeof bValue === 'string') {
      return 1 // Strings should come before non-strings
    } else {
      return 0 // Non-string, non-null values are considered equal
    }
  })
}

export const stringifyIfNotNull = (data: any): string => {
  const jsonString = JSON.stringify(data)
  return jsonString === 'null' ? '' : jsonString
}

export const getStatusType = (attribute: any): string => {
  const status = attribute?.plainStatus ?? attribute?.status
  return status === 'ACTIVE' ? 'success' : 'secondary'
}

export const getStatusLabelOrValue = (attribute: any): string => {
  const status = attribute?.plainStatus ?? attribute?.status
  return status === 'ACTIVE' ? 'Active' : CONSTANTS.InActiveText
}

export const deepClone = <T>(obj: T): T => {
  if (obj === null || typeof obj !== 'object') {
    return obj
  }

  if (Array.isArray(obj)) {
    const arrCopy = [] as any[]
    for (let i = 0; i < obj.length; i++) {
      arrCopy[i] = deepClone(obj[i])
    }
    return arrCopy as any as T
  }

  const objCopy = {} as { [key: string]: any }
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      objCopy[key] = deepClone(obj[key])
    }
  }
  return objCopy as T
}

export const scrollMetaCategoriesInAssetCreation = () => {
  const element = document.getElementById('menu-metadata-categories')
  if (element) {
    const positionFromTop = element.getBoundingClientRect().top
    if (positionFromTop < 67) {
      let scrollHeight = 180
      if (document.getElementById('create-asset-header-details')) {
        scrollHeight = 220
      }
      const scrollableParent = findScrollableParent(element)
      if (scrollableParent) {
        scrollableParent.scroll({ top: scrollHeight, behavior: 'smooth' })
      }
    }
  }
}
export const getsortedHeader = (headers: any) => {
  const headersCopy = JSON.parse(JSON.stringify(headers))
  const seletedColTemp: any = []
  const unSelectedColTemp: any = []
  headersCopy.forEach((ele: any) => {
    if (ele.viewInListing) {
      seletedColTemp.push(ele)
    } else {
      unSelectedColTemp.push(ele)
    }
  })
  return [
    ...seletedColTemp,
    ...unSelectedColTemp.sort((first: any, second: any) => first.label.localeCompare(second.label)),
  ]
}
export const getCancelJobHeading = (status: string) => {
  const statusMessages: Record<string, string> = {
    IN_PROGRESS: 'Cancel Job?',
    IN_QUEUE: 'Cancel In Queued Job?',
    SCHEDULED: 'Cancel Scheduled Job?',
  }

  return statusMessages[status] || ''
}
export const updateAudioLanguage = (assetGroupDetails: any, language: any, atIndex: any) => {
  const copyArr = JSON.parse(JSON.stringify(assetGroupDetails?.audio))
  copyArr.forEach((item: any, index: number) => {
    if (index === atIndex) {
      item.language = language
    }
  })
  return copyArr || []
}

/**
 * getPublishingStatus to determine the publishing status.
 *
 * @param {string | undefined} id - The identifier to compare.
 * @param {object} asset - The asset object which may contain the isStandAlonePublishing property.
 * @returns {string} - Returns 'TRUE' or 'FALSE' based on the conditions.
 */
export const getPublishingStatus = (id: string | undefined, asset: any): string => {
  const { CREATE } = CONSTANTS.PAGE_TYPE
  if (id !== CREATE) {
    return asset?.isStandAlonePublishing ? 'TRUE' : 'FALSE'
  }
  return 'FALSE'
}
export const getMultiSelectValue = (value: any, selected: boolean, isMultiple?: boolean) => {
  if (isMultiple) {
    return value.split(',').length > CONSTANTS.MAX_CLIP_LINE_COUNTS.MAX_ITEMS && selected
      ? `${value?.split(',')?.slice(0, CONSTANTS.MAX_CLIP_LINE_COUNTS.MAX_ITEMS)?.join(', ')}...`
      : value
  } else {
    return value
  }
}
export const uniqueSelectedColumn = (getSelectedColumn: any) => {
  const uniqueSelectedColumn = getSelectedColumn?.reduce((acc: any[], current: any) => {
    const matchingObj = acc?.find((item) => item.id === current.id)
    if (!matchingObj) {
      return acc.concat([current])
    } else {
      return acc
    }
  }, [])
  return uniqueSelectedColumn
}
export const getOrderColumn = (data: any, order: any) => {
  if ([undefined, null].includes(data[0])) {
    return []
  } else {
    const correctData = data.map((item: any) => {
      if (order?.includes(item.id)) {
        return { ...item, viewInListing: true }
      } else {
        return { ...item, viewInListing: false }
      }
    })
    const sortedData = order?.length ? [...correctData] : [...data]
    const imageItem = sortedData?.filter((item: any) => item?.id === 'image')
    const restOfItems = sortedData?.filter((item: any) => item?.id !== 'image')
    const getSelectedColumn = restOfItems?.map((item: any) =>
      order?.includes(item.id) && !item.viewInListing ? { ...item, viewInListing: true } : item,
    )
    const getselectedColumnCopy = order?.length ? getSelectedColumn : restOfItems
    const sortedColumnData = getselectedColumnCopy
      ?.filter((item: any) => item?.viewInListing)
      .sort((a: any, b: any) => {
        const indexA = order.indexOf(a.id)
        const indexB = order.indexOf(b.id)
        if (indexA !== -1 && indexB !== -1) {
          return indexA - indexB
        }
        if (indexA !== -1) {
          return -1
        }
        if (indexB !== -1) {
          return 1
        }
        return a.position - b.position
      })
    const unSelectedColumn = restOfItems?.filter((item: any) => !item.viewInListing)
    let allColumns = []
    if (imageItem.length) {
      allColumns = [imageItem[0], ...sortedColumnData, ...unSelectedColumn]
    } else {
      allColumns = [...sortedColumnData, ...unSelectedColumn]
    }
    const finalColHeader = uniqueSelectedColumn(allColumns)
    return finalColHeader
  }
}
export const getRearrangeData = (ev: any, headersData: any) => {
  const { source, destination } = ev
  if (!destination) return
  const sourceIndex = source.index
  const destinationIndex = destination.index
  let copyData = JSON.parse(JSON.stringify(headersData))
  if (isValidArray(headersData)) {
    const [element] = copyData.splice(sourceIndex, 1)
    copyData.splice(destinationIndex, 0, element)
    copyData = updatingSortOrderValue(copyData)
    return copyData
  }
}
export const checkOptionSelected = (
  multiple: boolean,
  options: any,
  value: any,
  option: any,
  labelKey: string,
  selected: boolean,
) => {
  if (multiple && options?.length === value?.length && option?.[labelKey] === 'Select All') {
    return true
  }
  return selected
}

export const getPartnerViewDeatils = (partnerData: any, listOptions: any) => {
  const partnerFields = JSON.parse(JSON.stringify(PARTNER_CONSTENT.initialField)) as Array<any>
  const partnerComplexFields = partnerFields.find(
    (item: any) => item.fieldName === 'allowedAssetTypeConfigurations',
  )
  const allowedAssetTypeConfigurationsColumns: any = []
  const allowedAssetTypeConfigurationsRows: any = []
  if (partnerComplexFields?.fieldConfig) {
    partnerComplexFields.fieldConfig.forEach((subAttr: any) => {
      allowedAssetTypeConfigurationsColumns.push({
        id: subAttr.fieldName,
        label: subAttr.label,
        sort: false,
        fieldType: subAttr.fieldType,
        isMultiple: subAttr?.isMultiple,
      })
    })

    if (partnerData?.allowedAssetTypeConfigurations?.length) {
      partnerData.allowedAssetTypeConfigurations.forEach((values: any) => {
        const row: any = {}
        partnerComplexFields.fieldConfig.forEach((subAttr: any) => {
          row[subAttr.fieldName] = {
            ...subAttr,
            id: subAttr.fieldName,
            attribute: {
              ...subAttr,
              value: values[subAttr.fieldName] ?? '',
              options: listOptions[subAttr.formFieldsOptionsKey] || [],
            },
          }
        })
        allowedAssetTypeConfigurationsRows.push(row)
      })
    }
  }
  return [
    { label: 'Partner Name', value: partnerData?.serviceName, isTitle: true },
    { label: 'Partner Alias', value: partnerData?.serviceAlias, isTitle: false },
    { label: 'Email ID', value: partnerData?.contactEmail, isTitle: false },
    { label: 'Contact Number', value: partnerData?.contactPhone, isTitle: false },
    { label: 'Status', value: partnerData?.status, isTitle: false },
    {
      label: 'Contract Start Date',
      value: partnerData.contractStartDate
        ? format(new Date(partnerData.contractStartDate), CONSTANTS.DATE_FORMAT_TIME)
        : 'NA',
      isTitle: false,
    },
    {
      label: 'Contract End Date',
      value: partnerData.contractEndDate
        ? format(new Date(partnerData.contractEndDate), CONSTANTS.DATE_FORMAT_TIME)
        : 'NA',
      isTitle: false,
    },
    {
      label: 'Allowed Masters',
      value: partnerData?.allowedMasters?.length
        ? partnerData?.allowedMasters.map((item: any, ind: number) =>
            partnerData?.allowedMasters?.length - 1 === ind ? item : `${item}, `,
          )
        : 'NA',
    },
    {
      viewType: 'complex',
      label: 'Allowed Asset Type',
      columns: allowedAssetTypeConfigurationsColumns,
      tableRows: allowedAssetTypeConfigurationsRows,
    },
  ]
}
