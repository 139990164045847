import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PAGES_ROUTES } from '../../Constants'
import { MASTERS_API, SYSTEM_CONFIG_APIS } from '../../Constants/ApiDefinations'
import {
  SystemConfigurationsAPIDefinitionModal,
  SystemConfigurationsListResponse,
} from '../../Models'
import mainApiService from '../../Services'
import { setLoader } from './loader.slice'

interface SystemConfigurationsState {
  systemConfigurationData: SystemConfigurationsListResponse | any
  systemConfigurationMasterList?: any[]
}

const initialState: SystemConfigurationsState = {
  systemConfigurationData: {},
  systemConfigurationMasterList: [],
}

const systemConfigurationsSlice = createSlice({
  name: 'systemConfigurations',
  initialState,
  reducers: {
    setSystemConfigurationsData: (state, action: PayloadAction<{ key: string; data: any }>) => {
      const { data, key } = action.payload
      state.systemConfigurationData =
        key && data ? { ...state.systemConfigurationData, [key]: data } : {}
    },
    setMastersList: (state, action: PayloadAction<any>) => {
      state.systemConfigurationMasterList = action.payload
    },
  },
})

export const { setSystemConfigurationsData, setMastersList } = systemConfigurationsSlice.actions

export const getSystemConfigurationsMastersData: any = createAsyncThunk(
  'systemConfigurations/getSystemConfigurationsMastersData',
  async (key: string, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: SystemConfigurationsAPIDefinitionModal =
      SYSTEM_CONFIG_APIS.LIST_MASTERS(key)
    const data = await mainApiService(requestedData)
    dispatch(setSystemConfigurationsData({ key, data }))
    dispatch(setLoader(false))
    return data
  },
)

export const createSystemConfigurationMaster = createAsyncThunk(
  'systemConfigurations/create',
  async (payload: any, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: any = MASTERS_API.CREATE_MASTERS(payload)
    const data = await mainApiService(requestedData)
    dispatch(setLoader(false))
    return data
  },
)

export const getSystemConfigurationMaster = createAsyncThunk(
  'systemConfigurations/get',
  async (_payload: any, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: any = MASTERS_API.LIST_MASTERS_CONFIGURATION()
    const data = await mainApiService(requestedData)
    const tempData: any[] = []
    data?.data?.forEach((ele: any) => {
      tempData.push({
        _id: ele?.configKey,
        name: ele?.name,
        key: ele?.configKey,
        url: `/${PAGES_ROUTES.MetaMaster}/${PAGES_ROUTES.Masters}/${ele.configKey.toLowerCase()}`,
      })
    })
    dispatch(setMastersList(tempData))
    dispatch(setLoader(false))
    return data
  },
)

export const getSystemConfigurationListByMasterKey = createAsyncThunk(
  'getSystemConfigurationListByMasterKey/get',
  async (payload: any, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: any = SYSTEM_CONFIG_APIS.LIST_MASTERS(payload)
    const data = await mainApiService(requestedData)
    dispatch(setLoader(false))
    return data
  },
)

const systemConfigurationsSelector = (state: any) => state.systemConfigurations

export const selectSystemConfigurationData = createSelector(
  systemConfigurationsSelector,
  (state) => state.systemConfigurationData,
)

export const selectSystemConfigurationMasterList = createSelector(
  systemConfigurationsSelector,
  (state) => state.systemConfigurationMasterList,
)

export const selectSystemConfigurationMastersList = createSelector(
  systemConfigurationsSelector,
  (state) => state.systemConfigurationMastersList,
)

export default systemConfigurationsSlice
