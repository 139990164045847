import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import {
  ATTRIBUTE_REPOSITORY_CRUD,
  CONTENT_CRUD,
  MASTERS_MANAGEMENT,
} from '../../Constants/ApiDefinations'
import { AttributeViewResponseModal } from '../../Constants/Constant/AttributeConstant'
import { attributeStatusChangeDefinitionModal, roleCRUDAPIDefinitionModal } from '../../Models'
import {
  assetCloningApiModal,
  assetLinkApiModal,
  attributeCRUDAPIDefinitionModal,
  linkpayload,
} from '../../Models/AttributeModal'
import mainApiService from '../../Services'
import { setLoader } from './loader.slice'

interface AttributeInitialType {
  attribute?: AttributeViewResponseModal | null
  attributeData?: any
  nonComplexTypeAttributes?: any
  fieldTypes?: any
  affiliatePartners?: any
}
const initialState: AttributeInitialType = {
  attribute: null,
  attributeData: null,
  nonComplexTypeAttributes: [],
  fieldTypes: [],
  affiliatePartners: [],
}
const attributeSlice = createSlice({
  name: 'attribute',
  initialState,
  reducers: {
    viewAttribute: (state, action) => {
      state.attribute = action.payload
    },
    setAttributeData: (state, action) => {
      const { data = {} } = action.payload
      state.attributeData = data
    },
    setNonComplexTypeAttributes: (state, action) => {
      const { data = {} } = action.payload
      const { records = [] } = data
      state.nonComplexTypeAttributes = records
    },
    setFieldTypes: (state, action) => {
      const { data = [] } = action.payload
      state.fieldTypes = data
    },
    setAffiliatePartners: (state, action) => {
      const { data = {} } = action.payload
      const { records = [] } = data
      state.affiliatePartners = records?.map((item: any) => ({
        key: item?.key,
        value: item?.value,
      }))
    },
    clearAttributeData: (state) => {
      state.attributeData = {}
    },
    clearAttributeRepoStore: (state: any) => {
      state.attribute = null
      state.attributeData = null
      state.nonComplexTypeAttributes = []
      state.fieldTypes = []
      state.affiliatePartners = []
    },
  },
})

export const {
  viewAttribute,
  setAttributeData,
  setNonComplexTypeAttributes,
  setFieldTypes,
  clearAttributeData,
  setAffiliatePartners,
  clearAttributeRepoStore,
} = attributeSlice.actions

export const viewAttributeService = createAsyncThunk(
  'attribute/viewAttribute',
  async (id: string, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: any = ATTRIBUTE_REPOSITORY_CRUD.VIEW_ATTRIBUTE_REPO(id)
    const data = await mainApiService(requestedData)
    dispatch(viewAttribute(data.data))
    dispatch(setLoader(false))
  },
)
export const changeAttributeStatus = createAsyncThunk(
  'role/changeStatus',
  async (data: { attributeId: string; status: string }, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: attributeStatusChangeDefinitionModal =
      ATTRIBUTE_REPOSITORY_CRUD.CHANGE_STATUS(data)
    const result = await mainApiService(requestedData)
    dispatch(setLoader(false))

    return result
  },
)
export const deleteAttribute = createAsyncThunk(
  'attribute/delete',
  async (id: string, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: roleCRUDAPIDefinitionModal = ATTRIBUTE_REPOSITORY_CRUD.DELETE_ATTRIBUTE(id)
    const data = await mainApiService(requestedData)
    dispatch(setLoader(false))

    return data
  },
)
const attributeSelector = (state: { attribute: AttributeInitialType }) => state.attribute

export const createAttributeService = createAsyncThunk(
  'attribute/create',
  async (payload: any, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: attributeCRUDAPIDefinitionModal =
      ATTRIBUTE_REPOSITORY_CRUD.CREATE_ATTRIBUTES(payload)
    const data = await mainApiService(requestedData)
    dispatch(setLoader(false))
    return data
  },
)
export const editAttributeService = createAsyncThunk(
  'role/editRole',
  async (payload: any, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: attributeCRUDAPIDefinitionModal =
      ATTRIBUTE_REPOSITORY_CRUD.UPDATE_ATTRIBUTES(payload)
    const data = await mainApiService(requestedData)
    dispatch(setLoader(false))
    return data
  },
)
export const cloneAssetService = createAsyncThunk(
  'asset/assetCloning',
  async (data: { contentId: string; copies: number }, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: assetCloningApiModal = CONTENT_CRUD.CLONE_ASSET(data)
    const result = await mainApiService(requestedData)
    dispatch(setLoader(false))

    return result
  },
)
export const linkAssetService = createAsyncThunk(
  'asset/assetLinking',
  async (payload: linkpayload, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: assetLinkApiModal = CONTENT_CRUD.LINK_ASSET(payload)
    const result = await mainApiService(requestedData)
    dispatch(setLoader(false))

    return result
  },
)
export const unlinkAssetService = createAsyncThunk(
  'asset/assetUnlinking',
  async (payload: linkpayload, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: assetLinkApiModal = CONTENT_CRUD.UNLINK_ASSET(payload)
    const result = await mainApiService(requestedData)
    dispatch(setLoader(false))

    return result
  },
)
export const getFieldTypes = createAsyncThunk(
  'attribute/fieldType',
  async (payload, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: any = ATTRIBUTE_REPOSITORY_CRUD.FIELD_TYPE()
    const data = await mainApiService(requestedData)
    dispatch(setFieldTypes(data))
    dispatch(setLoader(false))
    return data
  },
)

export const getNonComplexTypeAttributes = createAsyncThunk(
  'attribute/complexType',
  async (paramsData: string, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: any = ATTRIBUTE_REPOSITORY_CRUD.LIST_ATTRIBUTE_REPO(paramsData)
    const data = await mainApiService(requestedData)
    dispatch(setNonComplexTypeAttributes(data))
    dispatch(setLoader(false))
    return data
  },
)

export const getAttributeService = createAsyncThunk(
  'attribute/viewAttribute',
  async (id: string, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: any = ATTRIBUTE_REPOSITORY_CRUD.VIEW_ATTRIBUTE_REPO(id)
    const data = await mainApiService(requestedData)
    dispatch(setAttributeData(data))
    dispatch(setLoader(false))
    return data
  },
)

export const getAffiliatePartners = createAsyncThunk(
  'attribute/getAffiliatePartners',
  async (payload, { dispatch }) => {
    dispatch(setLoader(true))
    const requestedData: any =
      MASTERS_MANAGEMENT.AFFILIATE_PARTNERS_CRUD.LIST_AFFILIATE_PARTNERS('page=1&limit=100000')
    const data = await mainApiService(requestedData)
    dispatch(setAffiliatePartners(data))
    dispatch(setLoader(false))
    return data
  },
)

export const selectAttribute = createSelector(attributeSelector, (state) => state.attribute)
export const selectEditAttributeData = createSelector(
  attributeSelector,
  (state) => state.attributeData,
)
export const selectNonComplexTypeAttributesList = createSelector(
  attributeSelector,
  (state) => state.nonComplexTypeAttributes,
)
export const selectFieldTypesList = createSelector(attributeSelector, (state) => state.fieldTypes)
export const selectaffiliatePartnersList = createSelector(
  attributeSelector,
  (state) => state.affiliatePartners,
)

export default attributeSlice
