import { Stack, styled } from '@mui/material'
import { useDrawingArea } from '@mui/x-charts'
import { PieChart } from '@mui/x-charts/PieChart'
import { useEffect, useState } from 'react'
import { getContentCategoryColor, getMetaCategoryNameHomepage } from '../../Helpers/Util'
import { TypeObject, pageOption2 } from '../../Models'
import { ContentCategorySvg } from '../Icons/ContentCategorySVG'
import { MUIMultiselectAsset } from '../Shared'

/**
 * ContentCategory Component
 *
 * Renders Content Category Component.
 * @param metaCategoryList getting list of meta category
 *
 * @private homepageData getting list of data that user can see in the form of Pie chart
 *
 * @returns {React.JSX.Element | null} JSX Element representing the ContentCategory component.
 */

interface HomepageProps {
  metaCategoryList?: pageOption2[]
  homepageData?: TypeObject[]
}

const StyledText = styled('text')(({ theme }: any) => ({
  fill: theme.palette.text.primary,
  textAnchor: 'middle',
  dominantBaseline: 'central',
  fontSize: 20,
}))
const PieCenterLabel = ({ children }: { children: React.ReactNode }) => {
  const { width, height, left, top } = useDrawingArea()
  return (
    <StyledText x={left + width / 1.72} y={top + height / 2.2} className='paragraph-bold-7'>
      {children}
    </StyledText>
  )
}

const ContentCategory: React.FC<HomepageProps> = ({
  metaCategoryList = [],
  homepageData,
}: HomepageProps) => {
  const [selectedFilter, setselectedFilter] = useState({ newValue: [] as any[] })
  const [contentCategoryData, setcontentCategoryData] = useState<any>([])
  const [totalCount, settotalCount] = useState(0)

  useEffect(() => {
    const homepageDataCopy: any = []
    let count = 0
    homepageData?.forEach((ele: any) => {
      const dataItem: any = {
        id: ele.ContentCategory,
        value: ele.TotalCategoryCount,
        label: getMetaCategoryNameHomepage(ele.ContentCategory),
        color: getContentCategoryColor(ele?.ContentCategory),
      }
      const filteredArray = selectedFilter?.newValue.filter(
        (obj) => obj.value === ele?.ContentCategory,
      )
      if (filteredArray?.length) {
        homepageDataCopy.push(dataItem)
        count += ele.TotalCategoryCount
      } else if (!selectedFilter?.newValue?.length) {
        count += ele.TotalCategoryCount
        homepageDataCopy.push(dataItem)
      }
    })
    settotalCount(count)
    setcontentCategoryData(homepageDataCopy)
  }, [homepageData, selectedFilter])
  const customTooltip = (tootipArgs: any) => {
    const { itemData, series } = tootipArgs
    const details = series.data[itemData?.dataIndex]
    if (details?.label) {
      return (
        <Stack
          className='graph-tooltip'
          sx={{
            background: '#2E353B',
            font: 'Lato',
            fontWeight: 500,
            fontSize: '13px',
            lineHeight: '15.6px',
            color: '#fff',
            opacity: '90%',
            borderRadius: '10px',
          }}
          marginLeft={3}
          marginTop={-5}
          direction='column'
          gap={2}
          padding={2}
          minWidth={'100px'}
          minHeight={'56px'}
        >
          <Stack className='graph-tooltip-label'>{details?.label}</Stack>
          <Stack className='graph-tooltip-value'>{details?.value}</Stack>
        </Stack>
      )
    }
    return null
  }

  return (
    <div className='content-category-wrapper'>
      <div className='d-flex justify-content-between position-relative'>
        <h3>
          <ContentCategorySvg />
          Content Categories
        </h3>
        <div className='d-flex category-dropdown'>
          <label>Content Categories:</label>
          <MUIMultiselectAsset
            name={'content'}
            multiple={true}
            label={''}
            options={metaCategoryList}
            onInputChange={(ev: any, newValue: any) => {
              setselectedFilter((pre: any) => ({ ...pre, newValue }))
            }}
            id={'content'}
            value={selectedFilter?.newValue}
            labelKey={'key'}
            valueKey='value'
            small
            chipProps={{ sx: { backgroundColor: 'transparent', padding: '0px' } }}
          />
        </div>
      </div>
      <PieChart
        series={[
          {
            data: contentCategoryData,
            highlightScope: { highlighted: 'item' },
            highlighted: {
              innerRadius: 91,
              outerRadius: 143,
            },
            innerRadius: 89,
            outerRadius: 139,
            paddingAngle: 5,
            cornerRadius: 5,
            cy: 180,
            cx: '58%',
          },
        ]}
        height={400}
        slotProps={{
          legend: {
            hidden: true,
            direction: 'row',
            position: { vertical: 'bottom', horizontal: 'middle' },
            padding: 20,
            itemGap: 30,
            markGap: 20,
          },
        }}
        tooltip={{
          slots: {
            itemContent: customTooltip,
          },
          trigger: 'item',
        }}
      >
        <PieCenterLabel>{totalCount}</PieCenterLabel>
      </PieChart>
      <ul className='category-list'>
        {contentCategoryData?.map((item: any, ind: number) => {
          return (
            <li key={item.label}>
              <span style={{ backgroundColor: item?.color }}></span>
              {item.label}
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default ContentCategory
