import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import CONSTANTS from '../../../Constants'
import { PARTNER_APIS } from '../../../Constants/ApiDefinations'
import {
  getAssetTypeKeyValue,
  getMasterDropdown,
  getPartnerStatus,
} from '../../../Helpers/InboundPartnerHelper'
import { RoleConfirmationModal } from '../../../Models'
import mainApiService from '../../../Services'
import { useAppDispatch } from '../../../Store/Hooks'
import { showAlert } from '../../../Store/Slice/alert.slice'
import { setLoader } from '../../../Store/Slice/loader.slice'
import DetailViewRender from '../../DetailViewRender'
import RoleConfirmation from '../../Dialogs/RoleConfirmation'
import { PARTNER_CONSTENT } from './PartnersConstent'
interface PartnerInformationModal {
  view?: any
  viewData?: any[]
  getPartnerInformation: () => void
  listOptions?: any
}
const PartnersInformation: React.FC<PartnerInformationModal> = ({
  view,
  viewData,
  getPartnerInformation,
  listOptions,
}: PartnerInformationModal) => {
  const [dialogProps, setDialogProps] = React.useState<RoleConfirmationModal>({
    heading: '',
    description: '',
    color: 'primary',
    open: false,
  })
  const [partnersFormFields, setPartnersFormFields] = useState<any>(PARTNER_CONSTENT.initialField)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (view?._id) {
      const updatedFields = partnersFormFields?.map((field: any) => {
        if (field.fieldType === 'SELECT' && field?.fieldName === 'status') {
          return { ...field, value: getPartnerStatus(view[field.fieldName]) }
        } else if (field.fieldName === 'allowedMasters') {
          return {
            ...field,
            value: getMasterDropdown(view.allowedMasters || [], listOptions.masterList),
          }
        } else if (field.fieldName === 'allowedAssetTypes') {
          return {
            ...field,
            value: getAssetTypeKeyValue(view?.allowedAssetTypes || [], listOptions?.assetTypeList),
          }
        } else {
          return { ...field, value: view[field.fieldName] }
        }
      })
      setPartnersFormFields(updatedFields)
    }
  }, [view, listOptions])
  const handleClose = () => {
    setDialogProps({
      heading: '',
      description: '',
      color: 'primary',
      open: false,
    })
  }

  const handleStatusChange = async (type: string) => {
    dispatch(setLoader(true))
    const payload = {
      partnerId: view?._id,
      status: type,
    }
    const requestedData: any = PARTNER_APIS.PARTNER_STATUS(payload as any)
    const result = await mainApiService(requestedData)
    dispatch(setLoader(false))

    if (result?.error || !result) {
      handleClose()
      dispatch(showAlert([true, result.error?.message, 'error']))
      return
    }
    dispatch(showAlert([true, result.message, 'success']))
    getPartnerInformation()
    navigate('/partners/view/' + view?._id)
    handleClose()
  }
  const openSecretDialog = async () => {
    setDialogProps({
      heading: CONSTANTS.PARTNER_REGENERATE_SECRET,
      description: (
        <div>
          <p>Are you sure you want to re-generate secret?</p>
        </div>
      ),
      color: 'primary',
      Icon: 'DialogEditIcon',
      open: true,
      iconColor: 'success',
      successButtonLabel: 'Regenerate',
      cancelButtonLabel: 'Cancel',
      handleSuccess: () => secretButtonFunction(),
    })
  }
  const openDialog = (type: string) => {
    if (type === 'status-active') {
      setDialogProps({
        heading: CONSTANTS.PARTNER_STATUS_ACTIVE_TITLE,
        description: (
          <div>
            <p>
              Are you sure you want to activate the previously deactivated
              <strong className='col36'> {view?.serviceName}</strong>?
            </p>
          </div>
        ),
        color: 'primary',
        Icon: 'ActivateUserIcon',
        open: true,
        iconColor: 'success',
        successButtonLabel: 'Activate',
        cancelButtonLabel: 'Cancel',
        handleSuccess: () => handleStatusChange('ACTIVE'),
      })
    }
    if (type === 'status-inactive') {
      setDialogProps({
        heading: CONSTANTS.PARTNER_STATUS_INACTIVE_TITLE,
        description: (
          <div>
            <p>
              Are you sure you want to deactivate
              <strong className='col36'> {view?.serviceName}</strong>?
            </p>
          </div>
        ),
        color: 'error',
        Icon: 'DeactivateUserIcon',
        open: true,
        iconColor: 'error',
        successButtonLabel: 'Deactivate',
        cancelButtonLabel: 'Cancel',
        handleSuccess: () => handleStatusChange('INACTIVE'),
      })
    }
  }

  const secretButtonFunction = async () => {
    const payload = partnersFormFields.reduce(
      (acc: any, field: any) => {
        acc[field?.fieldName] =
          field.fieldType === 'SELECT' && !field.isMultiple
            ? field.value.key
            : field.fieldType === 'SELECT' && field.isMultiple
            ? field.value.map((ele: any) => ele.value)
            : field.value
        return acc
      },
      { partnerId: view?._id, resendEmail: true },
    )
    dispatch(setLoader(true))
    if (payload.contactPhone === '') {
      payload.contactPhone = null
    }
    const requestedData: any = PARTNER_APIS.EDIT_PARTNER(payload)
    const result = await mainApiService(requestedData)
    dispatch(setLoader(false))
    if (result?.responseCode === 200) {
      dispatch(showAlert([true, 'Secret sent to your email successfully!', 'success']))
      handleClose()
    } else {
      handleClose()
      dispatch(showAlert([true, result?.error?.errors || result?.error?.message, 'error']))
    }
  }
  return (
    <>
      <DetailViewRender
        showSecret={true}
        showStatus={true}
        pageName='Partner Information'
        view={view}
        editButtonRedirectionPath={`/partners/edit/${view._id}`}
        openDialog={openDialog}
        viewData={viewData}
        secretButtonFunction={openSecretDialog}
      />
      <RoleConfirmation {...dialogProps} handleClose={handleClose} />
    </>
  )
}

export default PartnersInformation
