import { getDateInFormat } from '../../Helpers/Util'

export const BLITZ_ID = 'Blitz ID'
export const VIDEO_SUBTITLES = 'Video Subtitles'

export const CONSTANTS = {
  PREVIE_IMAGE_GENERATION_DEBOUNCE_TIME: 3000,
  INCORRECT_URL_FORMAT_MESSAGE: 'Incorrect URL format',
  S3_URL_REQUIRED_MESSAGE: 'S3 url is required',
  ERROR_TEXT: 'Error',
  CUE_POINT_LIST: 'cuePointList',
  WARNING_TEXT: 'Warning',
  URL_VALIDATION_REGEX: new RegExp(process.env.REACT_APP_CLOUDFRONT_BASE_URL || '', 'g') as any,
  IMAGE_CONTENT_TYPE_ERROR_MESSAGES: {
    ASPECT_RATIO_ERROR_MESSAGE: 'Incorrect Aspect Ratio',
    IMAGE_RESOLUTION_WARNING_MESSAGE: 'Image resolution doesn`t match',
    IMAGE_RESOLUTION_ERROR_MESSAGE: 'Resolution limit exceeded',
    IMAGE_RATIO_AND_RESOLUTION_ERROR_MESSAGE: 'Aspect Ratio and Image resolution, limit exceeded',
  },
  MEDIA_IMAGE: {
    CONTENT_MEDIA_IMAGE_VARIANT_TEXT: 'CONTENT_MEDIA_IMAGE_VARIANT',
    ALL_COUNTRIES_TEXT: 'All Countries',
    ALL_COUNTRIES_VALUE: ['All'],
    URL_IMAGE_TYPE: 'URL',
    UPLOAD_IMAGE_TYPE: 'UPLOAD',
    VALID_IMAGE_FILENAME_REGEX: /^([a-zA-Z0-9\s_\\.\-:])+(.jpg|.jpeg|.png|.gif|.bmp)$/,
  },
  JSON_EDITOR_FIELDS: {
    countryRating: 'countryRating',
    countryPreview: 'countryPreview',
    isAutoPlayTrailer: 'isAutoPlayTrailer',
  },
  DATE_TIME_FORMAT_VIEWS: ['year', 'month', 'day', 'hours', 'minutes'],
  TIME_FORMAT_VIEWS: ['hours', 'minutes'],
  YEAR_FORMAT: ['year'],
  MODIFIED_DATA_HEADING: 'Modified Data',
  PRIMARY_IMAGE_TITLE_TEXT: 'Primary',
  VARIANT_IMAGE_TITLE_TEXT: 'Variant',
  S3_BUCKET_BASE_URL: process.env.REACT_APP_S3_BUCKET_BASE_URL as string,
  S3_BUCKET_IMAGE_BASE_FOLDER: process.env.REACT_APP_S3_BUCKET_BASE_FOLDER as string,
  S3_SUBTITLE_BUCKET_BASE_URL: process.env.REACT_APP_S3_SUBTITLE_BUCKET_BASE_URL as string,
  CLOUDFRONT_BASE_URL: process.env.REACT_APP_CLOUDFRONT_BASE_URL as string,

  CLOUDFRONT_BASE_URL_SUBTITLE: process.env.REACT_APP_CLOUDFRONT_BASE_URL_SUBTITLE as string,

  STANDALONE_PUBLISHING_TEXT: 'Standalone Publishing',
  ASSET_TYPE_TITLE_TEXT: 'Asset Type Title',
  BASE_URL: process.env.REACT_APP_API_BASE_URL as string,
  SESSION_END_URL: process.env.REACT_APP_CYBERARK_CLIENT_SESSION_END_URL as string,
  LOCAL_KEYS: {
    AUTH_CONFIG: 'auth_config',
    USER_DATA: 'user_data',
    ROLE_DATA: 'role_data',
    LOGOUT_MESSAGE: 'logout_message',
    TRANSACTION_ID: 'transaction_id',
    LISTING_ORDER: 'listing_order',
  },
  QUERY_CONFIG: {
    refetchOnMountOrArgChange: true,
    skip: false,
  },
  API_INTERVAL_TEN: 10000,
  ROLE_STATUS: [
    { value: 'ACTIVE', label: 'Active', type: 'status-active' },
    { value: 'INACTIVE', label: 'In Active', type: 'status-inactive' },
  ],
  FILTER_LIST: [
    { label: 'Is equal to', value: 'equals' },
    { label: 'Is not equal to', value: 'notEquals' },
    { label: 'Starts With', value: 'startsWith' },
    { label: 'Ends With', value: 'endsWith' },
    { label: 'Contains', value: 'contains' },
  ],
  FILTER_LIST_ADVANCED_SEARCH: [
    { label: 'Is', value: 'equals' },
    { label: 'Is Not', value: 'notEquals' },
    { label: 'Greater Than', value: 'greaterThan' },
    { label: 'Less Than', value: 'lessThan' },
  ],
  DAY_WEEK_FILTER: [
    { label: 'Next(Day)', value: 'nextDay' },
    { label: 'Next(Week)', value: 'nextWeek' },
  ],

  STRING_FILTER: [
    { label: 'Is equal to', value: 'equals' },
    { label: 'Is not equal to', value: 'notEquals' },
    { label: 'Starts With', value: 'startsWith' },
    { label: 'Ends With', value: 'endsWith' },
    { label: 'Contains', value: 'contains' },
    { label: 'In', value: 'in' },
  ],
  BULK_IMAGE_TYPE: [{ label: 'Primary Image Attribute', value: 'primary_image_atttribute' }],
  NUMBER_DATE_FILTER: [
    { label: 'Is equal to', value: 'equals' },
    { label: 'Is not equal to', value: 'notEquals' },
    { label: 'Greater Than', value: 'greaterThan' },
    { label: 'Greater Than Equal', value: 'greaterThanEquals' },
    { label: 'Less Than', value: 'lessThan' },
    { label: 'Less Than Equals', value: 'lessThanEquals' },
  ],
  BOOLEAN_FILTER: [
    { label: 'Is equal to', value: 'equals' },
    { label: 'Is not equal to', value: 'notEquals' },
  ],
  ARRAY_FILTER: [
    { label: 'Is equal to', value: 'equals' },
    { label: 'In', value: 'in' },
  ],
  VIDEO_SECTION_TIPS: [
    'Be Cautious, when you select "Skip Validation," you\'re granting users the ability to override system validations.',
    'To add an existing group, remove newly created group first. Then, you can choose an existing group.',
  ],
  CREATE_ASSET_DEFINITION_TIPS: [
    'Be Cautious, when you select "Skip Validation," you\'re granting users the ability to override system validations.',
  ],
  CREATE_ROLE_TIPS: [
    'Be Cautious, when you select "Skip Validation," you\'re granting users the ability to override system validations.',
    'Role names should be distinct and not match any existing role names.',
    'By default, roles are set to "Active." You can modify the status in the "Edit Role" section.',
    'You can make changes to role settings and configurations later.',
  ],
  CREATE_GEO_POLICY_TIPS: [
    'Be Cautious, when you select Skip Validation, you are granting users the ability to override system validations.',
    'Role names should be distinct and not match any existing role names.',
    'By default, roles are set to "Active." You can modify the status in the "Edit Role" section.',
    'You can make changes to role settings and configurations later.',
  ],
  NO_TIPS: ['No tips added'],
  PLACEHOLDER_TIPS: [
    'Refer attribute definition section to understand the mapping of the attribute with different asset types. ',
  ],
  VERSION_ARCIVED_HEADING: 'Version Archived',
  VERSION_ARCHIVED_DESCRIPTION:
    'We are redirecting you to the latest archived version of this asset as the content version your are trying to reach is archived',
  CREATE_USER_TIPS: ['Each user can be assigned only one role at a time.'],
  STATUS_ACTIVE_TITLE: 'Activate Role',
  STATUS_INACTIVE_TITLE: 'Deactivate Role',
  CHANGE_ATTRIBUTE_STATUS: 'Change attribute Status',
  UNSAVE_CHANGES: 'Unsaved Changes',
  UNSAVED_CHANGES_DESCRIPTION:
    'Your changes have not been saved. Make sure to save your data to prevent any loss of entered information.',
  UNSAVED_CHANGES_DESCRIPTION_BULK_IMAGES:
    'Your changes have not been saved. Make sure to save your data to prevent any loss of selected images.',
  STATUS_ACTIVE_CONTENT:
    'Are you sure you want to activate the previously deactivated role  “Content Creator”? This will restore the roles permissions and access.',
  STATUS_INACTIVE_CONTENT:
    'Are you sure you want to deactivate the role “Content Creator”? This will  suspend the roles permissions and access. You can reactivate it later.',

  USER_STATUS_ACTIVE_TITLE: 'Activate User',
  USER_STATUS_INACTIVE_TITLE: 'Deactivate User',

  USER_STATUS_ACTIVE_CONTENT: 'Are you sure you want to activate this User?',
  USER_STATUS_INACTIVE_CONTENT:
    'Are you sure you want to activate this User? All permission associated will be deleted.',
  PARTNER_TIPS: ['Message 1'],
  PARTNER_STATUS_ACTIVE_TITLE: 'Activate Partner',
  PARTNER_REGENERATE_SECRET: 'Re-generate Secret',
  PARTNER_STATUS_INACTIVE_TITLE: 'Deactivate Partner',

  ASSET_DEFINITION_ACTIVE_TITLE: 'Activate Asset Type',
  SAVE_SEARCH_TITLE: 'Save & Pin Search',
  ASSET_DEFINITION_INACTIVE_TITLE: 'Deactivate Asset Type',
  VALIDATION_FAILURE: 'Validation failure',
  VALIDATION_FAILURE_DESCRIPTION: `You're being redirected to the meta-category edit section due to validation errors. Please resolve these before proceeding. Click 'Okay' to continue. ${''}`,
  DELETE_ASSET: 'Delete Asset',
  DELETE_MEDIAINFO_URL: 'Delete URL',
  CHANGES_MADE: 'Changes Made',
  CHANGES_MADE_DESCRIPTION:
    'Changes have been made to this meta-category by some other user, please reload the page to view the changes',
  UNSAVED_CHANGES: 'Unsaved Changes',
  UNSAVED_CHANGES_MESSAGE:
    'Your changes have not been saved. Make sure to save your data to prevent any loss of entered information.',
  DELETE_ASSET_DESCRIPTION: 'Are you sure you want to delete this asset version?',
  DELETE_MEDIAINFO_URL_DESCRIPTION: 'Are you sure you want to delete this URL?',
  ASSET_DEFINITION_ACTIVE_CONTENT: 'Are you sure you want to activate this asset type?',
  ASSET_DEFINITION_INACTIVE_CONTENT: 'Are you sure you want to deactivate this asset type?',

  DELETE_ROLE_TITLE: 'Delete Role',

  DELETE_ROLE_CONTENT:
    'Are you sure you want to delete the role “Content Creator”? This action cannot be undone and will also affect the permissions of associated users.',
  CLONE_ASSET: 'Clone Asset',
  CLONE_ASSET_DESC: 'Enter the number of clones you want to create for this asset',
  CLONED_ASSET: 'Cloned Asset',
  CLONE_ASSET_SUCCESS:
    'The asset has been successfully cloned. Below are the links to the cloned assets',
  CLONE_ASSET_SUCCESS2: 'List of Cloned Assets',
  DEPUBLISH_CHILD_ASSET_SUCCESS: 'List of Child Assets',
  ROLE_EDITOR_MODULE_KEYS: {
    BLITZ_MODULE: 'BLITZ_MODULE',
    META_CATEGORIES: 'META_CATEGORIES',
    TOLLGATES: 'TOLLGATES',
    META_MASTER: 'META_MASTER',
  },
  HTTP_METHOD: {
    GET: 'get',
    POST: 'post',
    PUT: 'put',
    PATCH: 'patch',
    DELETE: 'delete',
  },
  DEFAULT_LANGUAGE_WARNING:
    // eslint-disable-next-line quotes
    "To enable 'Fetch & validate', choose the 'Default language' in Descriptive metadata and the language for the selected file. Ensure the selected language for the files, matches the default language.",
  USER_ROLE_DEACTIVATE_MSG: `This role is inactive. Please go to Role Management section to activate this
                    role. You can still assign users to an inactive role.`,
  ROLE_LISTING_COLUMNS_NAME: [
    { id: 'roleName', label: 'Role Name', sort: true },
    { id: 'associatedUsers', label: 'Associated Users', sort: true },
    { id: 'createdAt', label: 'Role Creation Date', sort: true },
    { id: 'updatedAt', label: 'Last Updated Date', sort: true },
    { id: 'status', label: 'Status', sort: true },
  ],
  USER_LISTING_COLUMNS: [
    { id: 'userName', label: 'User Name', sort: true },
    { id: 'cyberarkId', label: 'CyberArk Id', sort: true },
    { id: 'userEmail', label: 'Email Id', sort: true },
    { id: 'roleName', label: 'Role Name ', sort: true },
    { id: 'createdAt', label: 'User Creation Date', sort: true },
    { id: 'status', label: 'User Status', sort: true },
  ],
  GEO_POLICY_LISTING_COLUMN: [
    { id: 'policyName', label: 'Policy Name', sort: true },
    { id: 'timeDifference', label: 'Time Difference', sort: true },
    { id: 'countries', label: 'Countries', sort: false },
    { id: 'days', label: 'Day', sort: false },
    { id: 'description', label: 'Description', sort: true },
    { id: 'status', label: 'Status', sort: true },
  ],
  ASSOCIATED_ASSETS_LISTING_COLUMN: [
    { id: 'image', label: 'Image', sort: false },
    { id: 'assetTitle', label: 'Asset Title', sort: true },
    { id: 'state', label: 'Asset State', sort: true },
    { id: 'assetCategory', label: 'Asset Category', sort: true },
    { id: 'contentId', label: 'Asset ID', sort: true },
    { id: 'createdAt', label: 'Creation Date', sort: true },
  ],

  GEO_POLICY_LISTING_RESPONSE: [
    {
      _id: '656d7cb7c737d64b63b1b715',
      policyName: 'Geo_1',
      description: 'Geo policy description ',
      timeDifference: 7200,
      countries: ['nepal', 'bangladesh'],
      days: ['fri', 'sat'],
      createdBy: '654dca4be34dab5b60111783',
      updatedBy: '654dca4be34dab5b60111783',

      createdAt: '2023-12-04T07:16:07.612Z',
      updatedAt: '2023-12-04T07:16:07.612Z',
      status: 'Active',
    },
  ],
  ROLE_USER_LISTING_COLUMNS: [
    //  { id: 'roleName', label: 'Role Name ', sort: true },
    { id: 'userName', label: 'User Name', sort: true },
    { id: 'createdAt', label: 'User Creation Date', sort: true },
    { id: 'userEmail', label: 'Email Id', sort: true },
    { id: 'status', label: 'User Status', sort: true },
  ],
  SAVE_SEARCH_LISTING_COLUMNS: [
    { id: 'name', label: 'Search Name', sort: true },
    { id: 'isPinnedToDashboard', label: 'Pinned', sort: true },
    { id: 'action', label: 'Action', sort: false },
  ],
  MEDIA_INFO_COLUMN: [
    { id: 'action', label: 'Actions', sort: false },
    { id: 'videoUrl', label: 'URL Field', sort: false },
    { id: 'audioLanguages', label: 'Audio Language', sort: false },
    { id: 'platform', label: 'Platform', sort: false },
    { id: 'videoType', label: 'Video Type', sort: false },
    { id: 'status', label: 'Status', sort: false },
    { id: 'fps', label: 'FPS', sort: false },
    { id: 'encodingProfile', label: 'Encoding Profile', sort: false },
    { id: 'audioCodec', label: 'Audio Codec', sort: false },
    { id: 'videoCodec', label: 'Video Codec', sort: false },
    { id: 'drmId', label: 'DRMID', sort: false },
    { id: 'daiAssetKey', label: 'DAI asset key', sort: false },
    { id: 'isSource', label: 'Is Source', sort: false },
    { id: 'additionalDataJson', label: 'Additional Data', sort: false },
  ],
  BLITZ_ERROR_CODE_BLOCK_DELETE_USER: 'BLITZ_02013',
  BLITZ_DUPLICATE_GEOPOLICY_ERROR_CODE: 'BLITZ_13012',
  BLITZ_ORCHESTRATION_EXISTING_ASSET_SAVE_WARNING: 'BLITZ_20014',
  BLITZ_ERROR_CODE_BLOCK_DELETE_ATTRIBUTE: 'BLITZ_04011',
  BLITZ_ERROR_CODE_VALIDATION_ASSET_CREATE: 'BLITZ_01002',
  BLITZ_ERROR_CODE_VALIDATION_ASSET_MARK_AS_DONE: 'BLITZ_14021',
  BLITZ_ERROR_CODE_VALIDATION_VIDEO: 'BLITZ_14009',
  BLITZ_ERROR_CODE_PARENTID_ATTRIBUTE_MISSING: 'BLITZ_14051',
  BLITZ_JOB_EXIST_ERROR_CODE: 'BLITZ_21002',

  PAGE_TYPE: {
    CREATE: 'create',
    VIEW: 'view',
    EDIT: 'edit',
    UPDATE: 'update',
    VIEW_ONLY: 'view-only',
  },
  ATTRIBUTE_STATUS_ACTIVE_TITLE: 'Activate Attribute',
  GEO_POLICY_STATUS_ACTIVE_TITLE: 'Activate Geo Policy',
  ATTEIBUTE_DELETE_BLOCK_MESSAGE: 'Invalid Attribute Or Attribute Does Not Exists in Our System',
  ATTRIBUTE_STATUS_DEACTIVE_TITLE: 'Deactivate Attribute',
  GEO_POLICY_STATUS_DEACTIVE_TITLE: 'Deactivate Geo Policy',
  STATUS_ACTIVE_ATTRIBUTE_DESCRIPTION:
    'Are you sure you want to activate the previously deactivated attribute',
  STATUS_ACTIVE_GEO_POLICY_DESCRIPTION:
    'Are you sure you want to activate the previously deactivated Geo Policy',
  STATUS_DEACTIVE_ATTRIBUTE_DESCRIPTION: 'Are you sure you want to deactivate this attribute ',
  STATUS_DEACTIVE_GEO_POLICY_DESCRIPTION: 'Are you sure you want to deactivate the Geo Policy',
  DELETE_ATTRIBUTE_TITLE: 'Delete Attribute',
  DELETE_ATTRIBUTE_DESCRIPTION1: 'Are you sure you want to delete ',
  DELETE_ATTRIBUTE_DESCRIPTION2: '? As this attribute may be mapped to existing assets',
  DELETE_GEO_POLICY_DESCRIPTION1: 'Are you sure you want to delete ',
  DELETE_GEO_POLICY_DESCRIPTION2: '? As this Geo Policy may be mapped to existing assets',
  DISABLED_PERMISSION_ROLE_MODULES: ['PUBLISHING', 'RIGHTS_EXPIRED', 'DEPUBLISH'],
  DELETE_GEO_POLICY_TITLE: 'Delete Geo Policy',
  BULK_UPDATE_CONFIRM_DIALOG_HEAIDNG: 'Confirm Bulk Update',
  BULK_UPDATE_CANCELLED_JOB:
    'Job was canceled before any progress was made, so no reports were generated.',
  BULK_UPDATE_CONFIRM_DIALOG_DESC:
    'Clicking okay will trigger the bulk update process and apply the changes to relevant records. Please ensure your data is correct before proceeding.',
  BULK_UPDATE_CONFIRM_DIALOG_SCHEDULE: 'Confirm Bulk Update Schedule',
  BULK_UPDATE_CONFIRM_DIALOG_SCHEDULE_DESC: (date: any) =>
    `Clicking okay will trigger the bulk update process and apply the changes to relevant records at <b style="color: #3E3F40">${getDateInFormat(
      date,
      'dd/MM/yyyy HH:mm',
    )}</b>. Please ensure your data is correct before proceeding.`,
  UPLOAD_FAILED_HEADING: 'File Upload Failed',
  UPLOAD_FAILED_FILE_SIZE_DESC: (size: number) =>
    `File size limit exceeded the required limit of ${+size / 1024}MB.`,
  UPLOAD_FAILED_CONTENT_DESC: (batchContent: number) =>
    `The number of rows exceeded the required limit of ${batchContent} rows`,
  MESSAGES: {
    CSV_MAX_CONTENT_ALLOWED: (contentLength: number) =>
      'Allowed content (rows) should be less than or equal to ' + contentLength,
    CSV_MAX_FILE_SIZE: (fileSize: number) => 'Max file size should be ' + fileSize + ' kb',
    IMAGE_UNSAVED_CHANGES: 'Unsaved Changes',
    IMAGE_UNSAVED_CHANGES_DESCRIPTION:
      'Selecting a different Image type option will result in the loss of currently added images.',
    REMOVE_IMAGE_HEADING: 'Remove Image?',
    REMOVE_IMAGE_DESCRIPTION: 'Are you sure you wish to remove this image?',
    // eslint-disable-next-line quotes
    PUBLISH_DISABLE_MESSAGE: "You don't have permission to publish",
    STANDALONE_DISABLE_MESSAGE: 'To publish this asset, please publish its parent first.',
    FILE_DOWNLOADED: 'File Downloaded',
    ARCHIEVED_VERSION_EMPTY: 'No previously published version available.',
    NO_DATA_FOUND: 'No Data Found',
    COUNT_INVALID: 'Please select number of copies to clone.',
    NO_METACATEGORY_MSG: 'No Meta-Category Defined',
    CHANGE_STATUS_MESSAGE: (msg: string) => `Role ${msg} Successfully`,
    EMAIL_ALREADY_EXIST: 'Entered Email ID already exists!',
    EMAIL_INVALID: 'Entered Email ID is invalid!',
    CYBERARKID_ALREADY_EXIST: 'Entered Cyberark ID already exists!',
    CYBERARKID_INVALID: 'Entered Cyberark ID is invalid!',
    ROLE_CREATION_SUCCESS: 'Role created successfully',
    NO_DATA_CONTENT_ID: 'Data with this content id is not available',
    JOB_NAME_REQUIRED: 'Job name is required',
    ATTRIBUTES_REQUIRED: 'Select atleast one attribute',
    NO_ASSET_TYPE_DATA: 'Data with this asset type is not available',
    DELETE_BLOCK_MESSAGE: `Role deletion is blocked due to associated users; visit 'Associated Users' in Quick Links to find associated users or assign new role in the 'User Management' section before proceeding with deletion. ${''}`,
    INACTIVE_BLOCK_MESSAGE: `Role deactivation is blocked due to associated users; visit 'Associated User' in 'Quick Links' to unmap users or assign new role in the 'User Management' section before proceeding with deactivation.${''}`,
    STATUS_CHANGE_BLOCK_MESSAGE: `Role status change is blocked due to associated users; visit 'Associated User' in 'Quick Links' to unmap users or assign new role in the 'User Management' section before proceeding with status change. ${''}`,
    EDIT_DRAFT_EXIST:
      'Editing this asset version will transit this version to ‘Draft’ and it will override the existing draft version. Please ensure no further action is required in the existing draft version as it will get lost.  Do you wish to proceed?',
    EDIT_DRAFT_NOT_EXIST:
      'If you need to modify this asset, a new ‘Draft’ version will be created, allowing you to make the necessary changes. Do you wish to proceed?',
    SCHEDULE_FUTURE_EXIST:
      'Schedule already set for future time. Creating an earlier schedule will only be available for limited time until the later asset version scheduled for later time gets published.',
    SCHEDULE_EARLIER_EXIST:
      'Schedule already set for earlier time. Creating a schedule for later time will display the previous schedule asset version changes for limited time.',
    SCHEDULE_BOTH_EXIST:
      'Schedule already set for future & later time. Creating a new schedule will be visible for limited time until the other scheduled asset versions gets published.',
    SCHEDULE_SAME_EXIST:
      'Please select a different time for asset scheduling due to an existing schedule conflict.',
    SCHEDULE_DRAFT_EXIST:
      'Removing this schedule version will transit this version to ‘Draft’ and it will override the existing draft version. Please ensure no further action is required in the existing draft version as it will get lost. Do you wish to proceed?',
    SCHEDULE_DRAFT_NOT_EXIST:
      'Removing this schedule version will transit this version to ‘Draft’. Do you wish to proceed?',
    DE_PUBLISH_VERSIONS_EXIST:
      'Multiple versions of this asset are available. Depublishing will only affect the selected version, the other versions will remain unchanged. Do you wish to proceed with depublishing this asset version?',
    DE_PUBLISH_LINKED_EXIST:
      'Depublishing is not allowed for this asset due to associated child assets with standalone publishing set to ‘No.’ Please depublish/unlink/remove the schedule(s) of the child asset(s) before proceeding.',
    DE_PUBLISH_CONFIRM: 'Are you sure you want to de-publish this asset?',
    DE_PUBLISH: 'De-Publish',
    DRAFT_UPDATED: 'Draft version updated',
    DRAFT_UPDATE: 'Draft Update',
    DRAFT_CREATED: 'Draft version created',
    DRAFT_CREATE: 'Draft Create',
    CREATE_SCHEDULE: 'Create Schedule',
    DELETE_SCHEDULE: 'Remove Schedule',
    API_ERROR: 'Something wents wrong. Please try after sometime!',
    CONTENT_PUBLISHED: 'Content published successfully!',
    CONTENT_PUBLISHED_SCHEDULED: 'Content scheduled for immediate publishing!',
    CONTENT_SCHEDULED: 'Content scheduled successfully.',
    CONTENT_SCHEDULE_DELETE: 'Schedule deleted successfully!',
    SCHEDULE_DATE_FORMAT: 'Strings must use singlequote',
    CONTENT_DE_PUBLISHED: 'Asset version de-published successfully!',
    SESSION_END: 'Your session has been expired. Please log in again!',
    ROLE_SESSION_END: 'Your role has been updated. Please log in again',
    LOGOUT: 'You have been successfully logged out!',
  },
  OTHER_VERSIONS: {
    AVAILABLE: 'Other Available Versions',
    LAST_UPDATED: 'Last updated on',
    SCHEDULE_FOR: 'Scheduled For',
    SCHEDULE_FOR_PUBLISHED: 'Scheduled for Published',
    DRFAT: 'Draft',
  },
  DATE_N_TIME_FORMAT: 'dd MMMM yyyy, HH:mm',
  DATE_N_TIME_FORMAT_SECONDS: 'dd MMMM, yyyy, HH:mm:ss',
  TIME_FORMAT: 'dd MMMM, yyyy, HH:mm',
  TIME_FORMAT_1: 'dd MMM, yyyy, HH:mm',
  TIME_FORMAT_2: 'dd MMM, yyyy, HH:mm',
  TIME_FORMAT_3: 'dd/MM/yyyy HH:mm',
  DATE_FORMAT: 'dd/MM/yyyy',
  DATE_FORMAT_TIME: 'dd/MM/yyyy HH:mm',
  DATE_FORMAT_TIME_SECONDS: 'dd/MM/yyyy HH:mm:ss',
  DATE_YEAR_FORMAT: 'yyyy',

  SCHEDULE_TITLE: 'Schedule Title',
  SCHEDULE_TIME: 'Schedule Date and Time',
  BTN_TYPE_OUTLINED: 'outlined',
  BTN_TYPE_CONTAINED: 'contained',
  BTN_LABEL_NO: 'No',
  BTN_LABEL_YES: 'Yes',
  BTN_LABEL_OKEY: 'Okay',
  BTN_LABEL_CANCEL: 'Cancel',
  BTN_LABEL_SUBMIT: 'Submit',
  BTN_LABEL_PROCEED: 'Proceed',
  BTN_LABEL_PUBLISH_LATER: 'Publish Later',
  BTN_LABEL_PUBLISH_NOW: 'Publish Now',
  BTN_LABEL_DE_PUBLISH: 'De-Publish Now',
  BTN_LABEL_EDIT_SCHEDULE: 'Edit Schedule',
  BTN_LABEL_DELETE_SCHEDULE: 'Remove Schedule',
  TYPO_PUBLISHING_SCHEDULE: 'Publishing Schedule',
  META_CATEGORIES_SORTING: {
    KEY: 'metaCategoryKey',
    KEY2: 'categoryKey',
    ORDER: [
      'RIGHTS_AND_AVAILABILITY',
      'BUSINESS_RULES',
      'CONTENT_MEDIA_VIDEO',
      'CONTENT_MEDIA_IMAGE',
      'DESCRIPTIVE_METADATA',
      'TECHNICAL_METADATA',
      'ADVERTISEMENT_METADATA',
      'SEO_METADATA',
      'SPORTS_METADATA',
      'MISCELLANEOUS_METADATA',
    ],
  },

  STANDALONE_PUBLISHING: [
    { value: 'yes', label: 'Yes' },
    { value: 'no', label: 'No' },
  ],

  MASTERS_KEY_ALLOWED_PARENTS: 'ALLOWED_PARENTS',
  MASTERS_KEY_CONTENT_CATEGORY: 'CONTENT_CATEGORY',
  MASTERS_KEY_ASSET_CATEGORY: 'ASSET_CATEGORY',
  MASTERS_KEY_SCOPE: 'SCOPE',
  MASTERS_KEY_INHERITANCE_RULE: 'INHERITANCE_RULE',
  MASTERS_KEY_STANDALONE_PUBLISHED: 'STANDALONE_PUBLISHED',
  MASTERS_KEY_DATA_SOURCE: 'DATA_SOURCE',
  activeText: 'active',
  ActiveText: 'Active',
  InActiveText: 'In Active',

  YES_NO_LIST: [
    {
      label: 'Yes',
      value: 'Yes',
    },
    {
      label: 'No',
      value: 'No',
    },
  ],

  STATUS_LIST: [
    { value: 'ACTIVE', label: 'Active', type: 'status-active' },
    { value: 'INACTIVE', label: 'In Active', type: 'status-inactive' },
  ],

  DATA_TYPE_LIST: [
    { key: 'BOOLEAN', value: 'BOOLEAN' },
    { key: 'DATE', value: 'DATE' },
    { key: 'IMAGE_TYPE', value: 'IMAGE_TYPE' },
    { key: 'JSON', value: 'JSON' },
    { key: 'NUMBER', value: 'NUMBER' },
    { key: 'STRING', value: 'STRING' },
    // as discussed with BE this option has been added
  ],

  PAGINATION_OPTIONS: [
    { value: 10, label: 10 },
    { value: 20, label: 20 },
    { value: 50, label: 50 },
  ],

  STATUS_LABEL: {
    ACTIVE: 'Active',
    INACTIVE: 'In Active',
  },
  IMAGE_CONTENT_TYPE_CARD_DETAILS: {
    aspectRatio: '',
    imageResolution: '',
    imageSrc: '',
    imageUrl: '',
    targeting: '',
    segmentId: '',
  },

  AUTOCOMPLETE_EVENT_TYPES: {
    INPUT: 'input',
    CLEAR: 'clear',
    SELECTOPTION: 'selectOption',
    REMOVEOPTION: 'removeOption',
  },

  MAX_CLIP_LINE_COUNTS: {
    MAX_ITEMS: 3, // Configurable maximum items for select fields
    MAX_LINES: 2, // Configurable maximum lines for text fields
  },

  FIELDTYPES: {
    TEXT: 'TEXT',
    TEXTAREA: 'TEXTAREA',
    CHECKBOX: 'CHECKBOX',
    SWITCH: 'SWITCH',
    DATE: 'DATE',
    DATETIME: 'DATETIME',
    YEAR: 'YEAR',
    TIME: 'TIME',
    COMPLEXTYPE: 'COMPLEXTYPE',
    RADIO: 'RADIO',
    SELECT: 'SELECT',
    NUMBER: 'NUMBER',
    FILE: 'FILE',
    BOOLEAN: 'BOOLEAN',
    JSON1: 'JSON1',
  },
  CATEGORIES: {
    DESCRIPTIVE_METADATA: 'DESCRIPTIVE_METADATA',
    RIGHTS_AND_AVAILABILITY: 'RIGHTS_AND_AVAILABILITY',
    SEO_METADATA: 'SEO_METADATA',
    ADVERTISEMENT_METADATA: 'ADVERTISEMENT_METADATA',
    BUSINESS_RULES: 'BUSINESS_RULES',
    TECHNICAL_METADATA: 'TECHNICAL_METADATA',
    CONTENT_MEDIA_IMAGE: 'CONTENT_MEDIA_IMAGE',
    CONTENT_MEDIA_VIDEO: 'CONTENT_MEDIA_VIDEO',
    SPORTS_METADATA: 'SPORTS_METADATA',
    MISCELLANEOUS_METADATA: 'MISCELLANEOUS_METADATA',
    CHECKLIST: 'CHECKLIST',
  },

  MARK_DONE_STATUS: {
    PASS: 'PASS',
    FAILED: 'FAIL',
    NO_ACTION: 'NO_ACTION',
  },

  MARK_DONE_LABEL: {
    FIRST: 'Mark as Done',
    SECOND: 'Mark as Done',
    THIRD: 'Mark as Done',
  },

  ASSET_CREATION_OBJ: {
    SELECT: 'SELECT',
    ADDMORE: 'ADDMORE',
    COMPLEX: 'COMPLEX',
    REMOVE: 'REMOVE',
    NORMAL: 'NORMAL',
    BOOLEAN: 'BOOLEAN',
  },
  LISTING_LIMIT_OPTIONS: [
    { label: 10, value: 10 },
    { label: 20, value: 20 },
    { label: 50, value: 50 },
  ],
  ASSET_STATE_DISPLAY: {
    CREATED: 'Draft-Created',
    DRAFT: 'Draft',
    DRAFT_COMPLETE: 'Draft-Complete',
    DRAFT_UNDER_QC: 'Draft-Under QC',
    READY_FOR_PUBLISHING: 'Ready for Publishing',
    PUBLISHED: 'Published',
    SCHEDULE_PUBLISH: 'Scheduled For Publish',
    DEPUBLISHED: 'De-Published',
    RIGHTS_EXPIRED: 'Rights Expired',
    ARCHIEVED: 'Archived',
    FAILED: 'Failed',
    PUBLISH_FAIL: 'Publishing Failed',
  } as Record<string, string>,
  LISTING_STATE_OPTIONS: [
    { value: '', label: 'Filter by State' },
    { value: 'CREATED', label: 'Draft-Created' },
    { value: 'DRAFT', label: 'Draft' },
    { value: 'DRAFT_COMPLETE', label: 'Draft-Complete' },
    { value: 'DRAFT_UNDER_QC', label: 'Draft-Under QC' },
    { value: 'READY_FOR_PUBLISHING', label: 'Ready for Publishing' },
    { value: 'PUBLISHED', label: 'Published' },
    { value: 'SCHEDULE_PUBLISH', label: 'Scheduled For Publish' },
    { value: 'DEPUBLISHED', label: 'De Publish' },
    { value: 'RIGHTS_EXPIRED', label: 'Rights Expired' },
  ],
  BULK_UPDATE_STATUS: [
    { value: '', label: 'Filter by State' },
    { value: 'COMPLETED', label: 'Completed' },
    { value: 'IN_PROGRESS', label: 'In Progress' },
    { value: 'SCHEDULED', label: 'Scheduled' },
    { value: 'IN_QUEUE', label: 'In Queue' },
  ],
  ASSET_STATUS: {
    DRAFT: 'DRAFT',
    DRAFT_COMPLETE: 'DRAFT_COMPLETE',
    DRAFT_UNDER_QC: 'DRAFT_UNDER_QC',
    DRAFT_READY_FOR_PUBLISHING: 'READY_FOR_PUBLISHING',
    PUBLISHED: 'PUBLISHED',
    SCHEDULE_PUBLISH: 'SCHEDULE_PUBLISH',
    DE_PUBLISH: 'DE_PUBLISH',
    DEPUBLISHED: 'DEPUBLISHED',
    RIGHTS_EXPIRED: 'RIGHTS_EXPIRED',
    CREATED: 'CREATED',
    FAILED: 'FAILED',
    ARCHIEVED: 'ARCHIEVED',
    PUBLISH_FAIL: 'PUBLISH_FAIL',
  },
  EVENT_TYPE_STATE: {
    TRANSITION: 'STATE_TRANSITION',
    TRANSITION_IMMEDIATE_PUBLISH: 'STATE_TRANSITION_IMMEDIATE_PUBLISH',
  },
  ASSET_CATEGORY: [
    {
      label: 'GOB',
      value: 'GOB',
    },
    {
      label: 'Bundle',
      value: 'BUNDLE',
    },
    {
      label: 'Content Item',
      value: 'CONTENT_ITEM',
    },
  ],
  PARENT_CHILD: [
    {
      label: 'Parent',
      value: 'Parent',
    },
    {
      label: 'Child',
      value: 'Child',
    },
  ],

  BLITZ_ID_TEXT: 'blitzId',
  PARENT_ID_TEXT: 'parentId',
  EQUALS: 'equals',
  BLITZ_NULL_VALUE: 'null',
  ASSET_CATEGORY_ORDER: ['GOB', 'BUNDLE', 'CONTENT_ITEM'],
  ASSET_GROUP_FILE_TYPE: {
    FOLDER: 'Folder',
    FILE: 'File',
  },

  INITIATED_STATUS: ['VERIFICATION_INITIATED', 'PREVIEW_INITIATED', 'TRANSCODING_INITIATED'],
  AFTER_VALIDATION_TYPE: ['preview', 'transcoding'],
  NEED_LONGPULL_STATUS: [
    'CREATE',
    'UPDATE',
    'VERIFICATION_INITIATED',
    'PREVIEW_INITIATED',
    'TRANSCODING_INITIATED',
  ],

  SYSTEM_TOLLGATES: {
    RIGHTS_AND_AVAILABILITY: false,
    MISCELLANEOUS_METADATA: false,
    CONTENT_MEDIA_IMAGE: false,
    DESCRIPTIVE_METADATA: false,
    SEO_METADATA: false,
    BUSINESS_RULES: false,
    CONTENT_MEDIA_VIDEO: false,
    SPORTS_METADATA: false,
    ADVERTISEMENT_METADATA: false,
    TECHNICAL_METADATA: false,
    QC_PROCESS: false,
    PUBLISHING: false,
    RIGHTS_EXPIRED: false,
    DEPUBLISH: false,
    CHECKLIST: false,
  },
  ASSET_DEFINITION_CONFIRMATION_DIALOG: {
    TITLE: 'Confirm',
    DESC: 'Are you sure you want to “Submit” changes made?',
  },
  ATTRIBUTE_VALIDATION_ADDEDD: 'Please submit the added validations to apply.',
  // eslint-disable-next-line quotes
  VIDEO_LANGUAGE_ERROR: "Choose language to enable the 'Fetch & Validate' option.",

  VIDEO_COMPONENT_MESSAGES: {
    MEDIA_PROFILE_UPDATE: 'Media profile updated successfully!',
    FILE_ADD: 'File added successfully!',
    FILE_UPDATE: 'File updated successfully!',
    FILE_REMOVE: 'File removed successfully!',
    LANGUAGE_UPDATE: 'Language updated successfully!',
    SELECT_SUBTITLE: 'Please select language for subtitle.',
  },

  VIDEO_TRANSCODE_STAGES: {
    VALIDATTION: 'validation',
    PREVIEW: 'preview',
    TRANSCODING: 'transcoding',
  },
  CAST_AND_CREW: 'castAndCrew',
  LIVE_OPTIONS: ['LIVE_CHANNEL', 'LIVE_EVENT', 'LIVE_SPORT'],
  LIMIT_EXCEED: 'Limit Exceeded',
  LIMIT_EXCEED_CONTENT:
    'Unable to export, the file size has exceeded the permitted limit for export.',

  WARNING_ASSET: 'Warning',
  WARNING_ASSET_DESCRIPTION: 'Cloning this asset will also clone the associated ',
  WARNING_ASSET_DESCRIPTION1: 'Content Media: Video',
  WARNING_ASSET_DESCRIPTION2:
    ' meta category. Please review and make necessary changes before publishing to avoid potential issues. Proceed with caution.',
  MODULE_LIST: {
    MANAGE_CONTENT: 'ManageContent',
    USER: 'User',
    ROLE: 'Role',
    ASSET_DEFINITION: 'AssetDefinition',
    ATTRIBUTE_REPO: 'AttributeRepo',
    MASTER: 'Master',
    GEO_POLICY: 'GeoPolicy',
    ADVANCED_SEARCH: 'AdvanceSearch',
  },
  PACKAGE_MASTER_KEY: 'packageMasterKey',
  METADATA_TABS: {
    DESCRIPTIVE_METADATA: {
      tabs: [
        { value: 0, label: 'Descriptive Summary' },
        { value: 1, label: 'Cast & Crew' },
      ],
      fieldNames: ['castAndCrew'],
    },
    RIGHTS_AND_AVAILABILITY: {
      fieldNames: [
        'applicableGeoPolicy',
        'countryEntitlementSwitch',
        'geoPolicyGroupName',
        'countryEntitlementSwitchAvodToSvod',
        'automationPolicy',
      ],
      tabs: [
        { value: 0, label: 'General' },
        { value: 1, label: 'Geo Policy' },
      ],
    },
  },
  EXCLUDED_ADVANCE_SEARCH_ATTRIBUTE: ['applicableGeoPolicy', 'geoPolicyGroupName', 'adTags'],
  METADATA_COMPLEX_FIELD_UI_TYPE: {
    AFTER_ADD_MORE: ['castAndCrew'],
    BEFORE_ADD_MORE: ['cuePointList'],
  },
  METADATA_CONTENT_ID_FILEDS: ['blitzParentContentId', 'linkedReferenceContentId', 'autoPlayId'],
  METADATA_CONTENT_ID_API: {
    blitzParentContentId: 'GET_DATA_BASED_ON_ID',
    linkedReferenceContentId: 'GET_LINKED_REF_DATA_BASED_ON_ID',
    autoPlayId: 'GET_DATA_BASED_ON_ID',
  },
  METADATA_CONTENT_ID_DATA: {
    blitzParentContentId: null,
    linkedReferenceContentId: null,
    autoPlayId: null,
  },
  LIST_HISTORY_ROUTES: ['/asset/view', '/asset/update', '/asset/view-only'],
  SYSTEM_CONFIGURATION_MEDIAINFO_KEY: 'MEDIA_INFO_CONFIG',
  TITLE_ID_TEXT: 'titleOrId',
  TRANSACTION_APIS: [
    { url: '/content/editable/action/detail/', method: 'get' },
    { url: '/content/metaCategory/', method: 'patch' },
    { url: '/content/metaCategory/markDone', method: 'patch' },
  ],

  FILE_STATUS: {
    IN_PROGRESS: 'IN_PROGRESS',
    SUCCESS: 'SUCCESS',
  },
  BULK_UPDATE_ATTRIBUTE_LABEL: {
    BLITZ_ID: BLITZ_ID,
    VIDEO_SUBTITLES: VIDEO_SUBTITLES,
  },
  BULK_UPDATE_EXCLUDED_ATTRIBUTE: [BLITZ_ID],
  OPTIONAL_ATTRIBUTES_BULK_UPDATE: [],
  BULK_UPDATE_DISABLED_EXTRAS: [
    'tvSpriteImageUrl',
    'checksum',
    'maximumAudioQuality',
    'maximumResolution',
    'maximumVideoQuality',
    'dvrUrl',
    'nonDrmDash',
    'nonDrmHls',
    'blitzParentContentId',
    'drmVideoKid',
    'kidMss',
    'applicableGeoPolicy',
    'integratedSplashAvailable',
    'mergedManifestAvailable',
    'drmType',
    'geoPolicyGroupName',
    'duration',
    'spriteImageUrl',
    'defaultLang',
    'packageMasterKey',
  ],
  BULK_UPDATE_ADDITIONAL_FIELD: [{ fieldName: 'videoSubTitles', label: VIDEO_SUBTITLES }],
}
