const ShieldIcon = () => {
  return (
    <svg width='13' height='16' viewBox='0 0 13 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M5.72904 0.904062L1.06238 2.9774C0.582376 3.19073 0.269043 3.67073 0.269043 4.1974V7.33073C0.269043 11.0307 2.82904 14.4907 6.26904 15.3307C9.70904 14.4907 12.269 11.0307 12.269 7.33073V4.1974C12.269 3.67073 11.9557 3.19073 11.4757 2.9774L6.80904 0.904062C6.46904 0.750729 6.06904 0.750729 5.72904 0.904062ZM4.46238 10.8574L2.73571 9.13073C2.47571 8.87073 2.47571 8.45073 2.73571 8.19073C2.99571 7.93073 3.41571 7.93073 3.67571 8.19073L4.93571 9.44406L8.85571 5.52406C9.11571 5.26406 9.53571 5.26406 9.79571 5.52406C10.0557 5.78406 10.0557 6.20406 9.79571 6.46406L5.40238 10.8574C5.14904 11.1174 4.72238 11.1174 4.46238 10.8574Z'
        fill='white'
      />
    </svg>
  )
}

export default ShieldIcon
